export const insertPhoto = require("../assets/insert_photo.png");
export const filterIcon = require("../assets/filter.svg");
export const headerIcon = require("../assets/header_icon.png");
export const chatIcon = require("../assets/chat_icon.png");
export const openChatAvatar = require("../assets/open_chat_avatar.png");
export const detailPhoto = require("../assets/detail_image.jpeg");
export const notFoundIcon = require("../assets/not_found.png");
export const footerLogoIcon = require("../assets/footer_logo.png");
export const footerWaveIcon = require("../assets/footer_wave.png");
export const noImageAvatar = require("../assets/no_avatar.jpeg");
