import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import {
  Box,
  Card,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import FavoriteBorderRoundedIcon from "@material-ui/icons/FavoriteBorderRounded";
import StarBorderRoundedIcon from "@material-ui/icons/StarBorderRounded";
import { ValueType } from "react-select";
import {
  convertToOptions,
  sendAPIRequest,
} from "../../../components/src/utils";
import { Pagination } from "@material-ui/lab";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import FavoriteRoundedIcon from "@material-ui/icons/FavoriteRounded";
import { format } from "date-fns";

const assets = require("./assets");

export interface IOption {
  value: string;
  label: string;
}

export interface IDockListItem {
  id: number;
  latitude: string;
  longitude: string;
  address: string | null;
  lake: { id: number; name: string; state: string } | null;
  city: string | null;
  dock_type: string;
  listing_type: string;
  docking_length: number;
  docking_width: number;
  listing_title: string;
  about: string;
  access: string;
  parking_space: number;
  max_boats: number;
  max_guests: number;
  allow_pets: boolean;
  allow_smoking: boolean;
  allow_liveaboards: boolean;
  rules_and_cautions: string;
  has_security_camera: boolean;
  has_animals: boolean;
  reservation_type: string;
  cancellation_policy: string;
  base_price: string;
  step: number;
  images: string[];
  dock_add_ons: {
    id: number,
    add_on_id: number,
    dock_id: number,
    price: number
  }[] | null;
  service_fee: number;
  guest_total: string;
  locality: string;
  administrative_area_level_1: string;
  administrative_area_level_2: string;
  state_short_name: string;
  features: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
  }[];
  host:	{ id: number, name: string, reviews: number,rating: number,avatar:string };
  favourite_id: number | null
}

interface ISearchedParams {
  per: number;
  page: number;
  nearby: string | null;
  availability_from: string | null;
  availability_to: string | null;
  feature_ids: number[] | null;
  price_from: string | null;
  price_to: string | null;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  isLoading: boolean;
  errorMsg: string;
  loginModal: boolean;
  currentPage: number;
  featureList: IOption[];
  priceRangeList: IOption[];
  searchedText: string;
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
  selectedFeatures: IOption[];
  selectedPriceRange: IOption;
  availableDockList: IDockListItem[];
  searchParams: ISearchedParams;
  total_count: number;
  total_pages: number;
  dockTitle: string;  
  isdockSearchCalled: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  itemsPerPage: number = 20;
  getFeaturesApiCallId: string = "";
  searchDockApiCallId: string = "";
  searchDockInAreaApiCallId: string = "";
  addToFavoritesApiCallId: string = "";
  removeFromFavoritesApiCallId: string = "";
  clearTimeout: NodeJS.Timeout | null = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      isLoading: false,
      errorMsg: "",
      loginModal: false,
      currentPage: 1,
      featureList: [],
      priceRangeList: convertToOptions(
        [
          { value: "1", name: "0-50" },
          { value: "2", name: "50-100" },
          { value: "3", name: "100-200" },
          { value: "4", name: "200-300" },
          { value: "5", name: "300-400" },
          { value: "6", name: "400-500" },
          { value: "7", name: "500+" },
        ],
        "value",
        "name"
      ),
      searchedText: "",
      selectedStartDate: null,
      selectedEndDate: null,
      selectedFeatures: [],
      selectedPriceRange: {} as IOption,
      availableDockList: [],
      searchParams: {
        per: this.itemsPerPage,
        page: 1,
        nearby: null,
        availability_from: null,
        availability_to: null,
        feature_ids: null,
        price_from: null,
        price_to: null,
      },
      total_count: 0,
      total_pages: 0,
      dockTitle: '',
      isdockSearchCalled: false,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.fetchFeatureList();
    this.fetchDockInAreaListBySearch();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getAdvancedSearchList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (responseJson && responseJson.accounts) {
        if (typeof responseJson.accounts === "string") {
          alert(responseJson.accounts);
        } else {
          this.setState({ advancedsearchList: responseJson.accounts.data });
        }
      }
      this.apiSuccessCallBackController(apiRequestCallId, responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {};

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {};

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  getAdvancedSearchList = (token: string) => {};

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.getFeaturesApiCallId]: this.handleFeatureAPIResponse,
      [this.searchDockApiCallId]: this.handleAPIResponse,
      [this.searchDockInAreaApiCallId]: this.handleAPIResponse,
      [this.addToFavoritesApiCallId]: this.handleAddFavoriteApiCall,
      [this.removeFromFavoritesApiCallId]: this.handleRemoveFavoriteApiCall,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  fetchFeatureList = () => {
    this.setState({ errorMsg: "" });
    this.getFeaturesApiCallId = sendAPIRequest(
      "bx_block_content_management/features",
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  handleSearchParamChange = (param: Partial<ISearchedParams>) => {
    this.setState({
      dockTitle: 'Search results...'
    });
    this.setState(
      (prevState) => ({
        searchParams: {
          ...prevState.searchParams,
          ...param,
        },
      }),
      this.fetchDockListBySearch
    );
  };

  fetchDockListBySearch = async () => {
    this.setState({
      isLoading: true,
      errorMsg: "",
    });
    const token = await getStorageData("token");
    const searchParams = new URLSearchParams();
    Object.entries(this.state.searchParams).forEach(([keyName, value]) => {
      if (value !== null && value !== "") {
        if (keyName === "feature_ids") {
          value.forEach((featureId: number) => {
            searchParams.append("feature_ids[]", featureId.toString());
          });
        } else {
          searchParams.append(keyName, value.toString());
        }
      }
    });
    this.searchDockApiCallId = sendAPIRequest(
      `bx_block_advanced_search/dock_search?${searchParams}`,
      {
        method: "GET",
        headers: token ? {
          "Content-Type": "application/json",
          token,
        } : {
          "Content-Type": "application/json",
        },
      }
    );
    this.setState({ isdockSearchCalled: true });
  };
  
  fetchDockInAreaListBySearch = async () => {
    this.setState({
      isLoading: true,
      errorMsg: "",
    });
    const query = await getStorageData("propsData");
    const token = await getStorageData("token");
    const parseQuery = JSON.parse(query);
    this.setState({
      dockTitle: parseQuery.query,
    });
    
    this.searchDockInAreaApiCallId = sendAPIRequest(
      `${configJSON.getSearchInTheAreaApiEndPoint}${parseQuery.query}&lake_id=${parseQuery.lake_id}`,
      {
        method: "GET",
        headers: token ? {
          "Content-Type": "application/json",
          token,
        } : {
          "Content-Type": "application/json",
        },
      }
    );
  };
  handleFeatureAPIResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      meta?: { message: string };
      data?: { id: string; type: string; attributes: object }[];
    };
    if (response.data) {
      const featureList = response.data.map((feature) => feature.attributes);
      this.setState({
        featureList: convertToOptions(featureList, "id", "name"),
      });
    }
  };

  handleAPIResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      meta?: { pagination: { total_count: number; total_pages: number } };
      data?: { id: string; type: string; attributes: object }[];
    };
    if (response.data && response.meta) {
      const availableDockList = response.data.map(
        (availableDock) => ({...availableDock.attributes, lake: null})
      );
      this.setState({
        availableDockList: availableDockList as IDockListItem[],
        total_count: response.meta.pagination.total_count,
        total_pages: response.meta.pagination.total_pages,
        isLoading: false,
      });
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    this.setState({ isLoading: false });

    const { errors } = responseJSON as { errors: { error: string } };
    if (errors) {
      this.setState({
        errorMsg: errors.error,
      });
      return true;
    }
    return false;
  };

  handleSearchByText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({ searchedText: value });
    if (this.clearTimeout) {
      clearTimeout(this.clearTimeout);
    }
    this.clearTimeout = setTimeout(() => {
      this.handleSearchParamChange({ nearby: value, page: 1 });
    }, 500);
  };

  handleAvailabilityByDates = (dates: [Date | null, Date | null]) => {
    const [selectedStartDate, selectedEndDate] = dates;
    this.setState({ selectedStartDate, selectedEndDate });
    if (selectedStartDate && selectedEndDate)
      this.handleSearchParamChange({
        availability_from: format(selectedStartDate, "MM/dd/yyyy"),
        availability_to: format(selectedEndDate, "MM/dd/yyyy"),
        page: 1
      });
  };

  goToDockBooking = (dockId: number) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      configJSON.dockBookingPage
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    if (dockId) {
      msg.addData(getName(MessageEnum.NavigationScreenNameMessage), dockId);
    }
    this.send(msg);
  };

  addToFavorites = async (dock_id: string) => {
    this.setState({ errorMsg: "" });
    const token = await getStorageData("token");
    if(!token) {
      this.setState({ loginModal: true });
      return;
    }
    this.addToFavoritesApiCallId = sendAPIRequest(
      `bx_block_favourites/favourites`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token,
        },
        body: {
          data: {
            dock_id
          },
        },
      }
    );
  };

  removeFromFavorites = async (dock_id: number | null) => {
    this.setState({ errorMsg: "" });
    const token = await getStorageData("token");
    if(!token) {
      this.setState({ loginModal: true });
      return;
    }
    this.removeFromFavoritesApiCallId = sendAPIRequest(
      `bx_block_favourites/favourites/${dock_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          token,
        }
      }
    );
  };

  handleAddFavoriteApiCall = (
    responseJSON: Record<string, unknown>
  ) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      meta?: { message: string };
      data?: { id: string; type: string; attributes: {dock_listing: IDockListItem} };
    };
    if (response.data) {
      if(this.state.isdockSearchCalled){
        this.fetchDockListBySearch();
      } else {
        this.fetchDockInAreaListBySearch();
      }
    }
  };

  handleRemoveFavoriteApiCall = (
    responseJSON: Record<string, unknown>
  ) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      message?: string ;
    };
    if (response.message) {
      if(this.state.isdockSearchCalled){
        this.fetchDockListBySearch();
      } else {
        this.fetchDockInAreaListBySearch();
      }
    }
  };
  handleFeatureChanges = (value: ValueType<IOption, true>) => {
    this.setState({
      selectedFeatures: value as IOption[],
    });
    if (value) {
      const featureIds = value.map((feature) => Number(feature.value));
      this.handleSearchParamChange({feature_ids: featureIds, page: 1});
    } else {
      this.handleSearchParamChange({ feature_ids: null, page: 1 });
    }
  };

  handlePriceRangeChanges = (value: ValueType<IOption, true>) => {
    this.setState({
      selectedPriceRange: (value as unknown) as IOption,
    });

    if (value) {
      const { label } = (value as unknown) as IOption;
      const [price_from, price_to] = label.includes("-")
        ? label.split("-")
        : label.split("+");
      this.handleSearchParamChange({
        price_from,
        price_to: price_to || null,
        page: 1
      });
    } else {
      this.handleSearchParamChange({ price_from: null, price_to: null, page: 1 });
    }
  };
  renderCards = () => {
    return this.state.availableDockList.map((dockItem) => (
      <Grid item key={dockItem.id} md={3} lg={3} sm={4} xs={12}>
        <Card className="dockListCard">
          <CardMedia
            className="dockListImage"
            image={dockItem.images?.[0] || assets.cardMediaImage}
            title={dockItem.listing_title}
          >
            <IconButton
              data-test-id="favouriteIcon"
              className="favIcon"
              onClick={() => 
                dockItem.favourite_id
                  ? this.removeFromFavorites(dockItem.favourite_id)
                  : this.addToFavorites(dockItem.id.toString())
              }
            >
              {dockItem.favourite_id ? (
                <FavoriteRoundedIcon color="error" />
              ) : (
                <FavoriteBorderRoundedIcon />
              )}
            </IconButton>
          </CardMedia>
          <Box className="cardContent">
            <Typography
              data-test-id="cardTitle"
              variant="body1"
              className="cursorPointer"
              onClick={() => this.goToDockBooking(Number(dockItem.id))}
            >
              {dockItem.listing_title || "Not Provided"}
            </Typography>
            <Typography variant="body1" className="ratingText">
              <StarBorderRoundedIcon className="ratingIcon" />
              {(dockItem.host?.rating || 0).toFixed(1)}
            </Typography>
          </Box>
        </Card>
      </Grid>
    ));
  };

  renderPaginationAndSummary = () => {
    const { page, per } = this.state.searchParams;
    const startIndex = (page - 1) * per + 1;
    const endIndex =
      page * per > this.state.total_count ? this.state.total_count : page * per;

    return (
      <Box className="pagination">
        <Pagination
          data-test-id="pagination"
          count={Math.ceil(this.state.total_pages)}
          siblingCount={0}
          page={page}
          onChange={this.handlePageChange}
        />
        <Typography variant="subtitle1">
          {startIndex} - {endIndex} of {this.state.total_count} results
        </Typography>
      </Box>
    );
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({
      currentPage: value,
    });
    this.handleSearchParamChange({ page: value });
  };
  // Customizable Area End
}
