import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { getLastPartOfURL, sendAPIRequest } from "../../../components/src/utils";
import { DockDetail } from "../../../blocks/reservations/src/DockBookingController.web";

interface Arraydata { id: string, attributes: { id: number, name: string } }
interface AddOnsdata { price: string, add_on_id: number} 
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    dockAccess: string;
    dockAccessMsg: string;
    dockParkingMsg: string;
    noOfParking: number;
    modalOpen: boolean;
    addOnsName: string[];
    description: string;
    featureData: Arraydata[];
    addOnsData: Arraydata[];
    addOnCharge:number;
    addOnObj: Arraydata;
    draftDataIds:{id:number,access:string, parking_space:number,features:[{id:number}],dock_add_ons:[{add_on_id:number, price:number}], lake:{id:number}};
    featureIds:number[];
    addOnsIds:number[];
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class DockListingFeaturesController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    dockFeaturesAPICallId: string = "";
    dockAddOnsAPICallId: string = "";
    updateAddOnsAPICallId: string = "";
    draftDockAPICallId: string = "";
    removeAddOnsAPICallId: string = "";
    updateDockAPICallId: string = "";
    existingDockFetchAPICallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.NavigationMessage),
        getName(MessageEnum.NavigationPropsMessage),
        getName(MessageEnum.NavigationTargetMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        draftDataIds:{id:0, access:"", parking_space:0,features:[{id:0}],dock_add_ons:[{add_on_id:0, price:0}], lake:{id:0}},
        dockAccess: "",
        dockAccessMsg :'',
        dockParkingMsg: '',
        noOfParking: 0,
        modalOpen:false,
        addOnsName: [],
        description: "",
        featureData: [],
        addOnsData: [],
        addOnCharge:0,
        addOnObj:{ id: "", attributes: { id: 0, name: "" } },
        featureIds: [],
        addOnsIds:[],
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      // Customizable Area Start
      // Customizable Area End
    }

    async componentDidMount() {
      // Customizable Area Start
      this.getDockFeatures();
      this.getAddOnsData();
      this.getDraftDockData();
      this.getExistingDockListingDetails();
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const featureJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      
        if (apiRequestCallId === this.dockFeaturesAPICallId) {
          this.setState({
            featureData: featureJson.data,
          }
          )
        }
      }
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const addOnsJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      
        if (apiRequestCallId === this.dockAddOnsAPICallId) {
          this.setState({
            addOnsData: addOnsJson.data,
          }
          )
        }
      }
      this.draftDockApi(message)  
      this.updateDockAndNextApi(message) 
      this.removeAddonApi(message);

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.apiSuccessCallBackController(apiRequestCallId, responseJson);
      // Customizable Area End
    }

    // Customizable Area Start   
    apiSuccessCallBackController = (
      apiRequestCallId: string,
      responseJSON: Record<string, unknown>
    ) => {
      const successCallbackMap = {
        [this.existingDockFetchAPICallId]: this.handleListingDataAPIResponse,
      };
  
      if (apiRequestCallId) {
        const successCallback: (responseJSON: Record<string, unknown>) => void =
          successCallbackMap[apiRequestCallId];
        !!successCallback && successCallback(responseJSON);
      }
    };

    updateDockAndNextApi = (message:Message)=>{
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiUpdateRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const addOnsUpdateJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      
        if (apiUpdateRequestCallId === this.updateDockAPICallId) {
          if(addOnsUpdateJson.data){       
            if(this.state.draftDataIds.id !== Number(getLastPartOfURL().url)){
              this.handleNavigation("ManageListingDetails")
            } else {
              this.handleNavigation("DockListingRulesInstructions")
            }
          }
        }
      }
    }

    removeAddonApi = (message:Message)=>{
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiUpdateRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const addOnsUpdateJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      
        if (apiUpdateRequestCallId === this.removeAddOnsAPICallId) {
          if(addOnsUpdateJson.message === "Add-on removed"){      
            this.setState({addOnsIds: this.state.addOnsIds.filter(addonId => addonId !== Number(this.state.addOnObj.id))})
          }
        }
      }
    };

    draftDockApi = (message:Message)=>{
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiDraftRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const addOnsDraftJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      
        if (apiDraftRequestCallId === this.draftDockAPICallId) {
          this.setState({
            draftDataIds: addOnsDraftJson.data.attributes
          }
          )
          const featureIds = addOnsDraftJson.data.attributes.features.map((feature:Arraydata) => feature.id);
          const addOnsIds = addOnsDraftJson.data.attributes.dock_add_ons.map((addOn: AddOnsdata) => addOn.add_on_id);
          const noOfParking = addOnsDraftJson.data.attributes.parking_space;
          const dockAccess = addOnsDraftJson.data.attributes.access;
            this.setState({ featureIds, addOnsIds, noOfParking, dockAccess});
        }
      }
    }  
    getDraftDockData = async () => {
      const token = await getStorageData("token");
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const getValidationsM = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.draftDockAPICallId = getValidationsM.messageId
      getValidationsM.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.draftDockPath)
      getValidationsM.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsM.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getcompanyApiMethod
      );
      runEngine.sendMessage(getValidationsM.id, getValidationsM);
    }    
    getExistingDockListingDetails = async () => {
      const token = await getStorageData("token");
      const dockId = getLastPartOfURL().url || -1;
      this.existingDockFetchAPICallId = sendAPIRequest(
        `bx_block_content_management/dock_listings/${dockId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
    };

    handleListingDataAPIResponse = (
      responseJSON: Record<string, unknown>
    ) => {
      const response = responseJSON as {
        data?: { id: string; type: string; attributes: DockDetail };
      };
      if (response.data) {
        const dockDetails = response.data?.attributes;
        if(dockDetails)
          this.setState({
            addOnsIds: dockDetails.dock_add_ons.map(addon => addon.add_on_id),
            featureIds: dockDetails.features.map(feature => feature.id),
            dockAccess: dockDetails.access,
            noOfParking: dockDetails.parking_space,
          });
      }
    };
    updateAddOnsDock = async () => {
      const token = await getStorageData("token");
      const updatedBody = {
        dock_id: Number(getLastPartOfURL().url),
        add_on_id: this.state.addOnObj.attributes.id,
        price: this.state.addOnCharge,
      }
      
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const getValMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateAddOnsAPICallId = getValMsg.messageId
      getValMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateAddOnsPath)
      getValMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      getValMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(updatedBody)
          );
      runEngine.sendMessage(getValMsg.id, getValMsg);
    }   
    getDockFeatures = () => {
      const headers = {
        "Content-Type": "application/json",
      };
      const getDockMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.dockFeaturesAPICallId = getDockMsg.messageId
      getDockMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getFeatures)
      getDockMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getDockMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getcompanyApiMethod
      );
      runEngine.sendMessage(getDockMsg.id, getDockMsg);
    }
    getAddOnsData = () => {
      const headers = {
        "Content-Type": "application/json",
      };
      const getValidationsMsgs = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.dockAddOnsAPICallId = getValidationsMsgs.messageId
      getValidationsMsgs.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAddOns)
      getValidationsMsgs.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsgs.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getcompanyApiMethod
      );
      runEngine.sendMessage(getValidationsMsgs.id, getValidationsMsgs);
    }    
    removeAddOns= async () => {
      const token = await getStorageData("token");
      const removeBody = {
        dock_id: Number(getLastPartOfURL().url),
        add_on_id: this.state.addOnObj.attributes.id,
      }
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.removeAddOnsAPICallId = getValidationsMsg.messageId
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.removeAddOnsPath)
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteMethod
      );
      getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(removeBody)
          );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }  
    handleNavigation = (route: string) => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), route);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), getLastPartOfURL().url);
      this.send(message);
    }; 
    handleNextPage = async()=>{
      let dockAccessMsg = '';
      let dockParkingMsg = '';

      if (!this.state.dockAccess) {
        dockAccessMsg = "Please select the type of dock access.";
      } 
      if (this.state.dockAccess === "Access dock from property address" && this.state.noOfParking < 1) {
        dockParkingMsg = "Please specify the number of parking spaces.";
      } 
      this.setState({ dockAccessMsg , dockParkingMsg});
      if (dockAccessMsg || dockParkingMsg) {
        return;
      }
      this.setState({ dockAccessMsg: '', dockParkingMsg: ''});
      const dock_listing = {
        feature_ids : this.state.featureIds,
        access: this.state.dockAccess,
        parking_space: this.state.noOfParking,
        step:2,
        lake_id: this.state.draftDataIds.lake.id || getLastPartOfURL().url,
      }
      const token = await getStorageData("token");      
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateDockAPICallId = getValidationsMsg.messageId
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.createDockListingApiEndPoint}/${getLastPartOfURL().url}`)
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
      getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({dock_listing})
          );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);      
    } 
    handleFeatureChange=(fearureId:number)=>{
      this.setState((prevState) => {
        const featureIds = [...prevState.featureIds];
        const itemIndex = featureIds.indexOf(fearureId);
    
        if (itemIndex === -1) {
          featureIds.push(fearureId);
        } else {
          featureIds.splice(itemIndex, 1);
        }      
        return { featureIds };
      });
    } 
    handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
      this.setState({dockAccess:event.target.value})
    }    
    handleParkingCount = (event: React.ChangeEvent<HTMLInputElement>)=>{
      this.setState({noOfParking:parseInt(event.target.value)})
    }  
    handleClose = (isAddonAdded: boolean) => {
      if(isAddonAdded) {
        this.setState({ addOnsIds: [ ...this.state.addOnsIds, this.state.addOnObj.attributes.id ] });
      } else {
        this.setState({ addOnsIds: this.state.addOnsIds.filter(addonId => addonId !== this.state.addOnObj.attributes.id) })
      }
      this.setState({modalOpen:false})
      setTimeout(()=>{
        this.setState({ addOnCharge: 0 });
      },2000)
    }
    handleCheckedModel = (addOn: Arraydata) => {
      this.setState((prevState) => {
        const addOnsName = [...prevState.addOnsName];
        const addOnsIds = [...prevState.addOnsIds];
        const itemIndex = addOnsName.indexOf(addOn.attributes.name);
        const itemIndex1 = addOnsIds.indexOf(addOn.attributes.id);
    
        if (itemIndex === -1 && itemIndex1 === -1) {
          addOnsName.push(addOn.attributes.name);
          addOnsIds.push(addOn.attributes.id);
          this.modalOpen(); 
        } else {
          addOnsName.splice(itemIndex, 1);
          this.removeAddOns(); 
        }    
        return { addOnsName, addOnsIds };
      });
      this.setState({addOnObj:addOn})
    };
    modalOpen =()=>{
      this.setState({modalOpen:true})
    }
    handleAddOnCharge = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = parseInt(event.target.value);
      this.setState({ addOnCharge: newValue })
    };
    updateAddOnsCharge = ()=>{
      this.updateAddOnsDock();
      this.handleClose(true);      
    }
    chunkArray = (array: Arraydata[], chunkSize: number) => {
      const chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
          chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
  };
    // Customizable Area End
  }
