Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.getProfilePath = '/bx_block_settings/profile_settings/view';
exports.updateProfilePath = '/bx_block_settings/profile_settings/modify';
exports.btnExampleTitle = "CLICK ME";
exports.pushNotificationText = "Push notifications";
exports.pushNotificationHelperText =
  "Stay in the loop! Check out our latest updates.";
exports.emailsText = "Emails";
exports.emailsHelperText =
  "Stay in the loop! Check out our latest updates through email.";
exports.textMsgsText = "Text messages";
exports.textMsgsHelperText =
  "Stay in the loop! Check out our latest updates through text messages.";
exports.loginText = "Login";
exports.updateBtnText = "Update";
exports.cancelBtnText = "Cancel";
exports.currentPasswordText = "Current Password";
exports.newPasswordText = "New Password";
exports.confirmPasswordText = "Confirm Password";
exports.updatePasswordBtnText = "Update Password";
exports.accountText = "Account";
exports.deactivateYourAccountText = "Deactivate your account";
exports.deactivateMyAccountBtnText = "Deactivate my account";
exports.deactivateModalTitleText = "Do you want to deactivate your account?";
exports.hostInformationText = 'Host Information';
exports.boaterInformationText = 'Boater Information';
exports.hereText = 'Here to rent a dock.';
exports.verifiedInformation = 'Verified Information';
exports.email = 'Email Address';
exports.governmentId= 'Government id';
exports.identity = 'Verify your identity';
exports.verified = 'Get Verified';
exports.deactivateModalHelperText =
  "Are you sure you want to deactivate your account?If ye, please enter your email to confirm deactivation.";
exports.deactivateModalYesBtnText = "Yes, Deactivate my account";
// Customizable Area End