import React from "react";
// Customizable Area Start
import BoaterSearchController, {
  Props,
  configJSON,
} from "./BoaterSearchController.web";
import {
  Container,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Grid,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

import {
  noDock,
  imgListing
} from "./assets";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { Pagination } from "@material-ui/lab";

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Josefin Sans",
      },
      h1: {
        fontFamily: "Josefin Sans",
        fontSize: "36px",
      },
      h5: {
        fontFamily: "Josefin Sans",
        fontSize: "20px",
        fontWeight: 700,
        margin: "5px",
        lineHeight: "28px",
      },
      body1: {
        fontFamily: "Outfit",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        margin: "10px 0px",
        textAlign: "center",
      },
      body2: {
        fontFamily: "Outfit",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        textAlign: "center",
        margin: "10px 0px",
      },
    },
    MuiInputBase: {
      input: {
        fontFamily: "Outfit, sans-serif !important",
        color: "#64748B",
      },
    },
    MuiButton: {
      root: {
        fontSize: "1rem",
      },
      contained: {
        fontSize: "18px",
        fontFamily: "Josefin Sans",
        color: "#FFFFFF",
        borderColor: "#4F9FF8 !important",
        backgroundColor: "#4F9FF8",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#FFFFFF",
          color: "#4F9FF8",
        },
      },
    },
  },
});

const Wrapper = styled("div")(({ theme }) => ({
  "& .title": {
    fontFamily: 'Josefin Sans',
    fontSize: '36px',
    fontWeight: 700,
    marginBottom: '66px',
    width: '500px',
    lineHeight: 1.2
  },
  "& .title22": {
    fontFamily: 'Josefin Sans',
    fontSize: '24px',
    fontWeight: 700,
    marginTop: '47px',
    marginBottom: '2px',
  },
  "& .notFoundMain": {
    padding: "80px 0 80px 0",
    display: "flex",
    gap: "15px",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginBottom: '-300px',
  },
  "& .notFoundMsg": {
    paddingTop: 20,
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 5,
  },
  "& .notFoundImg": {
    height: "35%",
    width: "35%",
    objectFit: "contain" as "contain",
  },
  [theme.breakpoints.down(600)]: {
    "& .notFoundImg": {
      width: "65%",
    },
  },
}));
export const noDataFound = (
  <Wrapper data-test-id="wrapper">
    <Box className="notFoundMain">
      <Typography className="title" >
        We couldn’t find what you searched for
      </Typography>
      <img
        src={noDock}
        alt='No lakes found'
        className="notFoundImg"
      />
      <Box className="notFoundMsg">
        <Typography className="title22">
          No lakes found
        </Typography>
      </Box>
    </Box>
  </Wrapper>
)
// Customizable Area End

export default class BoaterSearch extends BoaterSearchController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MainWrapper data-test-id="mainWrapper">
          <Container className="listingContainer" style={{ height: '100%' }}>
            <Header
              navigation={this.props.navigation}
              role="boater"
            />
            
            <Box mb={16}>
              <Typography
                data-test-id="pageTitleText"
                variant="h1"
                align="center"
                className="titleText"
              >
                {configJSON.titleText}
              </Typography>
              <Box className="searchWrapper">
                <TextField
                  id="search-bar"
                  className="searchBar"
                  variant="outlined"
                  placeholder="Search for docks, locations"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#64748B" }} />
                      </InputAdornment>
                    ),
                  }}
                  data-test-id="SearchByText"
                  value={this.state.searchTerm}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.handleChange(event)
                  }
                />
              </Box>
              <Box mt={3} mb={4}>
                {this.state.dockListData.length > 0 ? (
                  <Box>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {this.state.dockListData.map((dock) => {
                        const dockTitle = dock.state
                          ? `${dock.name}, ${dock.state}`
                          : `${dock.name}`;
                        return (
                          <Grid
                            item
                            key={dock.id}
                            lg={4}
                            md={6}
                            xs={12}
                            className="listItem"
                          >
                            <div
                              data-test-id="dock-box"
                              onClick={() =>
                                this.handleDockDetails(dock.id, dock.name)
                              }
                            >
                              <img
                                src={dock.image ? dock.image : imgListing}
                                className="listImg"
                              />
                              <Typography
                                variant="h5"
                                align="center"
                                style={{ cursor: "pointer" }}
                              >
                                {dock.name ? dockTitle : "Not Provided"}
                              </Typography>
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Box className="pagination">
                      <Pagination
                        data-test-id="pagination"
                        count={Math.ceil(this.state.total_pages)}
                        siblingCount={0}
                        page={this.state.current_page}
                        onChange={this.handlePageChange}
                      />
                      <Typography variant="subtitle1">
                        {(this.state.current_page - 1) * this.itemsPerPage + 1} - {this.state.current_page * this.itemsPerPage > this.state.total_count
                          ? this.state.total_count
                          : this.state.current_page * this.itemsPerPage} of {this.state.total_count} results
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  noDataFound
                )}
              </Box>
            </Box>
          </Container>
          <Footer navigation={this.props.navigation}/>
        </MainWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const MainWrapper = styled("div")(({ theme }) => ({
  "& .listingContainer": {
    flexDirection: "unset" as const,
    justifyContent: "center",
    marginBottom: "30px",
  },
  "& .titleText": {
    fontWeight: "700",
    margin: "20px 0px",
    lineHeight: "44px",
    letterSpacing: "-0.01em",
  },
  "& .searchWrapper": {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  "& .searchBar": {
    width: "440px",
    height: "56px",
    borderRadius: "8px",
  },
  "& .suggestionCardsWrapper": {
    width: "410px",
    padding: "24px 23px",
    borderRadius: "8px",
  },
  "& .cardMedia": {
    width: "120px",
    height: "120px",
    opacity: 0.5,
    borderRadius: "8px",
  },
  "& .cardContent": {
    margin: "0px",
    padding: "0px",
  },
  "& .listItem": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column" as const,
  },
  "& .listImg": {
    width: "338.6px",
    height: "248.46px",
    borderRadius: "20px",
    gap: "0px",
    opacity: "0px",
  },
  "& .pagination": {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px 50px 0 0",
    gap: 40,
    "& button": {
      textDecoration: "underline",
    },
  },
  "& .Mui-selected": {
    fontWeight: 700,
    backgroundColor: "transparent",
    textDecoration: "none !important",
  },
  [theme.breakpoints.down(500)]: {
    "& .pagination": {
      padding: "30px 20px 0 20px",
      gap: 5,
    },
  },
}));
// Customizable Area End
