// Customizable Area Start
import React from "react";
import { Container, Box, Typography, Chip } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import { theme } from "../../../blocks/chat/src/Chat.web";

export const reservationTheme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Josefin Sans",
      },
      h4: {
        fontSize: "1.5rem",
        fontFamily: "Josefin Sans",
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: "1rem",
        fontFamily: "Outfit",
        fontWeight: 400,
        color: "#64748B",
      },
    },
    ...theme,
  },
});

import ReservationListController, {
  Props,
  configJSON,
} from "./ReservationListController.web";

export default class ReservationList extends ReservationListController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { selectedView } = this.state;

    return (
      <ThemeProvider theme={reservationTheme}>
        <Wrapper data-test-id="mainWrapper">
          <Container maxWidth="lg">
            <Header
              role="host"
              isLoggedIn={true}
              navigation={this.props.navigation}
            />
            <Box className="inboxHeader">
              <Typography
                variant="h4"
                className="headerTitle padding20"
                data-test-id="titleText"
              >
                {configJSON.reservationsTitleText}
              </Typography>
            </Box>
            <Box className="bodyContent">
              <Box className="flexItems">
                {[
                  configJSON.dockRequestsText,
                  configJSON.dockedText,
                ].map((chipItem) => (
                  <Chip
                    key={chipItem}
                    data-test-id="tableViewSelector"
                    label={
                      <Typography
                        variant="subtitle1"
                        className={`${
                          chipItem === selectedView ? "chipTextSelected" : ""
                        }`}
                      >
                        {chipItem}
                      </Typography>
                    }
                    variant="outlined"
                    className={`chipContent ${
                      chipItem === selectedView ? "selectedChip" : ""
                    }`}
                    onClick={() => this.handleListView(chipItem)}
                  />
                ))}
              </Box>
              <Box>
                {this.renderReservationTable()}
              </Box>
            </Box>
          </Container>
        </Wrapper>
      </ThemeProvider>
    );
  }
}

const Wrapper = styled("div")(({ theme }) => ({
  "& .coloredSubtitle": {
    color: "#0F172A",
  },
  "& .fontWeight600": {
    fontWeight: 600,
  },
  "& .padding20": {
    padding: "20px 0 10px 0",
  },
  "& .padding30": {
    padding: "30px 0 20px 0",
  },
  "& .flexItems": {
    display: "flex",
    flexWrap: "wrap",
    gap: "7px",
  },
  "& .chipContent": {
    padding: "18px 6px",
    borderRadius: "26px",
    borderColor: "#CBD5E1",
    height:"44px",textTransform:"none"
  },
  "& .chipTextSelected": {
    fontWeight: 700,
    color: "white",
    fontSize:"16px",fontFamily:'Outfit'
  },
  "& .chipTexts": {
    fontWeight: 400,
    color: "#CBD5E1", fontSize:"16px",fontFamily:'Outfit'
  },
  "& .bodyContent": {
    padding: "10px 0",
  },
  "& .selectedChip": {
    backgroundColor: "#4F9FF8 !important",
  },
  "& .inboxHeader": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [theme.breakpoints.down(840)]: {
    "& .headerTitle": {
      fontSize: "1.3rem",
    },
  },
  [theme.breakpoints.down(600)]: {
    "& .inboxHeader": {
      paddingTop: "10px",
    },
    "& .headerTitle": {
      fontSize: "1.2rem",
    },
  },
}));

// Customizable Area End
