// Customizable Area Start
import React from "react";
import {
  styled,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LoginController, {
  Props,
} from "./LoginController";
import GenericModal from "../../../components/src/GenericModal";
import Toast from "../../../components/src/Toast";

const config = require("./config");
const assets = require("./assets");

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Outfit, sans-serif"
      },
      body1: {
        fontFamily: "Outfit, sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "Outfit, sans-serif !important"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "Outfit, sans-serif"
      }
    }
  }
})

const MainWrapper = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  margin: '0',
  padding: '0',
  width: '100%',
  [theme.breakpoints.down('md')]: {},
  '& input.error': {
    borderColor: '#F87171',
  },
  '& .link': {
    background: 'none',
  },
  '& .btn': {
    border: 0,
    cursor: 'pointer',
    fontFamily: '"Outfit", sans-serif',
  },
  '& .title': {
    color: '#0F172A',
    fontFamily: '"Josefin Sans", sans-serif',
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.12px',
    marginBottom: 16,
  },
  '& .brandname': {
    color: '#4F9FF8',
  },
  '& .field-wrapper': {
    marginBottom: 16,
  },
  '& .field-label': {
    display: 'block',
    color: '#334155',
    fontFamily: 'Outfit',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '22px',
    marginBottom: 4,
  },
  '& .field-value': {
    display: 'block',
    width: '100%',
    padding: '10px 8px',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
    background: '#FFF',
    color: '#0F172A',
    fontFamily: 'Outfit',
    fontSize: 16,
    fontWeight: 400,
    '&::placeholder': {
      color: '#94A3B8',
    }
  },
  '& .forgotpw-link': {
    display: 'inline-block',
    marginLeft: 'auto',
    color: '#64748B',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22px',
  },
  '& .login-btn': {
    width: '100%',
    padding: 16,
    background: '#4F9FF8',
    color: '#FFF',
    borderRadius: '8px',
    fontSize: 16,
    fontWeight: 700,
    '&:disabled': {
      color: '#64748B',
      background: '#F1F5F9',
      cursor: 'not-allowed',
    },
  },
  '& .create-account-link': {
    color: '#4F9FF8',
    fontSize: 16,
    fontWeight: 700,
  },
  '& .error-msg': {
    color: '#DC2626',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
    marginTop: 2,
  },
  '& .password-wrapper': {
    position: 'relative',
  },
  '& .toggle-pw-visibility': {
    position: 'absolute',
    zIndex: 100,
    top: '50%',
    right: 8,
    transform: 'translateY(-50%)',
    backgroundImage: `url(${assets.wannaSee})`,
    width: 24,
    height: 24,
    cursor: 'pointer',
  },
}));

export default class Login extends LoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { enteredEmail, enteredPassword, isPasswordObscured, passwordError, emailError, errorMessage, isLoading } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <GenericModal dataTest="login-modal" open={this.props.isOpen} onClose={this.props.onClose}>
          <MainWrapper data-test="mainLoginWrapper">
            <div className="title" data-test="title">Welcome to <span className="brandname">Daydocker</span></div>
            {errorMessage && <Toast data-test="toast" message={errorMessage} />}
            <form data-test="login-form" style={{ margin: 0 }} onSubmit={this.handleLoginSubmit}>
              <div className="field-wrapper">
                <label className="field-label" htmlFor="email">Email address</label>
                <input 
                  data-test="input-email"
                  value={enteredEmail} 
                  onChange={this.emailOnChangeHandler} type="email" id="email" 
                  className={`field-value ${emailError ? 'error' : ''}`} 
                  placeholder="eg. email@address.com" />
                <div className="error-msg" data-test="email-error">{emailError}</div>
              </div>
              <div className="field-wrapper">
                <label htmlFor="password" className="field-label">Password</label>
                <div className="password-wrapper">
                  <input 
                    value={enteredPassword} 
                    data-test="input-password"
                    onChange={this.passwordOnChangeHandler} 
                    type={isPasswordObscured ? 'password' : 'text'} 
                    id="password" 
                    className={`field-value ${passwordError ? 'error' : ''}`}
                    placeholder="************" />
                  <div 
                    onClick={this.togglePasswordVisibility}
                    data-test="togglePasswordVisibility"
                    className="toggle-pw-visibility" 
                    style={{backgroundImage: `url(${isPasswordObscured ? assets.wannaSee : assets.notWannaSee})`}}></div>
                </div>
                {passwordError && <div className="error-msg" data-test="password-error">{passwordError}</div>}
              </div>
              <div style={{ marginTop: 24, marginBottom: 40, display: 'flex' }}>
                <button
                  data-test="goToForgotPasswordBtn"
                  type="button"
                  onClick={this.goToForgotPassword}
                  className="btn link forgotpw-link">Forgot Password?
                </button>
              </div>
              <button 
                title={this.isDisabled ? 'Please fill in all fields' : ''}
                disabled={this.isDisabled}
                data-test="login-btn"
                className="btn login-btn">{isLoading ? 'Logging in...' : 'Login'}</button>
              <div style={{ marginTop: 24, textAlign: 'center' }}>
                <button 
                  data-test="go-to-signup"
                  type="button"
                  onClick={this.goToSignup}
                  className="btn link create-account-link">Create a new account</button>
              </div>
            </form>
          </MainWrapper>
        </GenericModal>
      </ThemeProvider>
    );
  }
}



// Customizable Area End
