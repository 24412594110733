import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  getLastPartOfURL,
  sendAPIRequest,
} from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

interface ISavedCard {
  id: string;
  name: string;
  last4: string;
  type: string;
  expiry_date: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  paymentIntentId: string;
  timer: string;
  onBackBtnClick: () => void;
  renderPaymentCard: (onPayment: () => void) => JSX.Element;
  onSuccessCall: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  selectedCard: ISavedCard | null;
  savedCardList: ISavedCard[];
  addedCard: { token: string; card_holder: string };
  paymentId: string;
  errorMsg: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DockBookingPaymentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchSavedCardApiCallId: string = "";
  makeDockBookingPaymentApiCallId: string = "";
  confirmBookingPaymentApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      selectedCard: {} as ISavedCard,
      savedCardList: [],
      addedCard: { token: "", card_holder: "" },
      paymentId: "",
      errorMsg: "",
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getSavedCardList();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    // Customizable Area Start
    this.apiSuccessCallBackController(apiRequestCallId, responseJson);
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (this.state.savedCardList !== prevState.savedCardList)
      this.setState({
        selectedCard: this.state.savedCardList[0],
        paymentId: this.state.savedCardList[0]?.id,
      });
  }

  storeCardDetails = (token: { id: string }, card_holder: string) => {
    this.setState({ addedCard: { token: token.id, card_holder } });
  };

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.fetchSavedCardApiCallId]: this.handlefetchSavedCardAPIResponse,
      [this.makeDockBookingPaymentApiCallId]: this.handleMakeDockBookingApiCall,
      [this.confirmBookingPaymentApiCallId]: this.handleConfirmBookingApiCall,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  getSavedCardList = async () => {
    const token = await getStorageData("token");
    this.setState({ isLoading: true, errorMsg: "" });
    this.fetchSavedCardApiCallId = sendAPIRequest(
      `bx_block_stripe_integration/payment_methods`,
      {
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  handleBookDockBtnClick = async () => {
    if (!this.state.selectedCard) {
      const authToken = await getStorageData("token");
      this.setState({ isLoading: true, errorMsg: "" });
      this.makeDockBookingPaymentApiCallId = sendAPIRequest(
        `bx_block_stripe_integration/payment_methods/create_payment_method`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: authToken,
          },
          body: {
            payment_method: {
              token: this.state.addedCard.token,
              card_holder: this.state.addedCard.card_holder,
            },
          },
        }
      );
    } else {
      this.handleConfirmBooking();
    }
  };

  handleConfirmBooking = async () => {
    const authToken = await getStorageData("token");
    this.setState({ isLoading: true, errorMsg: "" });
    this.confirmBookingPaymentApiCallId = sendAPIRequest(
      `bx_block_stripe_integration/payments/confirm`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: authToken,
        },
        body: {
          payment: {
            reservation_id: getLastPartOfURL().url,
            payment_method_id: this.state.paymentId,
            payment_intent_id: this.props.paymentIntentId,
          },
        },
      }
    );
  };

  handlefetchSavedCardAPIResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      meta?: { message: string };
      data?: {
        id: string;
        type: string;
        attributes: {
          billing_details: { name: string };
          card: {
            last4: string;
            brand: string;
            exp_month: number;
            exp_year: number;
          };
        };
      }[];
    };
    if (response.data) {
      this.setState({
        savedCardList: response.data.map((cardItem) => {
          return {
            id: cardItem.id,
            name: cardItem.attributes.billing_details.name,
            last4: cardItem.attributes.card.last4,
            type: cardItem.attributes.card.brand,
            expiry_date:
              String(cardItem.attributes.card.exp_month).padStart(2, "0") +
              "/" +
              cardItem.attributes.card.exp_year,
          };
        }) as ISavedCard[],
        isLoading: false,
        errorMsg: "",
        selectedCard: null,
      });
    }
  };

  handleMakeDockBookingApiCall = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      data?: { id: string; type: string; attributes: object };
    };
    if (response.data) {
      this.setState({
        paymentId: response.data.id,
      });
      this.handleConfirmBooking();
    }
  };

  handleConfirmBookingApiCall = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      data?: { id: string; type: string; attributes: object };
    };
    if (response.data) {
      this.props.onSuccessCall();
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    this.setState({ isLoading: false });

    const { errors } = responseJSON as { errors: { params: string }[] };
    if (errors) {
      this.setState({ errorMsg: errors[0].params });
      return true;
    }
    return false;
  };

  handleSelectedCard = (selectedCard: ISavedCard | null) => {
    this.setState({
      selectedCard,
      paymentId: selectedCard?.id as string,
    });
  };
  // Customizable Area End
}
