import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

interface IModalProps {
  type?: "error" | "success";
  message: string;
  onClose?: () => void;
}

const Toast: React.FC<IModalProps> = ({ type = "error", message, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(true);
    const timer = setTimeout(() => {
      setVisible(false);
      if (onClose) {
        onClose();
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [type, message, onClose]);

  if (!visible) {
    return null;
  }

  return (
    <Box
      sx={{ width: "100%" }}
      style={{
        display: "flex",
        gap: 5,
        justifyContent: "space-between",
        alignItems: "center",
        fontFamily: '"Outfit", sans-serif',
        backgroundColor: type === "error" ? "#FEE2E2" : "#ECFDF5",
        marginTop: 10,
        position: "relative",
        borderRadius: 8,
        marginBottom: 10,
      }}
    >
      <Box style={{ display: "flex", gap: 5, alignItems: "center" }}>
        <Box
          style={{
            width: 5,
            height: "100%",
            background: type === "error" ? "#DC2626" : "#059669",
            position: "absolute",
            borderRadius: "8px 0 0 8px",
          }}
        ></Box>
        <Typography
          data-test="error-msg"
          style={{
            fontFamily: '"Outfit", sans-serif',
            marginTop: 0,
            padding: "12px 16px",
            fontSize: 12,
            color: type === "error" ? "#DC2626" : "#059669",
          }}
        >
          {message}
        </Typography>
      </Box>
      {onClose && (
        <CloseRoundedIcon
          style={{
            color: type === "error" ? "#DC2626" : "#059669",
            marginRight: "15px",
            cursor: "pointer",
          }}
          onClick={() => {
            setVisible(false);
            if (onClose) {
              onClose();
            }
          }}
        />
      )}
    </Box>
  );
};

export default Toast;
