import React from "react";
// Customizable Area Start
import {
  Button,
  Container,
  Typography,
  AppBar,
  ThemeProvider,
  Box,
  createTheme,
  Divider,
  FormControl,
  RadioGroup,
  InputBase,
  InputAdornment,
  FormControlLabel,

} from '@material-ui/core';
import { styled } from '@mui/system';
import { imgHeaderLogo, footerImg2, sunImage, addImage, cancelIcon } from "./assets";
import Radio, { RadioProps } from '@material-ui/core/Radio';
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

const BpIcon = styled('span')<{
    width?: number;
    height?: number;
}>(({ theme, width, height }) => ({
    borderRadius: '50%',
    width: width || 20,
    height: height || 20,
    boxShadow: 'inset 0 0 0 1px #94A3B8, inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)(({ width, height }) => ({
    backgroundColor: '#4F9FF8',
    border: "none",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: width || 20,
        height: height || 20,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
}));
const BpRadio = (props: RadioProps & { iconProps?: { width?: number; height?: number }, type: 'customized' | 'policy' }) => {
    const { iconProps, type, ...radioProps } = props;
    const inputProps = type === 'customized' 
    ? { 'aria-label': 'customized-radio' }
    : { 'aria-label': 'cancellation-policy' };

    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon {...iconProps} />}
            icon={<BpIcon {...iconProps} />}
            inputProps={inputProps}
            {...radioProps}
        />
    );
}
const theme = createTheme({
    palette: {
        primary: {
            main: "#4F9FF8",
        },
    },
    overrides: {
        MuiTypography: {            
            subtitle1: {
                fontSize: "14px",
                fontFamily: "Outfit",
                color: "#64748B",
                fontWeight: 400,
            },
            h5: {
                fontFamily: "Josefin Sans",
                fontSize: "1.2rem",
                fontWeight: 700,
                color: "#0F172A",
            },
          colorTextSecondary:{
                color: "#0F172A",
            }
        },
        MuiButton: {
            root: {
                borderRadius: "8px",
                margin: "10px 0 30px 0",
                padding: "16px 32px",
            },
            containedPrimary: {
                color: "white",
                fontSize: "16px",
                fontWeight: 700,
                fontFamily: "Outfit",
                textTransform: "capitalize",
            },
        },
        MuiFormControlLabel: {
            label: {
                fontFamily: "Outfit",
                fontSize: "16px",
                color: "#0F172A",
                '&$disabled': {
                    color: '#64748B',
                },
            },
        },       
        MuiDivider: {
            root: { margin: "15px 0" },
        },
        MuiRadio: {
            root: {
                color: "#4F9FF8",
                '&$checked': {
                    color: "#106ba3",
                },
            },
        },
    },
});
// Customizable Area End

import DockListingShowOffController, {
  Props,
  configJSON,
} from "./DockListingShowOffController.web";

export default class DockListingShowOff extends DockListingShowOffController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  // Customizable Area Start
  // Customizable Area End
  
  render() {
    // Customizable Area Start
    const { updateProImages } = this.state;
    return (
          <>
              <ThemeProvider theme={theme}>
                  <Wrapper data-test-id="calendarModalWrapper">
                      <Container maxWidth="lg">
                          <AppBar position="relative" elevation={0} className="headerContent">
                              <img src={imgHeaderLogo} className="logoContent" />
                          </AppBar>
                            <Button startIcon={<ArrowBackIosRoundedIcon />} style={{ textTransform: "none" }} className="backButton"
                                onClick={() => this.handleNavigation("DockListingRulesInstructions")}
                            >Back</Button>

                            <Box className="bodyContent" >
                            <Typography className="featureText" style={{marginBottom:'28px'}} onClick={() => this.removeImage(1)}>
                                {configJSON.showOffText}
                            </Typography>
                            <Box>
                                <Typography style={webStyle.policiesText}>{configJSON.addPhotos}</Typography>
                            </Box>
                            <Box className='imageBoxs'>
                                {this.state.imageBoxs.map((image, index)=> {
                                    return(
                                        <div style={webStyle.boxStyle} key={index}>                                                                           
                                            {updateProImages !== null && updateProImages[index]  ? (
                                                <div style={{ position: 'relative' }}>
                                                    <img src={updateProImages[index] || undefined} alt="dock image" width= '156px' height= '165px'/>
                                                    <img src={cancelIcon} alt="close" style={{...webStyle.closeButtonStyle, position: 'absolute',}} onClick={() => this.removeImage(index)} />
                                                </div>   
                                            ) : (
                                                <img src={addImage} alt="add image" style={{cursor: 'pointer'}} onClick={() => this.uploadImageFile(index)} />
                                            )}
                                        </div>
                                    )
                                })}

                            </Box>
                            {this.state.uploadImgErrMsg && (
                                <div style={webStyle.errMsg}>
                                {this.state.uploadImgErrMsg}
                                </div>
                            )}
                            <Box className='ratingBox'>
                                <img src={sunImage} alt="sun"/>
                                <Typography className='ratingText'>{configJSON.bookingRateText}</Typography>
                                <img src={sunImage} alt="sun"/>
                            </Box>
                            <Divider className="marginBottom" />
                            <Typography className="featureText" style={{marginBottom:'16px'}}>
                                {configJSON.reservationsOptions}
                            </Typography>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-customized-radios"
                                    value={this.state.reservation_type}
                                    name="customized-radios"
                                    data-testId="radio-buttons"
                                    onChange={this.handleRadioChange}
                                    style={{ gap: "20px" }}
                                >
                                    <InputBase
                                        className={`radioInput ${this.state.reservation_type === configJSON.reservationsOption1 ? 'selected' : 'unSelected'}`}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <FormControlLabel
                                                    control={
                                                        <BpRadio
                                                            type="customized"
                                                        />
                                                        }
                                                    value={configJSON.reservationsOption1}
                                                    label={
                                                        <div style={{marginLeft:'7px'}}>
                                                            <Typography style={{ fontWeight: this.state.reservation_type === configJSON.reservationsOption1 ? 600 : 400, fontFamily: "Outfit", fontSize: "16px", color: "#0F172A" }}>
                                                                {configJSON.reservationsOption1}
                                                            </Typography>
                                                            <Typography variant="subtitle1">
                                                                {configJSON.reservationsSubOption1}
                                                            </Typography>
                                                        </div>
                                                    }
                                                />
                                            </InputAdornment>
                                        }
                                    />
                                    <InputBase
                                        className={`radioInput ${this.state.reservation_type === configJSON.reservationsOption2 ? 'selected' : 'unSelected'}`}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <FormControlLabel
                                                    control={
                                                        <BpRadio
                                                            type="customized"
                                                        />
                                                    }
                                                    label={
                                                        <div style={{ marginLeft: '7px' }}>
                                                            <Typography style={{ fontWeight: this.state.reservation_type === configJSON.reservationsOption2 ? 600 : 400, fontFamily: "Outfit", fontSize: "16px", color: "#0F172A" }}>
                                                                {configJSON.reservationsOption2}
                                                            </Typography>
                                                            <Typography variant="subtitle1">
                                                                {configJSON.reservationsSubOption2}
                                                            </Typography>
                                                        </div>
                                                    }
                                                    value={configJSON.reservationsOption2}
                                                />
                                            </InputAdornment>
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>

                            {this.state.reservationErrMsg && (
                                <div style={webStyle.errMsg}>
                                {this.state.reservationErrMsg}
                                </div>
                            )}
                            <Divider className="marginBottom" />
                            <Typography className="featureText" style={{marginBottom:'16px'}}>
                                {configJSON.cancellationPolicy}
                            </Typography>
                            <div>
                                <Typography style={webStyle.policiesText}>{configJSON.policiesDesc}</Typography>
                                <Typography style={{...webStyle.policiesText, marginBottom: "14px",marginTop: "7px",}}>{configJSON.policies}</Typography>
                                
                                <FormControl component="fieldset" style={{marginLeft:'3px',}}> 
                                    <RadioGroup aria-label="cancellation_policy" name="cancellation_policy" value={this.state.cancellation_policy} onChange={this.handleCancellationPolicy}>
                                        <FormControlLabel
                                            value="12_hour" 
                                            control={
                                                <BpRadio                                                    
                                                    iconProps={{ width: 16, height: 16 }}
                                                    type="policy"
                                                />
                                            } 
                                            style={{marginBottom:'12px'}}
                                            label={<Typography style={{marginLeft:'3px', ...webStyle.policiesText}}>{configJSON.policy1}</Typography>} 
                                        />
                                        <FormControlLabel value="24_hour" control={
                                                <BpRadio
                                                    iconProps={{ width: 16, height: 16 }}
                                                    type="policy"
                                                />
                                            } 
                                            label={<Typography style={{marginLeft:'3px', ...webStyle.policiesText}}>{configJSON.policy2}</Typography>} 
                                            style={{marginBottom:'12px'}}
                                        />
                                        <FormControlLabel value="72_hour" control={
                                                <BpRadio
                                                    iconProps={{ width: 16, height: 16 }}
                                                    type="policy"
                                                />
                                            } 
                                            label={<Typography style={{marginLeft:'3px', ...webStyle.policiesText}}>{configJSON.policy3}</Typography>} 
                                        />
                                    </RadioGroup>
                                </FormControl> 
                                {this.state.cancelPolicyErrMsg && (
                                    <div style={webStyle.errMsg}>
                                    {this.state.cancelPolicyErrMsg}
                                    </div>
                                )}  
                                <Box style={{...webStyle.displayTexts, flexDirection:'column',}}>
                                    <Typography style={webStyle.policiesText}>{configJSON.policydesc1}</Typography>
                                    <Typography style={webStyle.policiesText}>{configJSON.policydesc2}</Typography>
                                    <Typography style={webStyle.policiesText}>{configJSON.policydesc3}</Typography>
                                    <Typography style={webStyle.policiesText}>{configJSON.policydesc4}</Typography>
                                    <Typography style={{...webStyle.policiesText, fontWeight:700}}>{configJSON.policydesc5}</Typography>
                                    <Typography style={webStyle.policiesText}>{configJSON.policydesc6}</Typography>
                                    <Typography style={webStyle.policiesText}>{configJSON.policydesc7}</Typography>
                                    <Typography style={{...webStyle.policiesText, fontWeight:700}}>{configJSON.policydesc8}</Typography>
                                    <Typography style={webStyle.policiesText}>{configJSON.policydesc9}</Typography>
                                    <Typography style={webStyle.policiesText}>{configJSON.policydesc10}</Typography>
                                </Box>
                            </div>
                            <Button variant="contained" color="primary" data-testid="nextBtn" style={{ marginTop: "40px"}}
                                onClick={this.handleUpdateDockData}
                            >
                                {configJSON.nextBottonText}
                            </Button>
                            </Box>
                      </Container>
                      <Box className="progressBar">
                            <img alt="progress-bar" src={footerImg2} />
                        </Box>
                  </Wrapper>
              </ThemeProvider>
          </>
      )
       // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    errMsg:{ color: 'red', fontSize:'12px', marginTop:'20px', marginBottom:'20px' },
    closeButtonStyle : {        
        top: '8px',
        right: '8px',
        cursor: 'pointer',
    },
    boxStyle: {
        minWidth: '156px',
        maxWidth: '156px',
        height: '165px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        background: '#F1F5F9',
    },      
    displayTexts:{
        display:"flex",gap:"8px", margin:'12px 0'
      },
    policiesText: {
      fontFamily: "Outfit",
      fontSize: "16px",
      fontWeight: 400,
      color: 'black',
      lineHeight: '20.16px',
    }, 
  }
const Wrapper = styled("div")(({ theme }) => ({
    "& .imageBoxs":{
        minWidth: '156px',
        minHeight: '165px',
        display:'flex',
        gap: '16px',
        marginTop: '20px',
        '@media (max-width: 1000px)': { 
            display: 'grid',      
            gridTemplateColumns: 'repeat(auto-fill, minmax(165px, 1fr))'    
        }
    },
    "& .ratingBox":{display:"flex",justifyContent:"space-between",alignItems:"center", backgroundColor:"#fff7e7", width: "400px", height: "46px", borderRadius: "8px",
    padding:'0 8px', marginTop:'16px',
    '@media (max-width: 469px)': {
        width: "350px", padding:0,
      },
    '@media (max-width: 399px)': {
      width: "325px", padding:0,
    },
    },
    "& .ratingText":{
        fontFamily: 'Outfit',
        fontSize: '14px',
        fontWeight: 400,
        color: "#0F172A",
        '@media (max-width: 399px)': {
            fontSize: '12px',
          },
    },
    "& .unSelected": {
        backgroundColor: '',
        borderColor: '#94A3B8',
    },
    "& .logoContent": {
        width: "20%",
        height: "auto",
        minWidth: "175px",
      },
      "& .bodyContent": {
        margin: "-60px 240px",
        '@media (max-width: 900px)': {
          margin: "0 10px",
        },
      },
      "& .addressBox": {
        margin: 0,
      },
    "& .radioInput": {
      width: "375px",
      '@media (max-width: 450px)': {
        width: "100%",
      },
      height: "78px",
      padding: "16px 0 16px 16px",
      borderRadius: "8px",
      border: "1px solid #94A3B8",
    },
    "& .selected": {
      backgroundColor: '#EDF5FE',
      borderColor: '#4F9FF8',
    },
    "& .backButton": {
      fontWeight: 700,
      fontSize: "18px",
      fontFamily: "Outfit",
      color: "#64748B",
      marginTop:"30px",
    },
    "& .featureText": {
      fontWeight: 700,
      fontSize: "18px",
      fontFamily: "Outfit", color: "#0F172A"
    },
    "& .headerContent": {
      width: "100%", height: "auto", backgroundColor: "#FFFFFF", padding: "10px 0"
    }, 
    "& .marginBottom": {
      marginBottom: "32px",marginTop: "32px",
    },
    "& .progressBar": {
      width: "100%",
    },
    "& .progressBar img": {
      width: "100%",
    }
  }));
  
// Customizable Area End
