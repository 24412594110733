import React from "react";
// Customizable Area Start
import { 
    Container, 
    Box, 
    Typography,
    Grid, 
    Chip, 
    List, 
    ListItem, 
    ListItemIcon, 
    ListItemText,
    ImageList,
    ImageListItem,
    styled,
    ThemeProvider,
    createTheme,
    Button
} from "@material-ui/core";
import { MdAccessTime } from "react-icons/md";
import { TbDoorEnter, TbDoor, TbSparkles} from "react-icons/tb";
import { FiShoppingCart, FiHome } from "react-icons/fi";
import { AiOutlineFire } from "react-icons/ai";
import { PiBone } from "react-icons/pi";
import { BiParty } from "react-icons/bi";
import { TfiSpray } from "react-icons/tfi";
import { BsWind } from "react-icons/bs";
import { FaRegDotCircle } from "react-icons/fa";
import { CiCreditCard1 } from "react-icons/ci";
import { VscSparkle } from "react-icons/vsc";
import Header from '../../../components/src/Header';
import Footer from '../../../components/src/Footer';
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { imgListing } from "../../../blocks/landingpage/src/assets";
import Login from "../../../blocks/email-account-login/src/Login.web";

const reservationTheme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
    overrides: {
      MuiTypography: {
        root: {
          fontFamily: "Josefin Sans",
        },
        h3: {
          fontSize: "1.5rem",
          fontFamily: "Josefin Sans",
          fontWeight: 700,
        },
        h4: {
          fontSize: "1.125rem",
          fontFamily: "Outfit",
          fontWeight: 700,
        },
        h6: {
          fontSize: "1rem",
          fontFamily: "Outfit",
          fontWeight: 700,
        },
        body1: {
          fontSize: "1rem",
          fontFamily: "Outfit",
          fontWeight: 400,
        },
        subtitle1: {
          fontSize: "1rem",
          fontFamily: "Outfit",
          fontWeight: 400,
          color: "#64748B",
        },
        subtitle2: {
          fontSize: "14px",
          fontFamily: "Outfit",
          fontWeight: 400,
          color: "#7E5912",
        }
      },
      MuiImageList:{
        root:{
          '& .MuiImageListItem-item': {
            borderRadius: '10px',
          }
        }
      },
      MuiChip: {
        root: {
          height: "60px",
          color: "#7E5912",
          border: "1px solid #B19969 !important",
          borderRadius: "8px",
          padding: "10px 5px",
          "& .MuiChip-icon": {
            width: "20px",
            height: "20px",
            color: "#7E5912",
          },
          "& .MuiChip-label":{
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: 400,
            fontFamily: "Outfit",
          }
        }
      },
      MuiButton: {
        text: {
          fontSize: "0.875rem",
          fontFamily: "Outfit",
          fontWeight: 600,
          textTransform: "none",
        }
      },
      MuiListItem: {
        root: {
          padding: "3px 0px !important",
        }
      },
      MuiListItemIcon: {
        root:{
          color: "#000000",
          minWidth: "24px",
          marginRigth: "8px",
        }
      },
      MuiListItemText: {
        root:{
          fontSize: "1rem",
          fontFamily: "Outfit",
          fontWeight: 400,
          lineHeight: "20px",
        }
      }
    },
  });

const rules = [
  { icon: <MdAccessTime />, text: "Check-in: After 4:00 PM" },
  { icon: <MdAccessTime />, text: "Checkout: 10:00 AM" },
  { icon: <TbDoorEnter />, text: "Self check-in with lockbox" },
  { icon: <FiShoppingCart />, text: "Not suitable for infants (under 2 years)" },
  { icon: <AiOutlineFire />, text: "No smoking" },
  { icon: <PiBone />, text: "No pets" },
  { icon: <BiParty />, text: "No parties or events" },
];

const instructions  = [
  { icon: <TbSparkles />, text: "Committed to Airbnb’s enhanced cleaning process" },
  { icon: <TfiSpray />, text: "Airbnb's social-distancing and other COVID-19-related guidelines apply" },
  { icon: <BsWind />, text: "Carbon monoxide alarm" },
  { icon: <FaRegDotCircle />, text: "Smoke alarm" },
  { icon: <CiCreditCard1 />, text: "Security Deposit - if you damage the home, you may be charged up to $566" },
];

const getListType = (type: string)=> {
  return type === "whole_dock" ? 'Whole dock' : 'Slip only';
};

const getDockType = (type: string)=> {
  const dockType = type === "permanent" ? "Permanent" : "BulkHead";
  return type === "floating" ? "Floating" : dockType;
};
// Customizable Area End

import ManageListingDetailsController, {
  Props,
  configJSON,
} from "./ManageListingDetailsController.web";

export default class ManageListingDetails extends ManageListingDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <>
        <Login
          data-test-id="openLoginModal"
          isOpen={this.state.openLoginModal}
          onClose={() => {
            this.setState({ openLoginModal: false });
          }}
          navigation={this.props.navigation}
          id="login modal"
        />
        <ThemeProvider theme={reservationTheme}>
          <Container maxWidth="lg">
            <Header role="host" navigation={this.props.navigation} />
            <MainWrapper data-test-id="mainWrapper">
              <Button
                className="backBtn"
                startIcon={<ArrowBackIosRoundedIcon />}
                onClick={this.onBackBtnClick}
                data-test-id="backBtn"
              >
                {configJSON.backBtnText}
              </Button>
              <Box my={2} className="listDetailsHeader">
                <Typography
                  variant="h3"
                  className="headerTitle"
                  data-test-id="headerTitle"
                >
                  {configJSON.manageListingTitleText}
                </Typography>
              </Box>
              <Grid container>
                <Grid item xs={12} sm={6} md={4} className="imgListGrid">
                  <img
                    src={this.state.listedDockDetailsData.images?.[0] || imgListing}
                    className="bannerImg"
                  />
                  <ImageList
                    cols={4}
                    rowHeight={105}
                    component="ul"
                    className="imgList"
                    gap={1}
                  >
                    {this.state.listedDockDetailsData.images?.slice(1)?.map((image) => (
                      <ImageListItem 
                        className="imgListItem" 
                        key={image}
                      >
                        <img
                          className="dockImage"
                          src={image || imgListing}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <Box p={3}>
                    <Box display="flex" justifyContent="space-between">
                      <div>
                        <Typography variant="h4" component="h2">
                          {this.state.listedDockDetailsData.listing_title?.trim() || "No Title"}
                        </Typography>
                        <Typography variant="subtitle1">
                          {this.state.listedDockDetailsData.administrative_area_level_1 ?
                            (`${this.state.listedDockDetailsData.administrative_area_level_1}, ${this.state.listedDockDetailsData.administrative_area_level_2}`) :
                            "Not Provided"
                          }
                        </Typography>
                      </div>
                      <Typography variant="h3" component="h3" gutterBottom>
                        ${this.state.listedDockDetailsData.guest_total}
                      </Typography>
                    </Box>
                    <Box my={2}>
                      <Typography variant="body1" gutterBottom>
                        {this.state.listedDockDetailsData.about?.trim() || "No description"}
                      </Typography>
                    </Box>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Chip 
                          icon={<FiHome />} 
                          label={
                            <Box className="flexCol">
                              <Typography variant="subtitle2" className="fontWeight500">
                                {getDockType(this.state.listedDockDetailsData.dock_type)}
                              </Typography>
                              <Typography variant="subtitle2">
                                {configJSON.dockTypeText}
                              </Typography>
                            </Box>
                          } 
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item>
                        <Chip 
                          icon={<VscSparkle />} 
                          label={
                            <Box className="flexCol">
                              <Typography variant="subtitle2" className="fontWeight500">
                                {getListType(this.state.listedDockDetailsData.listing_type)}
                              </Typography>
                              <Typography variant="subtitle2">
                                Listing Type
                              </Typography>
                            </Box>
                          } 
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item>
                        <Chip 
                          icon={<TbDoor />} 
                          label={
                            <Box className="flexCol">
                              <Typography variant="subtitle2" className="fontWeight500">
                                {this.state.listedDockDetailsData.water_depth || 0}ft
                              </Typography>
                              <Typography variant="subtitle2">
                                {configJSON.waterDepthText}
                              </Typography>
                            </Box>
                          }
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Box mt={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="h6">
                        {configJSON.featureText}
                      </Typography>
                      <Button variant="text" data-test-id="featuresEditBtn" onClick={() => this.handleEditBtnClick("DockListingFeatures", this.state.listedDockDetailsData.id)}>
                        {configJSON.editTextBtn}
                      </Button>
                    </Box>
                    <Box className="marginTop10 gridCol2">
                      {this.state.listedDockDetailsData.features?.length > 0 ? (
                        this.state.listedDockDetailsData.features.map((feature, index) => (
                          <Typography variant="body1" key={index}>
                            {feature.name}
                          </Typography>
                        ))) : (
                          <Typography variant="body1" color="textSecondary">
                            No Listing Features
                          </Typography>
                        )
                      }
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="h6">
                        {configJSON.addOnsText}
                      </Typography>
                      <Button variant="text" data-test-id="addOnsEditBtn" onClick={() => this.handleEditBtnClick("DockListingFeatures", this.state.listedDockDetailsData.id)}>
                        {configJSON.editTextBtn}
                      </Button>
                    </Box>
                    <Box className="marginTop10 gridCol2 gridColGap">
                      {this.state.listedDockDetailsData.dock_add_ons &&
                        this.state.listedDockDetailsData.dock_add_ons?.length > 0 ? (
                          this.state.listedDockDetailsData.dock_add_ons.map((addon) => (
                            <Box
                              key={addon.id}
                              className="flexItems justifySpaceBetween"
                            >
                              <Box className="flexItems gapNone">
                                <Typography variant="body1">{addon.name}</Typography>
                              </Box>
                              <Typography variant="body1">${addon.price}</Typography>
                            </Box>
                          ))
                        ) : (
                          <Typography variant="body1" color="textSecondary">
                            No Add-ons
                          </Typography>
                        )
                      }
                    </Box>
                  </Grid>
                  <Grid item lg={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="h6">
                        {configJSON.rulesAndInstructionText}
                      </Typography>
                    </Box>
                    <Box gridGap={24} display="flex" justifyContent="space-between">
                      <List>
                        {rules.map((rule, index) => (
                          <ListItem key={index}>
                            <ListItemIcon>{rule.icon}</ListItemIcon>
                            <ListItemText primary={rule.text} />
                          </ListItem>
                        ))}
                      </List>
                      <List>
                        {instructions.map((instruction, index) => (
                            <ListItem key={index}>
                              <ListItemIcon>{instruction.icon}</ListItemIcon>
                              <ListItemText primary={instruction.text} />
                            </ListItem>
                          )
                        )}
                      </List>
                    </Box>
                  </Grid>
                </Grid>
                <Box my={6}>
                  <Typography variant="h6" gutterBottom>
                    {configJSON.cancellationPolicyText}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {`Free cancellation before ${this.state.listedDockDetailsData.cancellation_policy?.replace('_', ' ')}.`}
                  </Typography>
                </Box>
              </Box>
            </MainWrapper>
          </Container>
          <Footer navigation={this.props.navigation}/>
        </ThemeProvider>  
        </> 
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const MainWrapper = styled('div')(({ theme }) => ({
  "& .backBtn": {
    fontSize: "18px",
    fontFamily: "Outfit",
    fontWeight: 700,
    color: "#64748B",
    textTransform: "none",
  },
  "& .inboxHeader": {
    display: "flex",
    alignItems: "center",
  },
  "& .imgListGrid": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  "& .bannerImg": {
    height: "340px",
    maxWidth: "400px",
    borderRadius: "32px",
    backgroundSize: "cover",
    margin: "10px 0px",
  },
  "& .imgList": {
    maxWidth: "400px",
    justifyContent: "space-between",
  },
  "& .imgListItem": {
    width: "32% !important",
    maxWidth: "123px !important",
  },
  "& .dockImage": {
    borderRadius: "10px",
  },
  "& .flexCol": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .fontWeight500": {
    fontWeight: 500,
  },
  "& .marginTop10": {
    marginTop: "10px",
  },
  "& .gridCol2": {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "8px",
  },
  "& .gridColGap": {
    gridColumnGap: 70,
  },
  "& .flexItems": {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  "& .gapNone": {
    gap: 0,
  },
  "& .justifySpaceBetween": {
    justifyContent: "space-between",
  },
  [theme.breakpoints.down(600)]: {
    "& .inboxHeader": {
      paddingTop: "10px",
    },
  },
}));

// Customizable Area End