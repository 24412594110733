import React from "react";
// Customizable Area Start
import {
  Button,
  Container,
  Grid,
  Typography,
  AppBar,
  ThemeProvider,
  Box,
  createTheme,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputBase,
  FormHelperText,
  Popover
} from '@material-ui/core';
import { styled } from '@mui/system';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { imgHeaderLogo, FooterImg, helpImg } from "./assets";
import { getLastPartOfURL } from "../../../components/src/utils";

const BpIcon1 = styled('span')(({ theme }) => ({
  width: 20,
  borderRadius: "6px",
  height: 20,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  marginLeft:"-9px",
  'input:disabled ~ &': {
    boxShadow: 'none',
  },
  'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
  }, 
}));

const BpCheckedIcon1 = styled(BpIcon1)({
  backgroundColor: '#4F9FF8',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    width: 20,
    display: 'block',
    height: 20,
      backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
  },
  'input:hover ~ &': {
      backgroundColor: '#106ba3',
  },
});
const BpCheckbox1 = (props: CheckboxProps & { type: 'property' | 'feature' }) => {
  const { type, ...checkboxProps } = props;

  const inputProps = type === 'property' 
    ? { 'aria-label': 'property-checkbox' }
    : { 'aria-label': 'feature-checkbox' };
  return (
      <Checkbox
      checkedIcon={<BpCheckedIcon1 />}
      color="default"
      icon={<BpIcon1 />}
      disableRipple
          inputProps={inputProps}
          {...checkboxProps}
      />
  );
}

const theme = createTheme({
  palette: {
      primary: {
          main: "#4F9FF8",
      },
  },
  overrides: {
      MuiTypography: {
          h5: {
              fontFamily: "Outfit",
              fontSize: "16px",
              fontWeight: 700,
              color: "#0F172A",
          },
          body1: {
              fontFamily: "Outfit",
              fontSize: "1rem",
              fontWeight: 400,
              color: "#64748B",
              textTransform: "none",
          },
          subtitle2: {
            fontFamily: "Outfit",
            fontSize: "12px",
            fontWeight: 400,
            color: "#64748B",
            marginTop:"-12px",
        },
      },     
      MuiFormControlLabel: {
          label: {
            fontFamily: "Outfit",
            fontSize: "16px",
            color: "#0F172A",
              '&$disabled': {
                  color: '#64748B',
              },
          },
      },
      MuiList: {        
          root: {
            padding:0
          },
          padding:{
            paddingBottom:'0px',
            paddingTop:'0px',
          }
      },
      MuiDivider: {
          root: { margin: "15px 0" },
      },
      MuiButton: {
          root: {
            margin: "10px 0 30px 0",
            padding: "16px 32px",
            borderRadius: "8px",
          },
          containedPrimary: {
            color: "white",
            fontWeight: 700,
            fontSize: "16px",
            textTransform: "capitalize",
            fontFamily: "Outfit",
          },
      },
      MuiCheckbox: {
          root: {
              '& .MuiSvgIcon-root': {
                color: '#CBD5E1',
                strokeWidth: 1,
                borderRadius: '6px',
              },              
              '&.Mui-checked': {
                  color: '#4F9FF8',
              },'&:hover': {
                backgroundColor: 'transparent',
            },
          },
      }
  },
});
// Customizable Area End

import DockListingRulesInstructionsController, {
  Props,
  configJSON,
} from "./DockListingRulesInstructionsController.web";

export default class DockListingRulesInstructions extends DockListingRulesInstructionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {description, featureData, propertyWithArea, anchorEl, featureIds, propertyIds} = this.state;
    const open = Boolean(anchorEl);
    return (
      <ThemeProvider theme={theme}>
        <Wrapper >
          <Container maxWidth="lg">
            <AppBar position="relative" elevation={0} className="headerContent">
              <img src={imgHeaderLogo} className="logoContent" />
            </AppBar>
            <Button startIcon={<ArrowBackIosRoundedIcon />} style={{ textTransform: "none" }} className="backButton" 
              onClick={()=>this.handleNavigation("DockListingFeatures", getLastPartOfURL().url)}
            >Back</Button>

            <Box className="bodyContent">
              <Typography className="featureText">
              {configJSON.rulesAndInstructionText}
              </Typography>
              <div className="inputBox">
                <div style={{...webStyle.displayBox, flexDirection:"column",}}> 
              <Typography className="inputTitle">
                {configJSON.maxBoat}
              </Typography>
              <InputBase value={this.state.noOfBoats | 0} className="chargeOf"  onChange={this.handleNoOfBoats}
                          placeholder='Enter no of boats'
                          inputProps={{'area-label': 'boats',                          
                            style:{display: 'flex',alignItems: "center", justifyContent: "center"}
                          }}
                        /> 
                  {this.state.boatErrorMsg && (
                    <div style={webStyle.errMsg}>
                      {this.state.boatErrorMsg}
                    </div>
                  )}
                </div>
             
                <div style={{...webStyle.displayBox, flexDirection:"column",}}> 
              <Typography className="inputTitle">
                {configJSON.maxGuests}
              </Typography>
              <InputBase value={this.state.noOfGuests | 0} className="chargeOf" onChange={this.handleGuestsCount}
                          placeholder='Enter no of guest'
                          inputProps={{'data-testId': 'guest',
                            style:{display: 'flex',alignItems: "center", justifyContent: "center"}
                          }}
                        /> 
                  {this.state.guestErrorMsg && (
                    <div style={webStyle.errMsg}>
                      {this.state.guestErrorMsg}
                    </div>
                  )}
                </div>
              </div>
              <Typography variant="h5" style={{marginBottom:"10px"}}>
              {configJSON.allowText}
              </Typography>
              <Grid container className="addressBox" >
                <Grid item xs={12} sm={6} md={4}>
                {featureData.map((feature) => (
                    <List>
                        <ListItem key={feature.id} style={{ padding: "0 40px 0 0" }} >
                          <ListItemIcon style={{ minWidth: 30 }}>
                            <BpCheckbox1 onChange={()=>this.handleChecked(feature)}
                              type="feature"
                              checked={featureIds.includes(feature.name)}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              style: {...webStyle.propertyText },
                            }}
                            primary={feature.name}
                          />
                        </ListItem>
                    </List>
                ))}
                </Grid>
              </Grid>
             
              <Typography variant="h5" style={{marginTop:"15px"}}>
              {configJSON.propertyRules}
              </Typography>
              <Typography className="inputTitle" style={webStyle.protocol}>
              {configJSON.protocols}
              </Typography>
              <form >
                <textarea
                  name="description"
                  placeholder="State rules and cautions here (ex. Please beware of shallow area left of dock; or if using BBQ bring your own gas tank; Do play music by speaker in respect for close neighbours)"
                  maxLength={1000}
                  style={{...webStyle.textArea, resize: 'none'}}
                  value={description}
                  ref={this.adjustTextareaHeight}
                  data-test-id='Description'
                  onChange={(event) => this.handleDescription(event)}
                /></form>
                <FormHelperText style={{ textAlign: "right" }}>
                        <Typography variant="subtitle2">
                          {description.length}/1000
                        </Typography>
                      </FormHelperText>
              <Divider className="marginBottom" />
              <Typography className="featureText" style={{marginBottom:'10px'}}>
                {configJSON.listingAreaHave}
              </Typography>
              <Grid container className="addressBox" >
                <Grid item xs={12} sm={6} md={4}>
                {propertyWithArea.map((property) => (
                    <List>
                        <ListItem key={property.id} style={{ padding: "0 40px 0 0" }} >
                          <ListItemIcon style={{ minWidth: 30 }}>
                            <BpCheckbox1 onChange={()=>this.handlePropertyChecked(property)}
                              type="property"
                              checked={propertyIds.includes(property.name)}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              style: {...webStyle.propertyText },
                            }}
                            primary={
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ ...webStyle.propertyText }}>{property.name}</span>
                                {property.id === 2 && <img src={helpImg} alt="help" style={{ marginLeft: '8px', cursor:"pointer" }}
                                  onClick={this.handlePopoverOpen} 
                                  onMouseLeave={this.handlePopoverClose}/>}
                              </div>
                            }
                          />
                        </ListItem>
                    </List>
                ))}
                </Grid>
              </Grid>
              <Popover
                id="mouse-over-popover"
                style={{ pointerEvents: 'none' }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={this.handlePopoverClose}
                disableRestoreFocus
              >
                <div style={{
                  width: '375px',
                  borderRadius: '8px',
                }}>
                  <Typography style={webStyle.popoverTitle} >
                    {configJSON.popoverTitle}
                  </Typography>
                  <Divider />
                  <Typography style={webStyle.popoverDetail}>
                    {configJSON.popoverDetails}
                  </Typography>
                </div>
              </Popover>            
              <Button variant="contained" color="primary" data-test-id="nextBtn" style={{ marginTop: "40px"}}
                onClick={this.handleUpdateDock}
              >
                {configJSON.nextBottonText}
              </Button>
            </Box>
          </Container>
          <Box className="progressBar">
            <img alt="progress-bar" src={FooterImg} />
          </Box>
        </Wrapper>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  errMsg:{ color: 'red', fontSize:'12px', marginTop:'-30px', marginBottom:'20px' },
  popoverDetail:{
    fontFamily: 'Outfit',
    fontSize: '14px',
    fontWeight: 400,
    color: "#0F172A",
    padding: '0 24px 24px 24px', 
  },
  protocol:{color:"#64748B", marginTop:"15px",marginBottom: "10px",},
  displayBox:{
    display:"flex",gap:"16px"
  },
  textArea: {
    width: '100%',  minHeight: '98px', border: '1px solid #CBD5E1', color: "#64748B", fontWeight: 400,
    borderRadius: '8px',
    padding: '12px 8px',fontSize:"16px",fontFamily: "Outfit",overflow: 'hidden', 
  },
  propertyText: {
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 400,
    color: "#0F172A",
  }, 
  popoverTitle: {
    fontFamily: "Outfit",
    fontSize: "14px",
    fontWeight: 700,
    color: "#0F172A",
    display:"flex",
    alignItems:"center",
    padding: '24px 24px 10px 24px',
  }, 
}
const Wrapper = styled("div")(({ theme }) => ({
  "& .backButton": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Outfit",
    color: "#64748B",
    marginTop:"30px"
  },
  "& .featureText": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Outfit", color: "#0F172A"
  },
  "& .inputTitle": {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Outfit", color: "#0F172A"
  }, 
  "& .headerContent": {
    width: "100%", height: "auto", backgroundColor: "#FFFFFF", padding: "10px 0"
  },
  "& .logoContent": {
    width: "20%",
    height: "auto",
    minWidth: "175px",
  },
  "& .bodyContent": {
    margin: "-65px 240px",
    '@media (max-width: 900px)': {
      margin: "0 10px",
    },
  },
  "& .inputBox":{
    marginTop:"16px",
    display:"flex",
    flexDirection:"row",
    gap:"16px",
    '@media (max-width: 799px)': {
      flexDirection:"column",gap:"0px",
    },
  },
  "& .chargeOf": {
    width: "361px",
    height: "44px",
    padding: "10px 8px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    marginBottom: "24px",
    marginTop: "-10px",
    '@media (max-width: 409px)': {
      width: "100%",
    }, 
  },
  "& .addressBox": {
    margin: 0,
  },
  "& .marginBottom": {
    marginBottom: "32px",marginTop:"32px"
  },
  "& .progressBar": {
    width: "100%",
  },
  "& .progressBar img": {
    width: "100%",
  }
}));

// Customizable Area End
