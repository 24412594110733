// Customizable Area Start
Object.defineProperty(exports, '__esModule', {
  value: true
});


exports.compnaypagetitle = 'COMPANY PAGE';

exports.getcompanyApiContentType = 'application/json';
exports.getCompanyEndPoint = 'bx_block_joblisting/company_pages';
exports.getcompanyApiMethod = 'GET';
exports.ApiContentType = "application/json";
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchJobApiEndPoint = "bx_block_search/search_jobs";
exports.searchPeopleApiEndPoint = "bx_block_search/search_people";
exports.searchCompanyApiEndPoint = "bx_block_search/search_companies";
exports.createDockListingApiEndPoint = "bx_block_content_management/dock_listings";
exports.getDraftDockApiEndPoint = "bx_block_content_management/dock_listings/draft_dock_list";
exports.getLakeListApiEndPoint = "bx_block_location/lakes";

exports.validationApiContentType = 'application/json';
exports.validationApiMethodType = 'GET';
exports.exampleAPiEndPoint = 'EXAMPLE_URL';
exports.exampleAPiMethod = 'POST';
exports.exampleApiContentType = 'application/json';
exports.textInputPlaceHolder = 'Enter Text';
exports.labelTitleText = 'JobListing';
exports.labelBodyText = 'JobListing Body';
exports.emptyList = 'No Data Found';
exports.btnExampleTitle = 'CLICK ME';

// Manage people
exports.pageMemberLabel = 'Company page admins';
exports.pageMemberNote = 'View, Edit or Add member to you company page';

// add people
exports.addPeople = 'ADD PEOPLE';

// buttons
exports.continue_btn = 'Continue';
exports.continuetopage = 'Go to company page';
exports.addMember = 'Add New Admin';

//landing page
exports.headerText = "Does your dock have lonely days?";
exports.bodyText = "Share your dock with others for the day, week or month! It’s easy, free and brings in cash!";

exports.hostingOnDayDockerText = "Hosting on Daydocker is easy";
exports.listYourDockTitle = "List your dock for free";
exports.acceptReservationsTitle = "Accept reservations ";
exports.getPaidTitle = "Get paid";
exports.listYourDockDescription = "Upload pictures, set a rate, and describe your dock and any dock rules.";
exports.acceptReservationsDescription = "Allow instant reservations or review and approve reservation requests from boaters. ";
exports.getPaidDescription = "Secure payments are delivered directly to your bank account pomptly after a completed reservation.";
exports.btnListYourDockText = "List Your Dock";

exports.protectionGuaranteeTitle = "Upto $2,000,000 protection guarantee provided";
exports.protectionGuaranteeDescription = "Our Daydocker Protection Guarantee protects all bookings done via Daydocker for up to $2million for general liability claims and $10,000 of property protection per occurrence.";

exports.learneMoreTitle = "Learn more about Hosting";
exports.learnMoreDescription = "Visit FAQ to learn more.If you have any questions along the way, our amazing customer support team is here to help you.";
exports.btnFAQsText = "FAQs";
exports.titleFAQs = 'Frequently Asked Questions';
exports.titleTermsService = 'Terms of Service';
exports.titlePrivacyPolicy = 'Privacy Policy';
exports.btnContactUsText = "Contact Us";
exports.faqPath = '/bx_block_content_management/faqs';
exports.TermsServicePath = '/bx_block_terms_and_conditions/terms_and_conditions';
exports.updateTermsPath = '/bx_block_terms_and_conditions/terms_and_conditions/update_user_terms';
exports.privacyPolicyPath = '/bx_block_dashboard/privacy_policies';

//listing preview page
exports.titleTextListingDetails = "Look how great your listing looks:";
exports.greetingTextListingDetails = "Welcome to daydocker community. Your dock is officially listed.";
exports.perDayText = "/day";
exports.featureText = "Features";
exports.addOnsText = "Add-ons";
exports.rulesAndInstructionText = "Rules and Instructions";
exports.checkInText = "Check-in:";
exports.checkOutText = "Checkout:";
exports.editTextBtn = "Edit";
exports.cancellationPolicyText = "Cancellation Policy";
exports.maxGuestsText = "Guest(max)";
exports.guestText = "guests";
exports.dockListingDetailsBottomText = "Go to your dashboard anytime to manage bookings and listing.";

//dock listing form
exports.backBtnText = "Back";
exports.mapTitle = "Where is your dock located?";
exports.typeOfDockText = "Type of dock";
exports.areYouListingText = "Are you listing";
exports.dockingLengthLabel = "Docking length";
exports.dockingWidthLabel = "Docking width";
exports.dockingDepthLabel = "Water depth";
exports.lengthWidthUnit = "ft";
exports.tellUsAboutYourDock = "Tell us about your dock";
exports.listingTitleFieldLabel = "Give your listing a title";
exports.listingTitleFieldNote = "(Short, sweet and descriptive is best)";
exports.descriptionFieldLabel = "Tell guests more about your listing";
exports.whyShouldBoater = 'Why should boater rent your dock?';
exports.noteForDescription = "Give a general description of the deck or slip; whatever makes it special - back area , slide, BBQ, information about you as a host, if applicable.";
exports.nextBottonText = "Next";
exports.listingFeatures = "Listing Features";
exports.noteForAddOns = "Want to add items to your dock for more earning potential and pump the party for your dockers?"
exports.includeListing ="Include free or paid add-ons to your listing."
exports.dockAccess = "Dock Access";
exports.numParking = "Number of parking spaces";
exports.getFeatures = "/bx_block_content_management/features";
exports.freeOrPaid = "Is this add-on free or paid?";
exports.addOnsCharges = "How much would you like to charge for this add-on?";
exports.getAddOns = "/bx_block_content_management/add_ons";
exports.updateAddOnsPath = "/bx_block_content_management/dock_add_ons";
exports.removeAddOnsPath = "/bx_block_content_management/dock_add_ons/remove_add_on";
exports.draftDockPath = "/bx_block_content_management/dock_listings/draft_dock_list";
exports.deleteMethod ="DELETE";
exports.allowText = "Will you allow:";
exports.maxBoat = "Maximum number of boats";
exports.maxGuests = "Maximum number of guests";
exports.propertyRules = "Property Rules and Cautions";
exports.protocols = "Daydocker guests are instructed to stay on designated listing area to respect property and leave area clean. Check here for more Daydocker guest protocols.";
exports.listingAreaHave = "Does your listing area have";
exports.popoverDetails = "To protect animals and guests, hosts (are advised) shall not leave unattended animals at the listed property without informing guests.";
exports.popoverTitle = "About Animals";
exports.basePrice = "Choose your base price";
exports.basePriceDesc = "You can modify specific day pricing.";
exports.basePayPrice = 'Base pay price';
exports.serviceFee = "Daydocker service fee";
exports.guestTotal = "Daydocker guest total";
exports.beforeTaxes = '(before taxes)';
exports.youEarn = 'You earn';
exports.showOffText = "Show off your dock";
exports.bookingRateText = "Multiple photos increases booking rates";
exports.addPhotos = "Add at least 1 photo";
exports.reservationsOptions = "Decide how you will accept reservations";
exports.reservationsOption1 = "Use book now";
exports.reservationsSubOption1 = "(Guests can reserve with one click)";
exports.reservationsOption2 = "Confirm each reservation request";
exports.reservationsSubOption2 = "(You must accept or decline)";
exports.cancellationPolicy = "Decide your cancellation policy";
exports.policiesDesc = "When setting up your listing, it's important to consider which cancellation policy is best for you. The cancellation policy you set determines how far in advance a boater can cancel a reservation without a penalty.";
exports.policies = "These are the different policies you can choose from for daily or weekly reservations:";
exports.policy1 = "12 hour cancellation: Boaters will only be entitled to a refund if the cancellation is at least 12 hours before the reservation start time. (excluding service fee)";
exports.policy2 = "24 hour cancellation: Boaters will only be entitled to a refund if the cancellation is at least 24 hours before the reservation start time. (excluding service fee)";
exports.policy3 = "72 hour cancellation: Boaters will only be entitled to a refund when canceling at least 72 hours (3 days) before the reservation start time. (excluding service fee)";
exports.policydesc1 = "We recommend choosing 12 hour cancellation as your cancellation policy. This policy gives boaters peace of mind in case of inclement weather or other unforeseeable circumstances.";
exports.policydesc2 = "In our experience, hosts with this policy are not likely to incur more cancellations as a result.";
exports.policydesc3 = "Sometimes the weather turns nasty and makes boating uncomfortable or even impossible.";
exports.policydesc4 = "If weather conditions create an unsafe or uncomfortable environment for your boaters, you should accommodate them with a rain check reservation. Communication is key in this situation and communicating with your boater either before the reservation if the forecast is bad) or during your reservation (if the bad weather snuck up on you will help to accommodate your boater and increase the likelihood of positive host reviews. You may choose to refund boaters who request cancellation within the 24 hour start time at your discretion.";
exports.policydesc5 = "Longerdocker: Reservations of one month or more";
exports.policydesc6 = "For reservations of a month or more, payment will be taken at the time of booking for the first month, and will be withdrawn 7 days prior to the beginning of each subsequent month. 30 days notice is required to cancel or terminate a booking early without penalty.";
exports.policydesc7 = 'Cancellations up to 7 days before the start of a reservation will be entitled to a refund of 50% of the monthly fee. Cancellations less than 7 days before the start of a reservation will incur a fee equal to the monthly fee, with any refund of such fee at your discretion.';
exports.policydesc8 = 'Refunds';
exports.policydesc9 = "If you've agreed to reschedule or refund your boater, Daydocker will honor your resolution.";
exports.policydesc10 = 'Please just send an email to support@daydocker.com to provide us with the details of your resolution.';
exports.urlGetDockListingDetails = "bx_block_content_management/dock_listings";
exports.hostedByText = "Hosted by";
exports.basePayPriceText = "Base pay price";
exports.daydockerServiceFeeText = "Daydocker service fee";
exports.daydockerGuestTotalText = "Daydocker guest total";
exports.beforeTaxesText = "(before taxes)";
exports.dockTypeLabel = "Dock type";
exports.boatDockingAreaLabel = "Boat docking area";
exports.waterDepthLabel = "Water depth";
exports.amenitiesLabel = "Amenities";
exports.addonsLabel = "Add-ons";
exports.thingsToKnowHeader = "Things to know";
exports.dockRulesLabel = "Dock rules";
exports.healthSafetyLabel = "Health & safety";
exports.showLessBtn = "Show less";
exports.showMoreBtn = "Show more";
exports.noFeaturesText = "No Listing Features";
exports.noRatingsText = "0 ratings";
exports.noReviewsText = "0 reviews";
exports.noAddonText = "No Add-ons";
// Customizable Area End
