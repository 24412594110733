// Customizable Area Start
export const imgPasswordVisible = require('../assets/ic_password_visible.png');
export const imgPasswordInVisible = require('../assets/ic_password_invisible.png');
export const imgHeaderBg = require('../assets/header_bg.png');
export const companyLogo = require('../assets/microsoft-logo.png');
export const profilepagebg = require('../assets/image_Header.jpg');
export const people1 = require('../assets/people1.png');
export const people2 = require('../assets/people2.png');
export const people3 = require('../assets/people3.png');
export const videobg = require('../assets/videobg.png');
export const profilebg = require('../assets/profile_bg.png');
export const profile = require('../assets/profile.png');
export const apple = require('../assets/apple.png');
export const loadingGif = require('../assets/loading.gif');
export const cardBackgroundImage = require('../assets/card_background.png');
export const right_arrow = require('../assets/right-arrow.png');
export const add_icon = require('../assets/add.png')
export const dots = require('../assets/Right_Button.png')
export const search = require('../assets/search.png')
export const imgHeaderLogo = require("../assets/Image_header.png");
export const imgListDock = require("../assets/Image_(1).png");
export const imgAcceptReservations = require("../assets/Image_(2).png");
export const imgGetPaid = require("../assets/Image_(3).png");
export const titleImg = require("../assets/Title_Img.jpeg");
export const sunImg = require("../assets/Sun_Image.png");
export const progressBar1 = require("../assets/ProgressBar.png");
export const wholeDock = require("../assets/WholeDock.png");
export const slipOnlyDock = require("../assets/SlipOnlyDock.png");
export const grillImg = require("../assets/grill.svg");
export const progressBar2 = require("../assets/progressBar2.svg");
export const FooterImg = require("../assets/footerImg.png");
export const helpImg = require("../assets/help.svg");
export const footerImg2 = require("../assets/footerImg2.png");
export const sunImage = require("../assets/sunImg.svg");
export const plusIcon = require("../assets/plusImg.png");
export const addImage = require("../assets/addImage.png");
export const cancelIcon = require("../assets/cancelIcon.png");
export const FooterImage = require("../assets/footerImg5.png");
export const ImgBackground = require("../assets/imgBackground.png");

// icons
export const icons = {
  back: 'chevron-left',
  business: 'business',
  photo_camera: 'photo-camera',
  edit: 'edit',
  check: 'check',
  share: 'share',
  more_vert: 'more-vert',
  location_on: 'location-on',
  close: 'close',
  search: 'search',
  date_range: 'date-range',
  navigate_next: 'navigate-next',
  videocam: 'videocam',
  add_circle_outline: 'add-circle-outline',
  play_arrow: 'play-arrow',
  plus: 'plus',
  cached: 'cached',
  loop: 'loop',
  flash_off: 'flash-off',
  flash_on: 'flash-on',
  add: 'add',
  expand_more: 'expand-more',
  calendar: 'calendar'
};
// Customizable Area End
