export const imgFooterLogo = require("../src/assets/Image Pasted at 2024-5-3 15-41.png");
export const imgHeaderLogo = require("../src/assets/Image Pasted at 2024-5-3 15-41(1).png");
export const imgFooterTop = require("../src/assets/Image Pasted at 2024-5-4 21-36.jpeg");
export const checked = require("./assets/checked.svg");
export const unchecked = require("./assets/unchecked.svg");
export const userProfile = require("./assets/user_profile.png");
export const mapPin = require("./assets/MapPin.png");
export const falseChecked = require("./assets/falseChecked.svg");
export const userLogo = require("./assets/userLogo.png");
export const facebookIcon = require("./assets/facebook.png");
export const instagramIcon = require("./assets/instagram.png");
export const whatsappIcon = require("./assets/whatsapp.png");