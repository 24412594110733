// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Typography,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { ArrowBackIosRounded } from "@material-ui/icons";

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Josefin Sans !important",
      },
      body1: {
        fontSize: "24px",
        fontWeight: 400,
      },
    },
  }
});

import JobListingController, {
  Props,
  configJSON,
} from "./JobListingController.web";

class PrivacyPolicy extends JobListingController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const privacyPolicy = this.state.privacyPolicyList;
    return (
      <>
        <ThemeProvider theme={theme}>
          <MainWrapper data-test-id="jobListingContainer">
            <Container maxWidth="lg">
              <Header navigation={this.props.navigation} role="host"/>
              <Box style={{ marginBottom: '53px' }}>
                <div className="iconPlace" style={{ flexDirection: 'row' }}>
                  <ArrowBackIosRounded data-test-id='backBtn' style={{ cursor: 'pointer' }} onClick={() => this.goBack()} />
                  <Typography className="titleTexts" data-test-id="Frequently" >
                    {configJSON.titlePrivacyPolicy}
                  </Typography>
                </div>
                <Typography className="allText">
                  {privacyPolicy.description ?
                    <Box dangerouslySetInnerHTML={{__html: privacyPolicy.description}} /> :
                    "No Privacy Policy found"}
                </Typography>
              </Box>
            </Container>
            <Footer navigation={this.props.navigation} />
          </MainWrapper>
        </ThemeProvider>
      </>
    );
  }
}

export default PrivacyPolicy;

const MainWrapper = styled('div')(({ theme }) => ({
  "& .iconPlace": {
    display: 'flex',
    margin: "50px 0 20px 0",
    gap: '14px',
    marginLeft: '-30px',
    alignItems: 'baseline',
    '@media (max-width: 1300px)': {
      marginLeft: '0px',
    },
  },
  "& .titleTexts": {
    fontWeight: "700",
    fontSize: "36px",
    letterSpacing: "-0.01em",
    fontStyle: 'Josefin Sans',
    lineHeight: 1.1,
  },
  "& .allText": {
    fontFamily: "Outfit",fontSize: "18px", fontWeight: 400, color: "#64748B", marginLeft: '10px',
  },
}));
// Customizable Area End