import React from "react";
// Customizable Area Start
import { 
    Grid,
    Divider,
    Box,
    Typography,
    createTheme,
    ThemeProvider,
    Button,
    Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import GenericModal from "../../../components/src/GenericModal";
import { Formik, Form, Field } from 'formik';
import CustomInput from "../../../components/src/CustomInput.web";

const theme = createTheme({
    palette: {
      primary: {
        main: "#4F9FF8",
      },
    },
    overrides: {
      MuiTypography: {
        h5: {
          fontFamily: "Josefin Sans",
          fontSize: "20px",
          fontWeight: 700,
          color: "#000000",
          marginBottom: 10,
        },
        h6: {
          fontFamily: "Outfit",
          fontSize: "14px",
          fontWeight: 400,
          color: "#0F172A",
        },
      },
      MuiDivider: {
        root: { margin: "8px 0" },
      },
      MuiButton: {
        root: {
          borderRadius: "8px",
          padding: "16px 32px",
          margin: "10px 0",
          height: "44px",
        },
        containedPrimary: {
          fontFamily: "Outfit",
          fontSize: "16px",
          fontWeight: 700,
          color: "white",
          textTransform: "capitalize",
          '&:hover': {
            backgroundColor: "#4F9FF8",
          }
        },
      },
      MuiInputBase: {
        root: {
          fontFamily: "Outfit",
        }
      }
    },
  });

  import ContactUsModalController, {
    Props,
    configJSON,
} from "./ContactUsModalController.web";
// Customizable Area End

export default class ContactUsModal extends ContactUsModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { contactDetails, successMsg, errorMsg, openSnackbar } = this.state;
    // Customizable Area End
    return (
        // Customizable Area Start
        <>
        <GenericModal dataTest="contact-modal" open={this.props.isOpen} onClose={this.props.onClose}>
            <ThemeProvider theme={theme}>
            <Box mb={2}>
                <Typography variant="h5">{configJSON.contactFormTitle}</Typography>
            </Box>
            <Formik
              initialValues={contactDetails}
              validationSchema={this.validationSchema}
              onSubmit={(values) => {
                this.handleContactForm(values)
              }}
              data-test-id="contactForm"
              enableReinitialize={true}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form>
                    <Grid container>
                    <Grid item xs={12}>
                        <label>
                            <Typography variant="h6">
                                {configJSON.emailFieldLabel}
                            </Typography>
                        </label>
                        <Field
                            name="email"
                            size="small"
                            as={CustomInput}
                            placeholder="emailaddress@gmail.com"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("email", event.target.value)
                            }
                            value={values.email}
                            error={touched.email && errors.email}
                            errorMsg={errors.email}
                            data-test-id="email"
                        />
                         <label>
                            <Typography variant="h6">
                                {configJSON.nameFieldLabel}
                            </Typography>
                        </label>
                        <Field
                            name="name"
                            size="small"
                            as={CustomInput}
                            placeholder="Jane Doe"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("name", event.target.value)
                            }
                            value={values.name}
                            error={touched.name && errors.name}
                            errorMsg={errors.name}
                            data-test-id="name"
                        />
                        <Divider />
                        <Field
                            name="feedback"
                            as={CustomInput}
                            placeholder="Write your feedback here"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                setFieldValue("feedback", event.target.value)
                            }
                            value={values.feedback}
                            error={touched.feedback && errors.feedback}
                            errorMsg={errors.feedback}
                            data-test-id="feedback"
                            multiline
                        />
                        <Button
                            data-test-id="sendButton"
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                        >
                            {configJSON.sendBtnText}
                        </Button>
                    </Grid>
                    </Grid>
                </Form>
              )}
           </Formik>
            </ThemeProvider>
        </GenericModal>
        {successMsg && (
          <Snackbar 
            open={openSnackbar} 
            autoHideDuration={4000} 
            onClose={this.handleCloseSnackbar}
            data-test-id="successSnackbar">
            <Alert onClose={this.handleCloseSnackbar} severity="success">
              {successMsg}
            </Alert>
          </Snackbar>
        )}
        {errorMsg && (
          <Snackbar 
            open={openSnackbar} 
            autoHideDuration={4000} 
            onClose={this.handleCloseSnackbar}
            data-test-id="errorSnackbar">
            <Alert onClose={this.handleCloseSnackbar} severity="error">
              {errorMsg}
            </Alert>
          </Snackbar>
        )}
        </>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
// Customizable Area End