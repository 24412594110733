import {
  Box,
  Checkbox,
  FormHelperText,
  Radio,
  Typography,
  styled,
} from "@material-ui/core";
import { SvgIconComponent } from "@material-ui/icons";
import React, { Component } from "react";
import Select, { ValueType } from "react-select";

interface OptionType {
  value: string;
  label: string;
}

interface Props {
  placeholder: string;
  onChange?: (value: ValueType<OptionType, true>) => void;
  options: OptionType[];
  startIcon?: SvgIconComponent | string;
  endIcon?: SvgIconComponent;
  error?: boolean;
  value?: OptionType[];
  errorMsg?: string;
  optionType?: "select" | "radio";
}

export default class CustomCheckboxSelect extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      placeholder,
      onChange,
      value,
      startIcon: StartIcon,
      error = false,
      errorMsg = "",
      options,
      optionType = "select",
    } = this.props;

    return (
      <Wrapper>
        <Select
          value={value}
          onChange={onChange}
          options={[{ label: placeholder, options }]}
          isMulti={optionType === "select" ? true : undefined}
          components={{ Option: Option(optionType) }}
          placeholder={
            <React.Fragment>
              {StartIcon && <StartIcon />}
              {placeholder}
            </React.Fragment>
          }
          styles={{
            control: (provided) => ({
              ...provided,
              minHeight: "42px !important",
              borderColor: error ? "red" : "#b7c0c9",
              borderRadius: "8px",
            }),
            dropdownIndicator: () => ({
              display: "none",
            }),
            placeholder: () => ({
              fontFamily: "Outfit",
              opacity: 1,
              color: "#94A3B8",
              fontWeight: 400,
              display: "flex",
              gap: 5,
              alignItems: "center",
            }),
            singleValue: () => ({
              fontFamily: "Outfit",
            }),
            indicatorSeparator: () => ({
              display: "none",
            }),
            menuList: (provided) => ({
              ...provided,
              padding: "5px 10px",
            }),
            multiValue: (provided) => ({
              ...provided,
              backgroundColor: "#C9E4FF",
              borderRadius: "5px",
            }),
            multiValueLabel: (provided) => ({
              ...provided,
              fontFamily: "Outfit",
            }),
          }}
          hideSelectedOptions={false}
          isClearable={true}
          closeMenuOnSelect={false}
          formatGroupLabel={() => (
            <Box className="gropedTitle">{placeholder}</Box>
          )}
        />
        <FormHelperText>{error ? errorMsg : ""}</FormHelperText>
      </Wrapper>
    );
  }
}

const Wrapper = styled("div")(({}) => ({
  "& .optionLabel": {
    fontFamily: "Outfit",
    fontSize: "1rem",
    color: "#0F172A",
    fontWeight: 400,
  },
  "& .optionItem": {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#C9E4FF",
    },
  },
  "& .gropedTitle": {
    fontFamily: "Outfit",
    fontSize: "0.8rem",
    fontWeight: 700,
  },
  "& .MuiFormHelperText-root": {
    color: "red",
  },
}));

const Option = (optionType: "select" | "radio") => (props: any) => {
  const handleChange = () => {
    const newValue = props.selectOption(props.data);
    if (newValue) props.setValue(newValue, "select-option");
  };

  return (
    <Box className="optionItem" onClick={handleChange}>
      {optionType === "select" ? (
        <Checkbox color="primary" checked={props.isSelected} />
      ) : (
        <Radio color="primary" checked={props.isSelected} />
      )}
      <Typography className="optionLabel">{props.label}</Typography>
    </Box>
  );
};
