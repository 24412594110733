// Customizable Area Start
import React from "react";
import { Container, Box, Typography } from "@material-ui/core";
import { styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";

import EarningsController, {
  Props,
  configJSON,
  assets,
} from "./EarningsController.web";
import ReservationTable from "./ReservationTable.web";
import NoDataFound from "../../../components/src/NoDataFound.web";
import { reservationTheme } from "./ReservationList.web";
import Toast from "../../../components/src/Toast";

export default class Earnings extends EarningsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { earningsHeader, earningsList } = this.state;

    return (
      <ThemeProvider theme={reservationTheme}>
        <Wrapper data-test-id="mainWrapper">
          <Container maxWidth="lg">
            <Header
              role="host"
              isLoggedIn={true}
              navigation={this.props.navigation}
            />
            <Typography
              variant="h4"
              className="headerTitle padding20"
              data-test-id="titleText"
            >
              {configJSON.earningsText}
            </Typography>
            <Box className="bodyContent">
              <Typography variant="subtitle1" className="coloredSubtitle">
                You’ve made ${this.state.monthlyEarning} this month
              </Typography>
              {this.state.errorMsg && <Toast message={this.state.errorMsg} />}
              <Box className="padding30">
                {earningsList.length ? (
                  <ReservationTable
                    testId="earningsTable"
                    headerList={earningsHeader}
                    listData={earningsList}
                    totalCount={this.state.paginate.total_count}
                    totalPages={this.state.paginate.total_pages}
                    pageChange={this.handleChangePage}
                  />
                ) : (
                  <NoDataFound
                    name="No earnings"
                    image={assets.noReservationIcon}
                    message={configJSON.noEarningsText}
                    navigation={this.props.navigation}
                  />
                )}
              </Box>
            </Box>
          </Container>
        </Wrapper>
      </ThemeProvider>
    );
  }
}

const Wrapper = styled("div")(({ theme }) => ({
  "& .coloredSubtitle": {
    color: "#0F172A",
    fontSize: "1.1rem",
  },
  "& .padding20": {
    padding: "20px 0 10px 0",
  },
  "& .padding30": {
    padding: "30px 0 20px 0",
  },
  "& .bodyContent": {
    padding: "10px 0",
  },
  "& .inboxHeader": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [theme.breakpoints.down(840)]: {
    "& .headerTitle": {
      fontSize: "1.3rem",
    },
  },
  [theme.breakpoints.down(600)]: {
    "& .inboxHeader": {
      paddingTop: "10px",
    },
    "& .headerTitle": {
      fontSize: "1.2rem",
    },
  },
}));

// Customizable Area End
