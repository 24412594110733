import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { setStorageData } from "../../../framework/src/Utilities";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { sendAPIRequest } from "../../../components/src/utils";

interface ILake {
  id: number,
  name: string,
  state: string,
}

interface ISearchDockApiResponse {
  id: number,
  type: string,
  attributes: DockListDataAttributes,
}

interface DockListDataAttributes {
  id: number,
  name: string,
  state: string,
  longitude: string,
  latitude: string,
  address: string,
  docking_width: number,
  docking_length: number,
  listing_title: string,
  about: string,
  access: string,
  parking_space: number,
  max_guests: number,
  max_boats: number,
  allow_smoking: boolean,
  allow_pets: boolean,
  allow_liveaboards: boolean,
  has_security_camera: boolean,
  has_animals: boolean,
  rules_and_cautions: string,
  reservation_type: string,
  base_price: string,
  locality: string,
  cancellation_policy: string,
  administrative_area_level_1: string,
  administrative_area_level_2: string,
  state_short_name: string,
  step: number,
  lake: ILake | null,
  dock_type: string,
  listing_type: string,
  image: string,
  features: [],
  dock_add_ons: [],
  service_fee: number,
  guest_total: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchTerm: string;
  dockListData: DockListDataAttributes[];
  errorMsg: string;
  propsData: {query:string; lake_id:number};
  current_page: number;
  total_count: number;
  total_pages: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BoaterSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  itemsPerPage: number = 20;
  searchDockCallId: string = "";
  timeoutId: ReturnType<typeof setTimeout> | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
        searchTerm: '',
        dockListData: [],
        errorMsg: '',
        propsData: {query:'', lake_id:0},
        current_page: 1,
        total_count: 0,
        total_pages: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.searchDock("");
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.searchDockCallId]: this.handleSearchDockAPIResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleSearchDockAPIResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    const response = responseJSON as {
      data?: ISearchDockApiResponse[];
      meta?: {
        pagination: {
          total_count: number
          total_pages: number,
        }
      };
    };
    if (response.data && response.meta) {
      const dockList = response.data.map((dock) => ({
        ...dock.attributes
      }));

      this.setState({
        dockListData: dockList as DockListDataAttributes[],
        total_count: response.meta.pagination.total_count,
        total_pages: response.meta.pagination.total_pages,
      });
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
     const { errors: possibleErrors } = responseJSON;
     if (possibleErrors) {
       const errors = possibleErrors as string;
       this.setState({ errorMsg: errors });
         return true; // Indicates that there was an error
     }
     return false; // Indicates that there was no error
 };

  searchDock = async (searchQuery: string) => {
    this.searchDockCallId = sendAPIRequest(
      `${configJSON.searchAPIPath}?location=${searchQuery}&page=${this.state.current_page}&per=${this.itemsPerPage}`,
      {
        method: configJSON.validationApiMethodType,
        headers: {
          "Content-Type": configJSON.validationApiContentType,
        },
      }
    );
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newValue = value.replace(/\bLake\b|\blake\b/g, '').trim();
    this.setState(prevState => ({
      searchTerm: value,
      propsData: {
        ...prevState.propsData, 
        query: newValue 
      }
    }));

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  
    this.timeoutId = setTimeout(() => {
      this.searchDock(newValue);
    }, 1500);

  };
  handleDockDetails = (lakeId: number, lakeName: string) => {
    if(this.state.propsData.query === ""){
      this.setState(prevState => ({
        propsData: {
          ...prevState.propsData,
          query: lakeName, 
          lake_id: lakeId 
        }
      }),()=> {
        this.handleNavigation()
      });
    } else {
      this.setState(prevState => ({
        propsData: {
          ...prevState.propsData, 
          lake_id: lakeId 
        }
      }),()=>{
        this.handleNavigation()
      });
    }
  }
  handleNavigation = async() => {
    await setStorageData('propsData', JSON.stringify(this.state.propsData));
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'AdvancedSearch');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  } 

  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({
      current_page: value,
    }, () => this.searchDock(""));
  };
  // Customizable Area End
}
