import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { sendAPIRequest } from "../../../components/src/utils";
export interface IContactDetails {
    email: string,
    name: string,
    feedback: string,
}

interface IApiResponse {
    id: string;
    type: string;
    attributes: {
        id: number,
        email: string,
        name: string,
        feedback: string,
        created_at: string,
    };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    id: string;
    // Customizable Area Start
    isOpen: boolean;
    onClose: () => void;
    // Customizable Area End
}

interface S {   
    // Customizable Area Start
    contactDetails: IContactDetails,
    openSnackbar: boolean,
    successMsg: string,
    errorMsg: string,
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ContactUsModal extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    contactUsAPICallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        contactDetails: {
            email: "",
            name: "",
            feedback: "",
        },
        openSnackbar: false,
        successMsg: "",
        errorMsg: "",
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      // Customizable Area Start
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
      // Customizable Area End
    }
    // Customizable Area Start
    validationSchema = Yup.object().shape({
        email: Yup.string().email().required("Email is required"),
        name: Yup.string().required("Name is required"),
        feedback: Yup.string().required("Feedback is required"),
      });


    handleContactForm = (values: IContactDetails) => {
        this.contactUsAPICallId = sendAPIRequest(`${configJSON.contactUsApiEndPoint}`, {
            method: configJSON.httpPostMethod,
            headers: {
              "Content-Type": configJSON.ApiContentType,
            },
            body: {
                "contact_us": {
                    ...values,
                }
              },
            },
        );
        this.props.onClose();
    }

    apiSuccessCallBackController = (
        apiRequestCallId: string,
        responseJSON: Record<string, unknown>
      ) => {
        const successCallbackMap = {
          [this.contactUsAPICallId]: this.handleAPIResponse,
        };
    
        if (apiRequestCallId) {
          const successCallback: (responseJSON: Record<string, unknown>) => void =
            successCallbackMap[apiRequestCallId];
          !!successCallback && successCallback(responseJSON);
        }
      };

      handleAPIResponse = (responseJSON: Record<string, unknown>) => {
        if (this.handleErrorResponse(responseJSON)) return;

        this.setState({ successMsg: "" });
        const response = responseJSON as {
          meta?: { message: string };
          data?: IApiResponse;
        };
        if (response.meta) {
          this.setState({
            successMsg: response.meta.message,
            openSnackbar: true,
          });
        }
        if (response.data) {
          // Bind data as per API response
          const attributes = response.data.attributes;
          const contactDetails = {
            email: attributes.email,
            name: attributes.name,
            feedback: attributes.feedback,
          } as IContactDetails;
          this.setState({ contactDetails });
        }
      };

      handleErrorResponse = (responseJSON: Record<string, unknown>) => {
         const { error: possibleErrors } = responseJSON;
         if (possibleErrors) {
             this.setState({ errorMsg: "somthing went wrong!!!", openSnackbar: true });
             return true; // Indicates that there was an error
         }
         return false; // Indicates that there was no error
     };

     handleCloseSnackbar = () => {
        this.setState({ openSnackbar: false });
     }
    // Customizable Area End
  }