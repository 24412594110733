import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

interface Listdata {
  id: string;
  type: string;
  attributes: {
    id: number;
    author_info: { id: number; name: string };
    check_in: string;
    check_out: string;
    dock_address: string;
    total_payment: string;
    booking_status: string;
  }
}

export interface ReservationItems {
  id: number;
  guest: string;
  check_in: string;
  check_out: string;
  listing: string;
  total: string;
  status: string;
  [key: string]: string | number;
}
export interface IHeader {
    id: string;
    label: string;
  }

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  propId: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeReservationListData: ReservationItems[];
    selectedTab: string;
    pastReservationList: ReservationItems[];
    reservationHeaderData: IHeader[];
    total_count:number;
    total_pages:number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  ssId: string;
  // Customizable Area End
}

export default class BoaterReservationListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getReservationListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.NavigationMessage),
        getName(MessageEnum.NavigationPropsMessage),
        getName(MessageEnum.NavigationTargetMessage),
        getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
        selectedTab: configJSON.boaterDockingText,
        total_count:0,
        total_pages:0,
        reservationHeaderData: [
          { id: "guest", label: "Host" },
          { id: "check_in", label: "Check-in" },
          { id: "check_out", label: "Check-out" },
          { id: "listing", label: "Dock Address" },
          { id: "total", label: "Total Payment" },
        ],       
        activeReservationListData:[],
        pastReservationList: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    let urlData = window.location.pathname;
    let parts = urlData.split("/");
    parts.includes("where-you-have-docked") ? this.setState({selectedTab: "Where you’ve docked"}): this.setState({selectedTab: "Where you’re docking"});
    this.fetchReservationsList(1);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.getReservationsApi(message)
    // Customizable Area End
  }

  // Customizable Area Start
  getReservationsApi = (message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const addJsonDatas = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if (apiRequCallId === this.getReservationListApiCallId) {
        const newPastResList = addJsonDatas.data.map((list: Listdata) => {
          const attribute = list.attributes;
          return {
            id: attribute.id,
            guest: attribute.author_info.name,
            check_in: attribute.check_in,
            check_out: attribute.check_out,
            listing: attribute.dock_address,
            total: attribute.total_payment,
            status: attribute.booking_status
          };
        })
        this.setState({
          activeReservationListData: newPastResList,
          pastReservationList: newPastResList,
          total_count: addJsonDatas.meta.pagination.total_count,
          total_pages:addJsonDatas.meta.pagination.total_pages,         
        });
      }
    }
    }
  fetchReservationsList = async(page:number) => {
    const token = await getStorageData("token");
    const lastPath = this.state.selectedTab === "Where you’re docking" ? 'active' : 'inactive';
    const itemHeaders = {
      "Content-Type": configJSON.itemListApiContentType,
      'token': token
    };

    const getReservationList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReservationListApiCallId = getReservationList.messageId;

    getReservationList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.urlGetItemList}?status=${lastPath}&page=${page}&per=6`
    );
    getReservationList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(itemHeaders)
    );
    getReservationList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.itemListApiMethodType
    );

    runEngine.sendMessage(getReservationList.id, getReservationList);
    return true;
  };

  handleTabChanges = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  handleListTabView = (chipLabelName: string) => {
    let reservationTypes = chipLabelName.includes('docked') ? "BoaterDockedReservationList" : "BoaterReservationList";
    this.handleTabChanges(reservationTypes)
    this.setState({ selectedTab: chipLabelName });
  };  
  // Customizable Area End
}
