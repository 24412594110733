// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { ILakeListItem } from "./CalendarController.web";
import { DockDetail } from "./DockBookingController.web";

export const configJSON = require("./config");
export const assets = require("./assets");

export interface Props {
  selectedDock: ILakeListItem,
  isOpen: boolean;
  onClose: (selectedDock?: DockDetail) => void;
}

export interface IEvent {
  title: string;
  start: Date;
  end: Date;
  id: string;
}

interface SProps {
  isLoading: boolean;
  days: number;
  newBasePrice: number;
  errorMsg: string;
}

interface SSProps {
  ssId: string;
}

export default class CalendarModalController extends BlockComponent<
  Props,
  SProps,
  SSProps
> {
  customPriceAPICallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isLoading: false,
      days: 1,
      newBasePrice: 0,
      errorMsg: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<SProps>) {
    if(this.props.selectedDock.base_price !== prevProps.selectedDock.base_price) {
      this.setState({
        newBasePrice: this.props.selectedDock.weekend_price
          ? this.props.selectedDock.weekend_price
          : Number(this.props.selectedDock.base_price),
      });
    }
  }

  handleBasePriceChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    this.setState({
      newBasePrice: Number(event.target.value),
    });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.customPriceAPICallId]: this.handleAPIResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  changeCustomPrice = async () => {
    const token = await getStorageData("token");
    this.setState({
      isLoading: true,
    });
    this.customPriceAPICallId = sendAPIRequest(
      `${configJSON.urlPostUpdateBasePrice}/${this.props.selectedDock.id}/custom_weekend_price`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          token,
        },
        body: {
          weekend_price: this.state.newBasePrice,
        },
      }
    );
  };

  handleAPIResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;
    this.setState({
      isLoading: false,
    });
    const response = responseJSON as {
      data?: { id: string; type: string; attributes: DockDetail };
    };
    if (response.data) {
      this.props.onClose(response.data?.attributes);
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    this.setState({ isLoading: true });
    const { errors } = responseJSON as { errors: string };
    if (errors) {
      // set error message when API is done
      this.setState({ errorMsg: errors });
      return true;
    }
    return false;
  };
}

// Customizable Area End
