import React, { Component } from "react";
import { Switch, styled } from "@material-ui/core";

interface Props {
  checked: boolean;
  onChange: () => void;
}

const GenericSwitchWrapper = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 26,
  padding: 0,
  overflow: "initial",
  margin: theme.spacing(1),
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(22px)",
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        backgroundColor: "#4f9ff8",
        opacity: 1,
        border: "none",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    width: 24,
    height: 24,
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    border: `1px solid #dedede`,
    backgroundColor: "white",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
}));

export default class GenericSwitch extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { checked, onChange } = this.props;
    return <GenericSwitchWrapper checked={checked} onChange={onChange} />;
  }
}
