import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from "react";
import * as Yup from "yup";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";
import { 
  Grid,
  Typography,
  Chip, 
  Box,
  TextField,
} from '@material-ui/core';
import { FormikErrors, FormikTouched } from 'formik';
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { slipOnlyDock, wholeDock } from "./assets";

interface IDockListingDetails {
  id: number;
  address: string,
  lake_id: number,
    lake: ILake,
    dock_type: string,
    listing_type: string,
    listing_title: string,
    about: string,
    rental_reason: string,
    latitude: string,
    longitude: string,
    docking_length: number,
    docking_width: number,
    water_depth: number,
    locality: string,
    administrative_area_level_2: string,
    administrative_area_level_1: string,
    state_short_name: string,
}

interface ILake {
  id: number,
  name: string,
  state: string,
}

interface IApiResponse {
  id: string;
  type: string;
  attributes: {
    id: number;
    latitude: string;
    longitude: string;
    address: string;
    lake: ILake;
    city: string;
    dock_type: string;
    listing_type: string;
    docking_length: number,
    docking_width: number,
    water_depth: number,
    listing_title: string,
    about: string,
    rental_reason: string,
    step: number,
    locality: string,
    administrative_area_level_2: string,
    administrative_area_level_1: string,
    state_short_name: string,
  };
}

interface IGetLakesAPIResponse {
  id: string,
  type: string,
  attributes: {
      id: number,
      name: string,
      state: string,
  }
}

export interface IGeocodeAddress {
  latitude: string,
  longitude: string,
  formatted_address: string,
  locality: string,
  administrative_area_level_2: string,
  administrative_area_level_1: string,
  state_short_name: string,
  postal_code: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    onClose: () => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean;
    errorMsg: string;
    listingDetails: IDockListingDetails;
    successMsg: string;
    isDraftDockListingAvailable: boolean;
    destinationAddress: string;
    isLakeFieldDisabled: boolean;
    lakesList: ILake[];
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class DockListingFormController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    getDraftDockListingAPICallId: string = "";
    createDockListingAPICallId: string = "";
    updateDockListingAPICallId: string = "";
    getLakesAPICallId: string = "";
    timeoutId: ReturnType<typeof setTimeout> | null = null;
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage)
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        isLoading: false,
        listingDetails: {
          address: "",
          lake_id: 0,
          dock_type: "",
          listing_type: "",
          listing_title: "",
          about: "",
          rental_reason: '',
          latitude : "40.712776",
          longitude: "-74.005974",
          docking_length: 25,
          docking_width: 15,
          water_depth: 6,
          locality: "",
          administrative_area_level_2: "",
          administrative_area_level_1: "",
          state_short_name: "",
        } as IDockListingDetails,
        isDraftDockListingAvailable: false,
        errorMsg: "",
        successMsg: "",
        destinationAddress: "",
        isLakeFieldDisabled: true,
        lakesList: [],
        // Customizable Area End
      };

      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      // Customizable Area Start
      // Customizable Area End
    }

    async componentDidMount() {
      super.componentDidMount();
      // Customizable Area Start
      this.getDraftDockListing();
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      // Customizable Area Start
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
      // Customizable Area End
    }

    // Customizable Area Start
    validationSchema = Yup.object().shape({
        address: Yup.string().required("Address is required"),
        lake: Yup.string().nullable().required("Lake selection is required"),
        dock_type: Yup.string().required("Select one dock type"),
        listing_type: Yup.string().required("Select one listing type"),
        listing_title: Yup.string().required("Title is required"),
        about: Yup.string().required("Description is required").max(
          100,
          "Description can't be longer than 100 characters"
        ),        
        rental_reason: Yup.string().required("Description is required").max(
          100,
          "Description can't be longer than 100 characters"
        ),
        docking_length: Yup.number().required("Dock length is required"),
        docking_width: Yup.number().required("Dock Width is required"),
        water_depth: Yup.number().required("Dock Depth is required"),
      });

      getDraftDockListing = async() => {
        const token = await getStorageData("token");
        this.getDraftDockListingAPICallId = sendAPIRequest(configJSON.getDraftDockApiEndPoint, {
          method: configJSON.httpGetMethod,
          headers: {
            "Content-Type": configJSON.ApiContentType,
            "token": token,
          },
          },
        );
      }

      handleDockListing = async(values: IDockListingDetails) => {
        const token = await getStorageData("token");
        if(this.state.isDraftDockListingAvailable){
          this.updateDockListingAPICallId = sendAPIRequest(`${configJSON.createDockListingApiEndPoint}/${this.state.listingDetails.id}`, {
            method: configJSON.httpPutMethod,
            headers: {
              "Content-Type": configJSON.ApiContentType,
              "token": token,
            },
            body: {
              ...values,
              },
            },
          );
        }
        else{
          this.createDockListingAPICallId = sendAPIRequest(configJSON.createDockListingApiEndPoint, {
            method: configJSON.httpPostMethod,
            headers: {
              "Content-Type": configJSON.ApiContentType,
              "token": token,
            },
            body: {
              ...values,
              step: 1,
              },
            },
          );
        }
      }

      apiSuccessCallBackController = (
        apiRequestCallId: string,
        responseJSON: Record<string, unknown>
      ) => {
        const successCallbackMap = {
          [this.getDraftDockListingAPICallId]: this.handleAPIResponse,
          [this.createDockListingAPICallId]: this.handleCreateUpdateResponse,
          [this.updateDockListingAPICallId]: this.handleCreateUpdateResponse,
          [this.getLakesAPICallId]: this.handlegetLakesAPIResponse,
        };
    
        if (apiRequestCallId) {
          const successCallback: (responseJSON: Record<string, unknown>) => void =
            successCallbackMap[apiRequestCallId];
          !!successCallback && successCallback(responseJSON);
        }
      };

      handleAPIResponse = (responseJSON: Record<string, unknown>) => {
        if (this.handleErrorResponse(responseJSON)) return;

        this.setState({ successMsg: "" });
        const response = responseJSON as {
          meta?: { message: string };
          data?: IApiResponse;
        };
        if (response.meta) {
          this.setState({
            successMsg: response.meta.message,
          });
        }
        if (response.data) {
          this.setState({ isDraftDockListingAvailable: true, isLakeFieldDisabled: false });
          // Bind data as per API response
          const attributes = response.data.attributes;
          const listingDetails = {
            id: attributes.id,
            latitude: attributes.latitude,
            longitude: attributes.longitude,
            address: attributes.address,
            lake_id: attributes.lake.id,
            lake: attributes.lake,
            city: attributes.city,
            dock_type: attributes.dock_type,
            listing_type: attributes.listing_type,
            docking_length: attributes.docking_length,
            docking_width: attributes.docking_width,
            water_depth: attributes.water_depth,
            listing_title: attributes.listing_title,
            about: attributes.about,
            rental_reason: attributes.rental_reason ? attributes.rental_reason : "",
            step: attributes.step,
            locality: attributes.locality,
            administrative_area_level_2: attributes.administrative_area_level_2,
            administrative_area_level_1: attributes.administrative_area_level_1,
            state_short_name: attributes.state_short_name,
          } as IDockListingDetails;
          listingDetails.lake.state = attributes.state_short_name;
          this.setState({ listingDetails });
        }
      };

      handleCreateUpdateResponse = (responseJSON: Record<string, unknown>) => {
        if (this.handleErrorResponse(responseJSON)) return;
        this.setState({ successMsg: "", errorMsg: "" });
        const response = responseJSON as {
          data?: IApiResponse;
        };
        if (response.data) {
          this.handleNavigation("DockListingFeatures", response.data.attributes.id);
        }
      };

      handlegetLakesAPIResponse = (responseJSON: Record<string, unknown>) => {
        if (this.handleErrorResponse(responseJSON)) return;

        this.setState({ successMsg: "" });
        const response = responseJSON as {
          meta?: { message: string };
          data?: IGetLakesAPIResponse[];
        };
        if (response.meta) {
          this.setState({
            successMsg: response.meta.message,
          });
        }
        if (response.data) {
          let lakeList: ILake[] = [];
          response.data.forEach(element => {
            lakeList.push(element.attributes);
          });
          this.setState({ lakesList: [ ...lakeList] })
        }
      }

      handleErrorResponse = (responseJSON: Record<string, unknown>) => {
         this.setState({ isLoading: false });
  
          const { errors: possibleErrors } = responseJSON;
          if (possibleErrors) {
            const errors = possibleErrors as string;
              this.setState({ errorMsg: errors });
              return true; // Indicates that there was an error
          }
          return false; // Indicates that there was no error
      };


      handleGeoCodeAddress = (geoCodeAddress: IGeocodeAddress) => {
            const listingDetails = {
              ...this.state.listingDetails,
              latitude: geoCodeAddress.latitude,
              longitude: geoCodeAddress.longitude,
              address: geoCodeAddress.formatted_address,
              locality: geoCodeAddress.locality,
              administrative_area_level_2: geoCodeAddress.administrative_area_level_2,
              administrative_area_level_1: geoCodeAddress.administrative_area_level_1,
              state_short_name: geoCodeAddress.state_short_name,
              postal_code: geoCodeAddress.postal_code,
            } as IDockListingDetails;
            this.setState({ listingDetails, isLakeFieldDisabled: false });
            this.getLakeList("");
      };

      handleNavigation = (route: string, dockId?: number) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        if (dockId) {
          message.addData(getName(MessageEnum.NavigationScreenNameMessage), dockId);
        }
        this.send(message);
      };
      srcPath = (dockType:string)=>{
        return dockType === 'whole_dock' ? wholeDock : slipOnlyDock;
      }
      handleAddressChange = (
        address: string,
        setFieldValue: { 
          (
            field: string, 
            value: IDockListingDetails, 
            shouldValidate?: boolean | undefined
          ): Promise<void | FormikErrors<IDockListingDetails>>; 
          (arg0: string, arg1: string): void;}
        ) => {
          setFieldValue('address', address);
          if(address === ""){
            this.setState({ isLakeFieldDisabled: true });
          }
          else{
            this.setState({ isLakeFieldDisabled: false });
          }

          if (this.timeoutId) {
            clearTimeout(this.timeoutId);
          }

          this.timeoutId = setTimeout(() => {
            this.setState({destinationAddress: address})
          }, 3000);
      }

      handleLakeFieldChange = (
        option: ILake | null,
        setFieldValue: { 
          (
            field: string, 
            value: IDockListingDetails, 
            shouldValidate?: boolean | undefined
          ): Promise<void | FormikErrors<IDockListingDetails>>; 
          (arg0: string, arg1: string): void;}
      ) => {
        if(option){
          setFieldValue("lake_id", option.id.toString());
          setFieldValue("lake.id", option.id.toString());
          setFieldValue("lake.name", option.name);
          setFieldValue("lake.state", option.state);
        }
      }

      getLakeList = async (inputValue: string) => {
        const token = await getStorageData("token");

        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }

        this.timeoutId = setTimeout(() => {
        this.getLakesAPICallId = sendAPIRequest(
          `${configJSON.getLakeListApiEndPoint}?state=${this.state.listingDetails.state_short_name}&query=${inputValue}`, {
          method: configJSON.httpGetMethod,
          headers: {
            "Content-Type": configJSON.ApiContentType,
            "token": token,
          },
          },
        )}, 1000);
      }

      renderDockTypes = (
        values: IDockListingDetails,
        setFieldValue: { 
          (
            field: string, 
            value: IDockListingDetails, 
            shouldValidate?: boolean | undefined
          ): Promise<void | FormikErrors<IDockListingDetails>>; 
          (arg0: string, arg1: string): void; }) => {
            return(
              ["floating", "permanent", "bulkhead"].map((chipItem) => (
              <Grid item md={6} key={chipItem}>
                <Chip
                    key={chipItem}
                    data-test-id={chipItem}
                    label={
                        <Typography
                            variant="subtitle2"
                            className={`chipAlign ${chipItem === values.dock_type
                                    ? "chipTextSelected"
                                    : ""
                                }`}
                                style={{textTransform: "capitalize"}}
                        >
                            <CloseRoundedIcon />
                            {chipItem}
                        </Typography>
                    }
                    variant="outlined"
                    className={`chipContent ${chipItem === values.dock_type
                            ? "selectedChip"
                            : ""
                        }`}
                    onClick={() =>
                        setFieldValue("dock_type", chipItem)
                    }
                />
              </Grid>
              ))
            );
      }
    
      renderWholeOrSlipDock = (
        values: IDockListingDetails,
        setFieldValue: { 
          (
            field: string, 
            value: IDockListingDetails, 
            shouldValidate?: boolean | undefined
          ): Promise<void | FormikErrors<IDockListingDetails>>; 
          (arg0: string, arg1: string): void; }) => {
            return(
              <>
              {
              [{label: "Whole dock", value: "whole_dock"}, {label: "Slip only", value: "slip_only"}].map((chipItem, index) => (
                <React.Fragment key={chipItem.value}>
                <Chip
                  key={chipItem.value}
                  data-test-id={chipItem.value}
                  label={
                    <Typography
                      variant="subtitle2"
                      className={
                        chipItem.value === values.listing_type
                          ? "chipTextSelected"
                          : ""
                      }
                    >
                      {chipItem.label}
                    </Typography>
                  }
                  variant="outlined"
                  className={`chipContent ${chipItem.value === values.listing_type
                    ? "selectedChip"
                    : ""
                    }`}
                  onClick={() =>{
                    setFieldValue("listing_type", chipItem.value)
                  this.srcPath('')
                  }}
                />
                {index === 0 ? (
                  <Typography variant="subtitle1">or</Typography>
                ) : null}
                </React.Fragment>
              ))}
              </>
            );
      }
      handleLengthInputChange = (event: React.ChangeEvent<HTMLInputElement>,
        fieldName: string,
        setFieldValue: (field: string, value: any) => void
      ) => {
        const sanitizedValue = event.target.value.replace(/\D/g, '');
        setFieldValue(fieldName, sanitizedValue);
      };
      renderDockLengthWidthFields = (
        values: IDockListingDetails,
        touched: FormikTouched<IDockListingDetails>,
        errors: FormikErrors<IDockListingDetails>,
        setFieldValue: {
          (
            field: string, 
            value: IDockListingDetails, 
            shouldValidate?: boolean | undefined
          ): Promise<void | FormikErrors<IDockListingDetails>>; 
          (arg0: string, arg1: string): void; }) => {
            return(
              <Box className="dimentionOfDock">
                <Box maxWidth={93}>
                  <Typography className="dockingDimentionInputLabel">{configJSON.dockingLengthLabel}</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    defaultValue="25"
                    className="dockingDimentionInput"
                    InputProps={{
                      endAdornment: <Typography variant="body2" color="textSecondary">{configJSON.lengthWidthUnit}</Typography>,
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      this.handleLengthInputChange(event, "docking_length", setFieldValue)
                    }
                    value={values.docking_length}
                    data-test-id="docking_length"
                  />
                </Box>
                <Box maxWidth={93}>
                  <Typography className="dockingDimentionInputLabel">{configJSON.dockingWidthLabel}</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    defaultValue="15"
                    className="dockingDimentionInput"
                    InputProps={{
                      endAdornment: <Typography variant="body2" color="textSecondary">{configJSON.lengthWidthUnit}</Typography>,
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      this.handleLengthInputChange(event, "docking_width", setFieldValue)
                    }
                    value={values.docking_width}
                    data-test-id="docking_width"
                  />
                </Box>
              </Box>
            );
      }
    // Customizable Area End
  }
