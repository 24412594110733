// Customizable Area Start
import React from "react";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import GenericModal from "../../../components/src/GenericModal";
import {
  Box,
  Divider,
  Input,
  InputAdornment,
  Typography,
  Button,
} from "@material-ui/core";
import CalendarModalController, { Props } from "./CalendarModalController.web";
import Toast from "../../../components/src/Toast";
import { configJSON } from "./CalendarController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4F9FF8",
    },
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontFamily: "Josefin Sans",
        fontSize: "1.2rem",
        fontWeight: 700,
      },
      body2: {
        fontFamily: "Josefin Sans",
        fontSize: "1.3rem",
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: "0.9rem",
        fontFamily: "Outfit",
        fontWeight: 400,
        color: "#0F172A",
      },
      colorTextSecondary: {
        fontFamily: "Josefin Sans",
        fontSize: "48px",
        fontWeight: 700,
        color: "#0F172A",
      },
    },
    MuiInput: {
      root: {
        "&::before": {
          border: "none !important",
        },
        "&::after": {
          border: "none !important",
        },
      },
    },
    MuiInputBase: {
      input: {
        fontFamily: "Josefin Sans",
        fontSize: "48px",
        fontWeight: 700,
        color: "#0F172A",
        padding: 0,
      },
    },
    MuiDivider: {
      root: { margin: "8px 0" },
    },
    MuiButton: {
      root: {
        borderRadius: "8px",
        padding: "16px",
      },
      containedPrimary: {
        fontFamily: "Outfit",
        fontSize: "16px",
        fontWeight: 700,
        color: "white",
        textTransform: "capitalize",
      },
    },
  },
});

export default class CalendarModal extends CalendarModalController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { isLoading, newBasePrice, errorMsg } = this.state;
    return (
      <GenericModal
        open={this.props.isOpen}
        onClose={() => this.props.onClose()}
        data-test-id="toggleModal"
        customStyles={{
          modal: {
            marginTop: "-20px",
          },
        }}
      >
        <ThemeProvider theme={theme}>
          <Wrapper data-test-id="calendarModalWrapper">
            <Typography variant="body1" data-test-id="modalTitle">
              {configJSON.customWeekendPriceText}
            </Typography>
            <Input
              data-test-id="customPriceTag"
              className="paddingTop15"
              type="number"
              name="basePrice"
              defaultValue={newBasePrice}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              inputProps={{ style: { appearance: "textfield" } }}
              onChange={(event) => this.handleBasePriceChange(event)}
            />
            <Box className="borderedBox">
              <Box className="flexItems">
                <Typography variant="subtitle1">{configJSON.baseDayPriceText}</Typography>
                <Typography variant="subtitle1">${newBasePrice || 0}</Typography>
              </Box>
              <Box className="flexItems">
                <Typography variant="subtitle1">
                  {configJSON.dayDockerServiceFee}
                </Typography>
                <Typography variant="subtitle1">${this.props.selectedDock.service_fee}</Typography>
              </Box>
              <Divider />
              <Box className="flexItems">
                <Box>
                  <Typography variant="subtitle1" className="bigFontSize">
                    {configJSON.daydockerGuestTotalLabel}
                  </Typography>
                  <Typography variant="subtitle1" className="fontColor">
                    ({configJSON.beforeTaxes})
                  </Typography>
                </Box>
                <Typography variant="body2">
                  ${newBasePrice || 0 + this.props.selectedDock.service_fee}
                </Typography>
              </Box>
            </Box>
            <Box className="borderedBox">
              <Box className="flexItems">
                <Typography variant="subtitle1" className="bigFontSize">
                  {configJSON.yourEarnText}
                </Typography>
                <Typography variant="body2">
                  ${(newBasePrice || 0 - this.props.selectedDock.service_fee).toFixed(2)}
                </Typography>
              </Box>
            </Box>
            <Button
              data-test-id="addEventButton"
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              onClick={this.changeCustomPrice}
            >
              {isLoading ? "Adding..." : "Add"}
            </Button>
            {errorMsg && <Toast message={errorMsg} />}
          </Wrapper>
        </ThemeProvider>
      </GenericModal>
    );
  }
}

const Wrapper = styled("div")(({ theme }) => ({
  "& .paddingTop15": {
    paddingTop: "15px",
  },
  "& .flexItems": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .borderedBox": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    margin: "20px 0",
    padding: "15px 10px",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
  },
  "& .bigFontSize": {
    fontSize: "1rem",
  },
  "& .fontColor": {
    color: "#94A3B8",
  },
}));

// Customizable Area End
