import React from "react";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import {
  TextareaAutosize
} from "@material-ui/core";

interface ComponentProps {
  name?: string;
  minRows?: number;
  placeholder?: string;
  value: string;
  onChange: (event: any) => void;
}

const theme = createTheme({
  overrides: {},
});

const Wrapper = styled('div')({
  '& .custom-textarea': {
    color: '#0F172A',
    fontFamily: '"Outfit", sans-serif',
    fontSize: 16,
    border: '1px solid #CBD5E1',
    borderRadius: 8,
    width: '100%',
    padding: '10px 8px',
    '&::placeholder': {
      color: '#94A3B8',
    },
  }
});

const DayDockerTextarea: React.FC<ComponentProps> = ({ name = '', value, onChange, minRows = 2, placeholder = '' }) => {
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <TextareaAutosize
          name={name}
          value={value}
          onChange={onChange}
          className="custom-textarea"
          minRows={minRows}
          placeholder={placeholder}
          maxRows={5}
        />
      </Wrapper>
    </ThemeProvider>
  );
};

export default DayDockerTextarea;