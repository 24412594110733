// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import {
  setStorageData,
} from "../../../framework/src/Utilities";
import { MustCompleteProfile, MustVerifyEmail, sendAPIRequest, checkHasNumber, checkHasSpecialChar, checkMinLength, validateEmailFormat } from "../../../components/src/utils";

export const configJSON = require("./config");

type ErrorType = string | { failed_login: string }[];

export interface Props {
  navigation: any;
  id: string;
  isOpen: boolean;
  onClose: () => void;
  goToSignup?: () => void;
  goToForgot?: () => void;
  mustVerifyEmail?: (param: MustVerifyEmail) => void;
  mustCompleteProfile?: (param: MustCompleteProfile) => void;
}

interface S {
  isLoading: boolean;
  token: string;
  errorMessage: string;
  passwordError: string;
  emailError: string;
  successMessage: string;
  enteredEmail: string;
  enteredPassword: string;
  isPasswordObscured: boolean;
  formSubmitted: boolean;
}

interface SS {
  id: any;
}

export default class LoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  callLoginApiId = '';
  fetchOrderDetailsId = '';
  reorderSingleProductId = '';

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.receive = this.receive.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.allInputsOK = this.allInputsOK.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isLoading: false,
      token: '',
      errorMessage: '',
      passwordError: '',
      emailError: '',
      successMessage: '',
      enteredEmail: '',
      enteredPassword: '',
      isPasswordObscured: true,
      formSubmitted: false,
    };
  }

  get isDisabled() {
    return this.state.enteredEmail.trim() === '' || this.state.enteredPassword.trim() === ''
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  emailOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => this.setState({ enteredEmail: event.target.value });

  passwordOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ enteredPassword: event.target.value });
  }

  togglePasswordVisibility = () => {
    this.setState(prevState => ({
      isPasswordObscured: !prevState.isPasswordObscured
    }));
  }

  goToForgotPassword = () => {
    this.props.goToForgot && this.props.goToForgot();
  }

  goToSignup = () => {
    this.props.goToSignup && this.props.goToSignup();
  }

  handleLoginSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (this.state.isLoading === false) {
      if (!this.allInputsOK()) return;

      this.doLogin();
    }
  }

  allInputsOK = (): boolean => {
    const { enteredPassword, enteredEmail } = this.state;
    
    let emailError = '';
    let passwordError = '';
    
    if (!validateEmailFormat(enteredEmail)) {
      emailError = configJSON.errorEmail1;
    }

    if (!checkMinLength(enteredPassword)) {
      passwordError = configJSON.errorPassword1;
    } else if (!checkHasNumber(enteredPassword)) {
      passwordError = configJSON.errorPassword2;
    } else if (!checkHasSpecialChar(enteredPassword)) {
      passwordError = configJSON.errorPassword3;
    }

    this.setState({ emailError, passwordError, formSubmitted: true });

    if (!emailError && !passwordError) {
      this.setState({ formSubmitted: false, emailError: '', passwordError: '' });
      return true;
    }
    return false;
  }

  doLogin = () => {
    this.setState({ isLoading: true, errorMessage: '' });

    this.callLoginApiId = sendAPIRequest(
      `/login/login`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          data: {
            type: "email_account",
            attributes: {
              email: this.state.enteredEmail,
              password: this.state.enteredPassword,
            },
          },
        }
      }
    )
  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON)
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callLoginApiId]: this.handleLoginResponse,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    this.setState({ isLoading: false });
  
    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      const errors = possibleErrors as ErrorType;
      if (Array.isArray(errors) && errors[0].failed_login) {
        this.setState({ errorMessage: errors[0].failed_login });
      } else {
        this.setState({ errorMessage: typeof errors === 'string' ? errors : 'Something went wrong' });
      }
      return true; // Indicates that there was an error
    }
  
    return false; // Indicates that there was no error
  }

  handleLoginResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    this.setState({ errorMessage: '' });

    const response = responseJSON as { meta: { token: string; id: number } } | { meta: { temp_token: string; message: string; } };

    // Check if this account was half way through the sign-up flow
    if ('temp_token' in response.meta) {
      if (response.meta.message.includes('verified')) {
        this.props.mustVerifyEmail && this.props.mustVerifyEmail({
          email: this.state.enteredEmail,
          password: this.state.enteredPassword,
          tempToken: response.meta.temp_token,
        });
      } else if (response.meta.message.includes('complete')) {
        this.props.mustCompleteProfile && this.props.mustCompleteProfile({
          email: this.state.enteredEmail,
          password: this.state.enteredPassword,
          tempToken: response.meta.temp_token,
        });
      }
      return;
    }

    setStorageData('token', response.meta.token);
    setStorageData('loginId', response.meta.id);
    this.props.onClose();
  }

}

// Customizable Area End