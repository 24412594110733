import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { DockDetail } from "./DockBookingController.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";
import copy from "copy-to-clipboard";

export interface ExtendedDockDetail extends DockDetail {
  available: boolean;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    anchorElMenu: null | HTMLElement;
    currentItem: null | ExtendedDockDetail,
    manageListingItems: ExtendedDockDetail[];
    shareFlag: boolean;
    shareUrl: string;
    errorMsg: string;
    isDeleteDialogOpen: boolean;
    current_page: number;
    total_count: number;
    total_pages: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ManageListingController extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
    itemsPerPage: number = 10;
    getListingsDataApiCallId: string = "";
    toggleListingItemApiCallId: string = "";
    deleteListingItemApiCallId: string = "";
    generateShareUrlApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionResponseMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        anchorElMenu: null,
        currentItem: null,
        isDeleteDialogOpen: false,
        manageListingItems: [],
        shareFlag: false,
        shareUrl: "",
        errorMsg: "",
        current_page: 1,
        total_count: 0,
        total_pages: 0,
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      // Customizable Area Start
        const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        this.apiSuccessCallBackController(apiRequestCallId, responseJson);
      // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
      super.componentDidMount();
      this.getListingsData();
    };

    apiSuccessCallBackController = (
      apiRequestCallId: string,
      responseJSON: Record<string, unknown>
    ) => {
      const successCallbackMap = {
        [this.getListingsDataApiCallId]: this.handleListingDataAPIResponse,
        [this.toggleListingItemApiCallId]: this.handleToggleListingItemAPIResponse,
        [this.deleteListingItemApiCallId]: this.handleDeleteListingItemAPIResponse,
        [this.generateShareUrlApiCallId]: this.handleShareUrlAPIResponse,
      };
  
      if (apiRequestCallId) {
        const successCallback: (responseJSON: Record<string, unknown>) => void =
          successCallbackMap[apiRequestCallId];
        !!successCallback && successCallback(responseJSON);
      }
    };

    getListingsData = async () => {
      this.setState({ errorMsg: "" });
      const token = await getStorageData("token");
      this.getListingsDataApiCallId = sendAPIRequest(
        `bx_block_content_management/dock_listings?page=${this.state.current_page}&per=${this.itemsPerPage}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
    };

    handleListingDataAPIResponse = (
      responseJSON: Record<string, unknown>
    ) => {
      const response = responseJSON as {
        data?: { id: string; type: string; attributes: ExtendedDockDetail }[];
        meta?: { pagination: { total_count: number; total_pages: number } };
        errors?: string[];
      };
      if (response.data && response.meta) {
        this.setState({
          manageListingItems: response.data?.map(listItem => listItem.attributes),
          total_count: response.meta.pagination.total_count,
          total_pages: response.meta.pagination.total_pages,
        });
      } else if(response.errors) {
        this.setState({
          errorMsg: response.errors[0]
        })
      }
    };

    toggleListingItem = async () => {
      this.setState({ errorMsg: "" });
      const token = await getStorageData("token");
      this.toggleListingItemApiCallId = sendAPIRequest(
        `bx_block_content_management/dock_listings/${this.state.currentItem?.id}/toggle_availability`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
    };

    handleToggleListingItemAPIResponse = (
      responseJSON: Record<string, unknown>
    ) => {
      const response = responseJSON as {
        available?: boolean;
        errors?: string[];
      };
      if (response.available === true || response.available === false) {
        this.setState({ anchorElMenu: null, currentItem: null });
        this.getListingsData();
      } else if(response.errors) {
        this.setState({
          errorMsg: response.errors[0]
        })
      }
    };

    deleteListingItem = async () => {
      this.setState({ errorMsg: "" });
      const token = await getStorageData("token");
      this.deleteListingItemApiCallId = sendAPIRequest(
        `bx_block_content_management/dock_listings/${this.state.currentItem?.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            token,
          },
        }
      );
    };

    handleDeleteListingItemAPIResponse = (
      responseJSON: Record<string, unknown>
    ) => {
      const response = responseJSON as {
        message?: string;
        errors?: string[];
      };
      if (response.message) {
        this.setState({ anchorElMenu: null, currentItem: null, isDeleteDialogOpen: false, current_page: 1 });
        this.getListingsData();
      } else if(response.errors) {
        this.setState({
          errorMsg: response.errors[0]
        })
      }
    };

    shareListingItem = async () => {
      this.setState({ anchorElMenu: null });
      if(!this.state.shareFlag) {
        const token = await getStorageData("token");
        this.generateShareUrlApiCallId = sendAPIRequest(
          `bx_block_content_management/dock_listings/${this.state.currentItem?.id}/share`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token,
            },
          }
        );
      } else {
        this.setState({
          shareFlag: false,
        });
      }
    };

    handleShareUrlAPIResponse = (responseJSON: Record<string, unknown>) => {
      const response = responseJSON as {
        url?: string;
        message?: string;
        errors?: string[];
      };
      if (response.url && response.message) {
        this.setState({
          shareFlag: true,
          shareUrl: response.url,
        });
      } else if(response.errors) {
        this.setState({
          errorMsg: response.errors[0]
        })
      }
    };

    handleCopyText = (value: string) => {
      copy(value);
    };

    handleMenuClick = (event: React.MouseEvent<HTMLElement>, item: ExtendedDockDetail) => {
        this.setState({ anchorElMenu: event.currentTarget, currentItem: item });
    };

    handleMenuClose = () => {
        this.setState({ anchorElMenu: null });
    };
    handleDeleteClick = () => {
      this.setState({
        errorMsg: "",
        isDeleteDialogOpen: true,
      });
    }
    onCloseDialog = () => {
      this.setState({ anchorElMenu: null, isDeleteDialogOpen: false });
    }
    handleNavigation = (route: string, dockId?: number) => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), route);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      if (dockId) {
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), dockId);
      }
      this.send(message);
    };

    handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
      this.setState({
        current_page: value,
      }, () => this.getListingsData());
    };
    // Customizable Area End
  }
