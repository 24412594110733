import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";

interface ComponentProps {
  value: string | number;
  name?: string;
  onChange: (event: any) => void;
  itemList: { value: string | number; label: string; }[];
  placeholder: string;
  customStyles?: { formControl: {} };
}

const DayDockerSelect: React.FC<ComponentProps> = ({ value, name = '', onChange, itemList, placeholder, customStyles = { formControl: {} } }) => {
  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        root: {
          borderRadius: 8,
        },
        input: {
          // placeholder for the select: Month / Day / Year
          // color: '#94A3B8',
          color: value === '' ? '#94A3B8' : '#0F172A',
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 8,
        },
        notchedOutline: {
          borderColor: '#CBD5E1',
        }
      },
      MuiMenuItem: {
        root: {
          fontFamily: '"Outfit", sans-serif',
          color: '#0F172A', // for the list of items
        },
        // selected: {
        //   backgroundColor: 'yellow',
        // },
      },
      MuiPaper: {
        root: {
          borderRadius: '8px !important',
        },
      },
      MuiSelect: {
        root: {
          fontFamily: '"Outfit", sans-serif',
          borderRadius: 8,
        },
        select: {
          // backgroundColor: 'yellow',
        },
      },
      MuiListItem : {
        root: {
          // backgroundColor: 'red',
        },
        button: {
          '&:hover': {
            // backgroundColor: 'cyan',
          },
        },
        // selected: {
        //   color: "purple"
        // },
      },
    }
  });
  
  return (
    <ThemeProvider theme={theme}>
      <FormControl 
        variant="outlined" 
        style={{ 
          fontFamily: '"Outfit", sans-serif', 
          borderRadius: 20,
          ...customStyles.formControl 
        }}
      >
        <Select
          value={value}
          onChange={onChange}
          name={name}
          displayEmpty
          renderValue={(value) => (value !== '' ? value : placeholder) as React.ReactNode}
        >
          {itemList.map((item) => (
            <MenuItem 
              key={item.value} 
              value={item.value}
            >
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

export default DayDockerSelect;