import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  styled,
  Typography,
} from "@material-ui/core";
import { imgFooterLogo, imgFooterTop } from "./assets";
import ContactUsModal from "../../blocks/emailnotifications2/src/ContactUsModal.web";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";
import { v4 as uuidv4 } from "uuid";

interface State {
  openModal: boolean;
  faqsOpen: boolean;
}

interface Props {
  // Add other props if needed
  navigation?: any;
}

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Josefin Sans",
      }
    },
  }
});

const Wrapper = styled('div')(({theme}) => ({
  '& .footerWrapper': {
    position: 'inherit',
    bottom: 0,
    left: 0,
    right: 0,
  },
  '& .footer': {
    backgroundColor: '#1275e4',
    padding: '20px 80px',
    margin: 0,
    width: "auto",
  },
  '& .footerLogoWrapper': {
    display: 'flex',
    justifyContent: 'center',
    gap: 3,
  },
  '& .footerLogoImg': {
    width: '58px',
    height: 'auto',
  },
  '& .copyRightTextWrapper': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .copyRightText': {
    color: '#FFFFFF',
    fontSize: '14px',
  },
  '& .footerNavWrapper': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '16px 0px',
  },
  '& .footerNavInnerWrapper': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .footerNavText': {
    fontFamily: 'Outfit, sans-serif',
    fontSize: '1rem',
    justifyContent: 'center',
    color: '#FFFFFF',
    fontWeight: 600,
    cursor: "pointer",
  },

  [theme.breakpoints.down(920)]: {
    '& .footer': {
      padding: '30px 60px',
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'center',
    },
  }
}));

export default class Footer extends React.Component<Props, State>{
  subScribedMessages: string[];
  send: (message: Message) => void;
  constructor(props: Props) {
    super(props);
    this.state = {
      openModal: false,
      faqsOpen: false,
    }
    this.subScribedMessages = [
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
    ];
    const blockId = uuidv4();
    this.send = (message) => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
  }

  handleNavigation = async(route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  goBack = ()=>{
    this.props.navigation.goBack()
  }
  
    render(){
    return (
      <>
        <ThemeProvider theme={theme}>
          <Wrapper>
            <Box className="footerWrapper">
              <Box>
                <img src={imgFooterTop} style={{ width: '100%' }} />
              </Box>
              <Grid container className="footer">
                <Grid item md={4} className="footerLogoWrapper">
                  <img src={imgFooterLogo} className="footerLogoImg" />
                  <Box className="copyRightTextWrapper">
                    <Typography align="center" className="copyRightText footerNavText">©2024 Daydocker Inc. All rights reserved</Typography>
                  </Box>
                </Grid>
                <Grid item md={8} className="footerNavWrapper">
                  <Typography className="footerNavText" onClick={() => this.handleNavigation('TermsAndConditions')}>
                    Terms of Service
                  </Typography>
                  <Typography className="footerNavText" onClick={() => this.handleNavigation('PrivacyPolicy')}>
                    Privacy Policy
                  </Typography>
                  <Typography className="footerNavText" onClick={() => this.setState({ openModal: true })}>
                    Contact Us
                  </Typography>
                  <Typography className="footerNavText" onClick={() => this.handleNavigation('FAQs')}>
                    FAQs
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Wrapper>
        </ThemeProvider>
        <ContactUsModal
          isOpen={this.state.openModal}
          onClose={() => this.setState({ openModal: false })}
          id="contact modal"
        />
      </>
    );
  }
};
