import React, { Component } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardExpiryElement,
  CardNumberElement,
  CardCvcElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { Field, Form, Formik } from "formik";
import { Box, Button, Grid, Typography, styled } from "@material-ui/core";
import CustomInput from "./CustomInput.web";
import * as Yup from "yup";

const configJSON = require("../../blocks/reservations/src/config");

interface Props {
  actionBtnRequired: boolean;
  saveBtnText?: string;
  cancelBtnText?: string;
  handleResponse?: (token: { id: string }, card_holder: string) => void;
  onReset?: () => void;
}

interface S {
  cardError: string;
  expiryDateError: string;
  cvcError: string;
  cardNumberValid: boolean;
  expiryDateValid: boolean;
  cvcValid: boolean;
  nameValid: boolean;
}

// Update when actual key is available
const stripePromise = loadStripe(
  "pk_test_51P0rok00CFg1jN7Fv3Bb2l3WrocMskxA3O6HwCekTMdPiIOfccJ6YUwzBIOwiWXItEd98Irto30MKAOXzrOooo7Z00CVk6kBzv"
);

class StripeCardForm extends Component<Props, S> {
  constructor(props: any) {
    super(props);
    this.state = {
      cardError: "",
      expiryDateError: "",
      cvcError: "",
      cardNumberValid: false,
      expiryDateValid: false,
      cvcValid: false,
      nameValid: false,
    };
  }

  validationSchema = Yup.object().shape({
    name: Yup.string().required("Name on card is required"),
  });

  handleSubmit = async (
    values: { name: string },
    stripe: any,
    elements: any
  ) => {
    if (!stripe || !elements) {
      return;
    }
    const cardNumberElement = elements.getElement(CardNumberElement);
    const { error, token } = await stripe.createToken(cardNumberElement, {
      name: values.name,
    });
    if (error) {
      this.setState({
        cardError: error.message,
      });
    } else {
      this.setState({
        cardError: "",
        expiryDateError: "",
      });
      this.handleCardDetails(token, values.name);
    }
  };

  handleCardDetails = (token: { id: string }, card_holder: string) => {
    this.props.handleResponse && this.props.handleResponse(token, card_holder);
  };

  checkAllFieldsValidAndSubmit = (submitForm: () => void) => {
    const { cardNumberValid, expiryDateValid, cvcValid, nameValid } = this.state;
    if (cardNumberValid && expiryDateValid && cvcValid && nameValid) {
      submitForm();
    }
  };

  render() {
    return (
      <Wrapper>
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <Formik
                initialValues={{ name: "" }}
                validationSchema={this.validationSchema}
                onSubmit={(values) => {
                  this.handleSubmit(values, stripe, elements);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  isValid,
                  dirty,
                  setFieldValue,
                  setFieldTouched,
                  setFieldError,
                  submitForm,
                }) => (
                  <Form>
                    <Grid container>
                      <Grid item md={12} xs={12} sm={12}>
                        <Typography
                          variant="body1"
                          className="marginTop10 colorGrey"
                        >
                          {configJSON.nameofCardHolderText}
                        </Typography>
                        <Field
                          name="name"
                          as={CustomInput}
                          placeholder={configJSON.namePlaceholder}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue("name", event.target.value);
                            setFieldTouched("name", true, false);
                            if (event.target.value.length > 0) {
                              this.setState({ nameValid: true });
                              this.checkAllFieldsValidAndSubmit(submitForm);
                            } else {
                              setFieldError("name", "Name on card is required");
                              this.setState({ nameValid: false });
                            }
                          }}
                          value={values.name}
                          error={Boolean(touched.name && errors.name)}
                          errorMsg={errors.name}
                        />
                      </Grid>
                      <Grid item md={12} xs={12} sm={12}>
                        <Typography
                          variant="body1"
                          className="marginTop10 colorGrey"
                        >
                          {configJSON.cardNumberText}
                        </Typography>
                        <CardNumberElement
                          options={{
                            classes: {
                              base: "CustomStripeElement",
                              focus: "borderPrimary",
                              invalid: "borderRed",
                            },
                            placeholder: "XXXX XXXX XXXX XXXX",
                          }}
                          onChange={(event) => {
                            if (event.error) {
                              this.setState({
                                cardError: "Please check card number",
                                cardNumberValid: false,
                              });
                            } else {
                              this.setState({ cardError: "", cardNumberValid: true });
                              this.checkAllFieldsValidAndSubmit(submitForm);
                            }
                          }}
                        />
                        {this.state.cardError && (
                          <Typography className="helperErrorText">
                            {this.state.cardError}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={12} xs={12} sm={12}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            className="removePaddingBottom"
                          >
                            <Typography
                              variant="body1"
                              className="marginTop10 colorGrey"
                            >
                              {configJSON.expiryDateText}
                            </Typography>
                            <CardExpiryElement
                              options={{
                                classes: {
                                  base: "CustomStripeElement",
                                  focus: "borderPrimary",
                                  invalid: "borderRed",
                                },
                                placeholder: "MM/YY",
                              }}
                              onChange={(event) => {
                                if (event.error) {
                                  this.setState({
                                    expiryDateError: "Please check expiry date",
                                    expiryDateValid: false,
                                  });
                                } else {
                                  this.setState({ expiryDateError: "", expiryDateValid: true });
                                  this.checkAllFieldsValidAndSubmit(submitForm);
                                }
                              }}
                            />
                            {this.state.expiryDateError && (
                              <Typography className="helperErrorText">
                                {this.state.expiryDateError}
                              </Typography>
                            )}
                          </Grid>
                          <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            className="removePaddingTop"
                          >
                            <Typography
                              variant="body1"
                              className="marginTop10 colorGrey"
                            >
                              {configJSON.cvvText}
                            </Typography>
                            <CardCvcElement
                              options={{
                                classes: {
                                  base: "CustomStripeElement",
                                  focus: "borderPrimary",
                                  invalid: "borderRed",
                                },
                                placeholder: "***",
                              }}
                              onChange={(event) => {
                                if (event.error) {
                                  this.setState({
                                    cvcError: "Please check cvv",
                                    cvcValid: false,
                                  });
                                } else {
                                  this.setState({ cvcError: "", cvcValid: true });
                                  this.checkAllFieldsValidAndSubmit(submitForm);
                                }
                              }}
                            />
                            {this.state.cvcError && (
                              <Typography className="helperErrorText">
                                {this.state.cvcError}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      {this.props.actionBtnRequired && (
                        <Box className="actionBtnGroup">
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={`textTransormInitial ${
                              isValid &&
                              dirty &&
                              !this.state.cardError &&
                              !this.state.expiryDateError &&
                              !this.state.cvcError
                                ? ""
                                : "disabledBtn"
                            }`}
                            disabled={Boolean(
                              !isValid &&
                                this.state.cardError &&
                                this.state.expiryDateError &&
                                this.state.cvcError
                            )}
                          >
                            {this.props.saveBtnText}
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            type="reset"
                            className="textTransormInitial"
                            onClick={this.props.onReset}
                          >
                            {this.props.cancelBtnText}
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          </ElementsConsumer>
        </Elements>
      </Wrapper>
    );
  }
}

const Wrapper = styled("div")(({ theme }) => ({
  "& .marginTop10": {
    marginTop: 10,
  },
  "& .colorGrey": {
    fontWeight: 600,
    color: "#334155",
  },
  "& .CustomStripeElement": {
    border: "1px solid #C6C6C6",
    padding: "18.5px 14px",
    margin: "5px 0",
    borderRadius: "8px",
    fontFamily: "Outfit",
  },
  "& .borderPrimary": {
    border: "1px solid #4F9FF8",
  },
  "& .borderRed": {
    borderColor: "#f44336",
  },
  "& .textTransormInitial": {
    textTransform: "initial",
  },
  "& .actionBtnGroup": {
    display: "flex",
    gap: 20,
    flexWrap: "wrap",
  },
  "& .disabledBtn": {
    color: "#64748B",
    backgroundColor: "#E4EBF2",
    pointerEvents: "none",
  },
  "& .helperErrorText": {
    fontFamily: "Outfit",
    fontSize: "12px !important",
    fontWeight: 400,
    color: "red",
  },
  [theme.breakpoints.down(600)]: {
    "& .removePaddingBottom": {
      paddingBottom: 0,
    },
    "& .removePaddingTop": {
      paddingTop: 0,
    },
  },
  [theme.breakpoints.down(540)]: {
    "& .actionBtnGroup": {
      gap: 10,
    },
    "& .textTransormInitial": {
      margin: "20px 0 0 0",
    },
  },
}));

export default StripeCardForm;
