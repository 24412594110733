Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpDeleteMethod = "DELETE";
exports.httpPostMethod = "POST";

exports.favouritesApiApiContentType = "application/json";
exports.favouritesApiEndPoint = "favourites/favourites";
exports.textInputPlaceHolder = "Enter Text";
exports.contentPlaceHolder = "Enter Content";
exports.configError = "Error";
exports.configErrorType = "Please enter a type";
exports.configErrorId = "Please enter a valid id";

exports.titleText = "Favorites";
exports.hostedBy = "Hosted by";
exports.confirmDeletationTitle = "Delete Favorites?";
exports.cancelBtnText = "Cancel";
exports.deleteBtnText = "Delete";
// Customizable Area End