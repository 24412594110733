import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
  IconButton,
  Button,
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { notFoundIcon } from "../../../blocks/chat/src/assets";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { imgListing } from "../../../blocks/landingpage/src/assets";
import { DockDetail } from "./DockBookingController.web";
import Toast from "../../../components/src/Toast";
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import ShareModal from "../../../components/src/ShareModal.web";
import NoDataFound from "../../../components/src/NoDataFound.web";
import GenericModal from "../../../components/src/GenericModal";
import { Pagination } from "@material-ui/lab";

const theme = createTheme({
  overrides:{
    MuiTypography: {
      root: {
        fontFamily: "Outfit, sans-serif",
      },
      h3: {
        fontSize: "1.5rem",
        fontFamily: "Josefin Sans",
        fontWeight: 700,
      },
      h6:{
        fontFamily: "Outfit, sans-serif",
        fontSize: "1rem",
        fontWeight: 600,
      },
      subtitle1: {
        fontFamily: "Outfit, sans-serif",
        fontSize: "0.875rem",
        fontWeight: 400,
      },
      body1: {
        fontFamily: "Outfit",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#64748B",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "8px",
        padding: "16px",
      },
      containedPrimary: {
        fontFamily: "Outfit",
        fontSize: "16px",
        fontWeight: 700,
        color: "white",
        backgroundColor: "#4F9FF8",
        textTransform: "capitalize",
        borderRadius: "8px",
        '&:hover': {
          backgroundColor: "#4F9FF8",
        }
      },
      textPrimary: {
        fontFamily: "Outfit",
        fontSize: "16px",
        fontWeight: 700,
        color: "#4F9FF8",
        textTransform: "capitalize",
        margin: "5px 0px",
      }
    },
    MuiDivider: {
      root:{
        height: "2px",
        color: "#E2E8F0",
        margin: "10px 0px",
      }
    },
    MuiMenu: {
      paper: {
        borderRadius: '8px',
        boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.06)',
        '& .MuiList-root': {
          padding: '0px',
        }
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: '48px !important',
        '& .MuiListItem-root':{
          padding: '12px 16px',
        },
        '& .MuiListItemIcon-root':{
          minWidth: '0px',
          margin: '5px',
        },
        '& .MuiListItemIcon-root, & .MuiListItemText-primary':{
          fontFamily: 'Outfit, sans-serif',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '20px',
        },
        '&:hover': {
          backgroundColor: '#4F9FF8',
          '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: '#FFFFFF',
          },
        },
      },
    }
  }
});
// Customizable Area End

import ManageListingController, {
  Props,
  configJSON,
} from "./ManageListingController.web";

export default class ManageListing extends ManageListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderItem = (item: DockDetail & { available: boolean }) => {
    return (
      <Grid item lg={6} key={item.id} className="gridItem">
        <Card className="card-root">
          <div className="cardMediaWrapper">
            <CardMedia
              className="card-media"
              image={item.images?.[0] || imgListing}
              title="Live from space album cover"
              component="img"
            />
            {!item.available &&
              <Typography data-test-id="statusText" className="mediaLabeltext">
                {configJSON.statusLabelText}
              </Typography>
            }
          </div>
          <CardContent className="card-content">
            <div className="card-details">
              <Box className="card-title-wrapper">
                <Box style={{ flex: "auto" }}>
                  <Typography
                    data-test-id="cardTitle"
                    variant="h6"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleNavigation("ManageListingDetails", item.id)}
                  >
                    {item.listing_title}
                  </Typography>
                  <Typography variant="subtitle1" className="addressText">
                    {item.address || "Not Provided"}
                  </Typography>
                </Box>
                <Box display="flex" gridGap={5} height={25} className="parentIcons">
                  <IconButton className="iconBtn">
                    <EditIcon data-test-id="editIconBtn" onClick={() => this.handleNavigation("ManageListingDetails", item.id)} />
                  </IconButton>
                  <IconButton className="iconBtn" data-test-id="menuIconBtn" onClick={(event) => this.handleMenuClick(event, item)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    data-test-id="menu"
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    anchorEl={this.state.anchorElMenu}
                    open={Boolean(this.state.anchorElMenu)}
                    onClose={this.handleMenuClose}
                  >
                    <MenuItem data-test-id="statusMenuItem" onClick={this.toggleListingItem}>
                      <ListItemIcon>
                        <BlockOutlinedIcon />
                      </ListItemIcon>
                      {!this.state.currentItem?.available ? (
                        <ListItemText primary={configJSON.menuUpdateStatusText2} />
                      ) : (
                        <ListItemText primary={configJSON.menuUpdateStatusText1} />
                      )}
                    </MenuItem>
                    <MenuItem data-test-id="shareMenuItem" onClick={this.shareListingItem}>
                      <ListItemIcon>
                        <ShareRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Share listing" />
                    </MenuItem>
                    <MenuItem data-test-id="deleteMenuItem" onClick={this.handleDeleteClick}>
                      <ListItemIcon>
                        <DeleteOutlineIcon />
                      </ListItemIcon>
                      <ListItemText primary={configJSON.deleteListingText} />
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
              <Divider />
              <Box className="price-wrapper">
                <Typography className="price-detail" variant="subtitle1">
                  {configJSON.dayPriceLabel} <Box>${Math.round(Number(item.guest_total))}</Box>
                </Typography>
                <Typography className="price-detail" variant="subtitle1">
                  {configJSON.yourEarningsLabel} <Box>${Math.round(Number(item.base_price))}</Box>
                </Typography>
                <Typography className="price-detail" variant="subtitle1">
                  {configJSON.daydockerGuestTotalLabel} <Box>${Math.round(Number(item.guest_total))}</Box>
                </Typography>
                <Box mt={2}>
                  <Typography className="price-detail" variant="h6">
                    {configJSON.totalPayLabel} <Box>${Math.round(Number(item.guest_total))}</Box>
                  </Typography>
                </Box>
              </Box>
            </div>
          </CardContent>
        </Card> 
      </Grid>
    )
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <ThemeProvider theme={theme}>
          <ShareModal
            isOpen={this.state.shareFlag}
            link={`https://nathanrobinsonsbc-496588-react.b496588.dev.eastus.az.svc.builder.cafe/ManageListingDetails/${this.state.currentItem?.id}?url=${this.state.shareUrl}`}
            onOpenCloseModal={this.shareListingItem}
            handleCopyText={this.handleCopyText}
          />
          <GenericModal
            data-test-id="deleteModal"
            dataTest="delete-modal"
            onClose={this.onCloseDialog}
            open={this.state.isDeleteDialogOpen}
          >
            <ThemeProvider theme={theme}>
              <Wrapper>
                <Box>
                  {this.state.errorMsg && <Toast message={this.state.errorMsg} />}
                  <Typography variant="h3" style={{lineHeight: "32px",fontSize: "24px"}}>{configJSON.deleteDialogTitle}</Typography>
                  <Typography variant="body1">{configJSON.deleteDialogText}</Typography>
                  <Box mt={3}>
                    <Button
                      variant="text"
                      color="primary" 
                      onClick={this.onCloseDialog}
                      data-test-id="cancelBtn"
                      fullWidth
                    >
                      {configJSON.btnCancelTitle}
                    </Button>
                    <Button
                      variant="contained"
                      data-test-id="deleteButton"
                      type="submit"
                      color="primary"
                      onClick={this.deleteListingItem}
                      fullWidth
                    >
                      {configJSON.deleteDialogBtn}
                    </Button>
                  </Box>
                </Box>
              </Wrapper>
            </ThemeProvider>
          </GenericModal>
            <Wrapper data-test-id="mainWrapper">
                <Container maxWidth="lg">
                    <Header
                        role="host"
                        isLoggedIn={true}
                        navigation={this.props.navigation}
                    />
                    <Container className="manageListingContainer">
                      {this.state.errorMsg && <Toast message={this.state.errorMsg} />}
                        <Box className="manageListingHeader">
                            <Typography variant="h3" data-test-id="headerTitle" className="headerTitle">
                                {configJSON.manageListingTitleText}
                            </Typography>
                            <Button 
                              variant="outlined"
                              className="addListingBtn"
                              data-test-id="addListingBtn"
                              startIcon={<AddCircleOutlineIcon />}
                              onClick={() => this.handleNavigation("DockListingForm")}
                            >
                              {configJSON.addListingBtn}
                            </Button>
                        </Box>
                        <CardWrapper data-test-id="cardWrapper">
                          {this.state.manageListingItems.length ?
                            (<Box>
                              <Box style={{ display: "flex" }}>
                                <Grid container spacing={3} justifyContent="center">
                                  {this.state.manageListingItems.map((item) => this.renderItem(item))}
                                </Grid>
                              </Box>
                              <Box className="pagination">
                                <Pagination
                                  data-test-id="pagination"
                                  count={Math.ceil(this.state.total_pages)}
                                  siblingCount={0}
                                  page={this.state.current_page}
                                  onChange={this.handlePageChange}
                                />
                                <Typography variant="subtitle1">
                                  {(this.state.current_page - 1) * this.itemsPerPage + 1} - {this.state.current_page * this.itemsPerPage > this.state.total_count
                                    ? this.state.total_count
                                    : this.state.current_page * this.itemsPerPage} of {this.state.total_count} results
                                </Typography>
                              </Box>
                            </Box>) : (<NoDataFound
                                image={notFoundIcon}
                                message={configJSON.noListingsFoundText}
                                name={configJSON.noListingsFoundText}
                              />)
                          }
                        </CardWrapper>
                    </Container>
                </Container>
                <Footer navigation={this.props.navigation}/>
            </Wrapper>
        </ThemeProvider>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .manageListingContainer": {
    marginBottom: '30px', 
    padding: 0,
  },
  "& .manageListingHeader": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "15px 0 30px 0",
  },
  "& .addListingBtn": {
    color: "#4F9FF8",
    textTransform: "none",
    borderRadius: "8px",
    borderColor: "#4F9FF8",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
  },
  "& .price-detail": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .iconBtn": {
    padding: "0px",
    alignItems: "flex-start",
    color: "#0F172A",
  },
  "& .pagination": {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    padding: "50px 50px 0 0",
    gap: 40,
    "& button": {
      textDecoration: "underline",
    },
  },
  "& .Mui-selected": {
    fontWeight: 700,
    backgroundColor: "transparent",
    textDecoration: "none !important",
  },
  [theme.breakpoints.down(840)]: {
    "& .headerTitle": {
      fontSize: "1.3rem",
    },
  },
  [theme.breakpoints.down(500)]: {
    "& .pagination": {
      padding: "30px 20px 0 20px",
      gap: 5,
    },
  },
  [theme.breakpoints.down('sm')]: {
    "& .searchInput": {
      width: "50vw",
    },
    "& .manageListingHeader": {
      flexDirection: "column",
      gap: "10px",
    }
  },
}));

 const CardWrapper = styled("div")(({ theme }) => ({
  "& .card-root": {
    display: 'flex',
    maxWidth: '628px',
    borderRadius: '12px',
    boxShadow: '0px 2px 10px -1px rgba(0, 0, 0, 0.2)',
  },
  "& .card-details": {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  "& .card-content": {
    display: 'flex',
    justifyContent: 'space-between',
    flex: "auto",
  },
  "& .card-title-wrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .card-media": {
    width: "15rem",
    height: '100%',
    maxHeight: "227px",
    objectFit: "fill",
  },
  "& .addressText": {
    maxWidth: "279px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .cardMediaWrapper": {
    position: "relative",
  },
  "& .mediaLabeltext": {
    position: "absolute", 
    top: "10%",
    left: "10%",
    color: '#B91C1C',
    backgroundColor: '#FEF2F2',
    borderRadius: '40px',
    padding: '2px 8px',
    fontSize: '12px',
    fontWeight: 700,
  },
  [theme.breakpoints.down(650)]: {
    "& .gridItem": {
      width: "100%"
    },
    "& .card-media": {
      width: "11rem"
    },
  },
  [theme.breakpoints.down(600)]: {
    "& .gridItem": {
      width: "100%"
    },
    "& .parentIcons": {
      padding: 0,
    },
    "& .card-root": {
     flexDirection: 'column',
    },
    "& .card-media": {
      width: "100%",
      height: "auto",
    },
},
 }));

// Customizable Area End
