import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { sendAPIRequest, validateEmailFormat } from "../../../components/src/utils";

export const configJSON = require("./config");

type ErrorType = string | { account: string }[];

export enum Step {
  EnterEmail = 1,
  VerifyEmail,
  EnterPassword,
  UpdatedSuccessfully,
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isOpen: boolean;
  onClose: () => void;
  goToLogin: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  currentStep: Step;
  token: string;
  tempToken: string;
  errorMessage: string;
  passwordError: string;
  confirmPasswordError: string;
  emailError: string;
  verifyCodeError: string;
  successMessage: string;
  enteredEmail: string;
  enteredPassword: string;
  enteredConfirmPassword: string;
  enteredVerifyCode: string;
  isPasswordObscured: boolean;
  formSubmitted: boolean;
  alreadyResentCode: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ForgotModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  callResetPasswordApiId = '';
  callResendCodeApiId = '';
  callCheckCodeApiId = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      currentStep: Step.EnterEmail, // Step.EnterEmail,
      token: '',
      tempToken: '',
      errorMessage: '',
      passwordError: '',
      confirmPasswordError: '',
      emailError: '',
      verifyCodeError: '',
      successMessage: '',
      enteredEmail: '',
      enteredPassword: '',
      enteredConfirmPassword: '',
      enteredVerifyCode: '',
      isPasswordObscured: true,
      formSubmitted: false,
      alreadyResentCode: false,
      // Customizable Area End
    };

    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.handleSubmit = this.handleSubmit.bind(this);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  get isDisabled() {
    let isDisabled = true;
    const { currentStep, enteredEmail, enteredVerifyCode, } = this.state;
    switch (currentStep) {
      case Step.EnterEmail:
        isDisabled = enteredEmail.trim() === '';
        break;
      case Step.VerifyEmail:
        isDisabled = enteredVerifyCode.trim() === '';
        break;
      case Step.EnterPassword:
        isDisabled = !this.metMin8Characters || !this.metAtLeastOneNumber || !this.metAtLeastOneSpecialCharacter;
        break;
    }
    return isDisabled;
  }

  get submitBtnText() {
    let btnLabel = '';
    const { currentStep, } = this.state;
    switch (currentStep) {
      case Step.EnterEmail:
      case Step.VerifyEmail:
        btnLabel = 'Submit';
        break;
      case Step.EnterPassword:
        btnLabel = 'Reset Password';
        break;
      default:
        btnLabel = 'Submit';
        break;
    }
    return btnLabel;
  }

  get title() {
    let title = '';
    const { currentStep } = this.state;
    switch (currentStep) {
      case Step.EnterEmail:
        title = `Forgot Password`;
        break;
      case Step.VerifyEmail:
        title = 'Verify your email address';
        break;
      case Step.EnterPassword:
        title = 'Reset Password';
        break;
      case Step.UpdatedSuccessfully:
        title = 'Updated successfully';
        break;
    }
    return title;
  }

  get subTitle() {
    let subTitle = '';
    const { currentStep, enteredEmail } = this.state;
    switch (currentStep) {
      case Step.EnterEmail:
        subTitle = `We'll send code to your email address.`;
        break;
      case Step.VerifyEmail:
        subTitle = `Enter the code that was sent to your <strong>${enteredEmail}</strong>`;
        break;
      case Step.UpdatedSuccessfully:
        subTitle = 'You can now login with your new password.';
        break;
      default:
        subTitle = ``;
        break;
      
    }
    return subTitle;
  }

  get metMin8Characters() {
    return this.state.enteredPassword.trim().length >= 8;
  }

  get metAtLeastOneNumber() {
    return /\d/.test(this.state.enteredPassword.trim());
  }

  get metAtLeastOneSpecialCharacter() {
    return /[^A-Za-z0-9]/.test(this.state.enteredPassword.trim());
  }

  get confirmPasswordMatch() {
    return this.state.enteredPassword === this.state.enteredConfirmPassword;
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>);
  };

  togglePasswordVisibility = () => {
    this.setState(prevState => ({
      isPasswordObscured: !prevState.isPasswordObscured
    }));
  }

  goToLogin = () => {
    this.props.goToLogin();
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Reset error state
    this.setState({ confirmPasswordError: ``, });

    const { currentStep } = this.state;
    switch (currentStep) {
      case Step.EnterEmail:
        if (!this.step1InputsOkay()) return;

        this.setState(prevState => ({
          currentStep: prevState.currentStep + 1,
          errorMessage: '',
          successMessage: '',
        }));

        // Call send code API
        this.resendVerifyCode();
        break;
      case Step.VerifyEmail:
        // Call check code API
        this.checkVerificationCode();
        break;
      case Step.EnterPassword:
        if (!this.confirmPasswordMatch) {
          this.setState({ confirmPasswordError: `The confirm password doesn't match` });
          return;
        }

        // Call reset password API
        this.resetPassword();
        break;
      
    }
  }

  step1InputsOkay = (): boolean => {
    const { enteredEmail } = this.state;
    let emailError = '';

    if (!validateEmailFormat(enteredEmail)) {
      emailError = configJSON.errorEmail1;
    }

    this.setState({ emailError });

    if (!emailError) {
      this.setState({ emailError: '' });
      return true;
    }
    return false;
  }

  resetPassword = () => {
    this.setState({ isLoading: true, errorMessage: '' });

    this.callResetPasswordApiId = sendAPIRequest(
      `bx_block_forgot_password/passwords`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          data: {
            'token': this.state.tempToken,
            'new_password': this.state.enteredPassword,
            'confirm_password': this.state.enteredPassword,
          },

        }
      }
    )
  }

  resendVerifyCode = () => {
    if (this.state.isLoading === false) {
      this.setState({ isLoading: true, errorMessage: '' });

      this.callResendCodeApiId = sendAPIRequest(
        `forgot_password/otp`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            data: {
              attributes: {
                email: this.state.enteredEmail,
              },
            },
          }
        }
      )
    }
  }

  checkVerificationCode = () => {
    if (this.state.isLoading === false) {
      this.setState({ isLoading: true, errorMessage: '' });

      this.callCheckCodeApiId = sendAPIRequest(
        `forgot_password/otp_confirmation`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            data: {
              'token': this.state.tempToken,
              'otp_code': this.state.enteredVerifyCode,
            },
          },
        }
      )
    }
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.callResetPasswordApiId]: this.handleResetPasswordResponse,
      [this.callResendCodeApiId]: this.handleResendVerifyCodeResponse,
      [this.callCheckCodeApiId]: this.handleCheckVerifyCodeResponse,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    this.setState({ isLoading: false });

    const { errors: possibleErrors } = responseJSON;
    if (possibleErrors) {
      const errors = possibleErrors as ErrorType;
      if (Array.isArray(errors)) {
        const errorValues = Object.values(errors[0]);
        const errorMsg = errorValues.length > 0 ? errorValues[0] : 'Something went wrong';
        this.setState({ errorMessage: errorMsg, successMessage: '' });
      } else {
        this.setState({
          errorMessage: typeof errors === 'string' ? errors : 'Something went wrong',
          successMessage: '',
        });
      }
      return true; // Indicates that there was an error
    }

    return false; // Indicates that there was no error
  }

  handleResetPasswordResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    this.setState(prevState => ({
      currentStep: prevState.currentStep + 1, // update password successfully modal + login link
      errorMessage: '',
      successMessage: '',
    }));
  }

  handleResendVerifyCodeResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    const response = responseJSON as { meta: { token: string; } };

    this.setState(prevState => ({
      errorMessage: '',
      successMessage: prevState.alreadyResentCode ? 'New code has been sent successfully.' : '',
      alreadyResentCode: true,
      tempToken: response.meta.token,
    }));
  }

  handleCheckVerifyCodeResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    this.setState(prevState => ({
      errorMessage: '',
      successMessage: '',
      currentStep: prevState.currentStep + 1,
    }));
  }

  // Customizable Area End
}

