import React from "react";
// Customizable Area Start
import LandingPageController, {
  Props,
  configJSON,
} from "./LandingPageController.web";
import {
  Container,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import SearchIcon from "@material-ui/icons/Search";
import {
  GooglePlayButton,
  AppStoreButton,
  ButtonsContainer,
} from "react-mobile-app-button";

import {
  imgBackground,
  imgBook,
  imgDiscover,
  imgEnjoy,
  imgListing,
} from "./assets";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { getLastPartOfURL } from "../../../components/src/utils";

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Josefin Sans",
      },
      h1: {
        fontFamily: "Josefin Sans",
        fontSize: "36px",
      },
      h3: {
        fontFamily: "Josefin Sans",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        textAlign: "center",
      },
      h5: {
        fontFamily: "Josefin Sans",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
        textAlign: "center",
        margin: "5px",
      },
      body1: {
        fontFamily: "Josefin Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        textAlign: "center",
        margin: "10px 0px",
      },
    },
    MuiInputBase: {
      input: {
        fontFamily: "Outfit, sans-serif !important",
        color: "#64748B",
      },
    },
    MuiButton: {
      root: {
        fontSize: "1rem",
      },
      contained: {
        fontFamily: "Josefin Sans",
        fontSize: "18px",
        color: "#FFFFFF",
        backgroundColor: "#4F9FF8",
        borderColor: "#4F9FF8 !important",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#FFFFFF",
          color: "#4F9FF8",
        },
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: "none",
        borderBottom: "2px solid #E2E8F0",
        "&:before": {
          backgroundColor: "transperant !important",
        },
        "& .MuiPaper-root.MuiAccordion-root": {
          boxShadow: "none",
        },
        "& .MuiAccordion-root.Mui-expanded": {
          border: "1px !important",
          borderColor: "#E2E8F0 !important",
        },
        "& .MuiAccordionSummary-content": {
          fontFamily: "Josefin Sans",
          color: "#94A3B8",
          fontSize: "20px",
          fontWeight: 700,
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          color: "#0F172A !important",
        },
        "& .MuiAccordionDetails-root": {
          fontFamily: "Josefin Sans",
          fontSize: "16px",
          fontWeight: 400,
          color: "#9095A1",
        },
      },
    },
  },
});

// Customizable Area End

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MainWrapper>
          <Container maxWidth="lg">
            <Header 
              navigation={this.props.navigation}
              role={getLastPartOfURL().url === "boater" ? "boater" : ""}
            />
          </Container>
          <Box mb={16}>
            <Container className="listingContainer">
            {this.state.dockList.length > 0 && 
            <>
              <Typography
                data-test-id="pageTitleText"
                variant="h1"
                align="center"
                className="titleText"
              >
                {configJSON.titleText}
              </Typography>
              <Box display="flex" justifyContent="center">
                <TextField
                  id="search-bar"
                  data-test-id="searchDock"
                  className="searchBar"
                  variant="outlined"
                  placeholder="Search for docks, locations"
                  size="small"
                  value={this.state.searchTerm}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#64748B" }} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={ (event) => this.handleSearchByText(event.target.value) }
                />
              </Box>
            </>
            }
              <Box mt={3} mb={4} style={{height:'maxContent'}}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  { this.renderDockList() }
                </Grid>
                {this.state.dockList.length > 0 && 
                <Box display="flex" justifyContent="center" my={3}>
                  <Button 
                    data-test-id="viewMoreBtn"
                    variant="contained" 
                    className="btnPrimary"
                    onClick={() => this.handleNavigation("BoaterSearch")}
                  >
                    {configJSON.btnViewMoreText}
                  </Button>
                </Box>
                }
              </Box>
            </Container>
          </Box>
          <Box className="aboutDayDockerWrapper" style={{marginTop:this.state.dockList.length <= 0 ? '300px' :0}}>
            <Typography align="center" className="aboutDayDockerText">
              {configJSON.aboutDayDockerText}
            </Typography>
            <Container className="listingContainer">
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid container item md={4} justifyContent="center">
                  <Box className="abtDayDockerWrapper">
                    <img src={imgDiscover} className="abtDayDockerImgs" />
                    <Box my={3}>
                      <Typography variant="h3">
                        {configJSON.discoverText}
                      </Typography>
                      <Typography variant="body1">
                        {configJSON.discoverDiscription}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid container item md={4} justifyContent="center">
                  <Box className="abtDayDockerWrapper">
                    <img src={imgEnjoy} className="abtDayDockerImgs" />
                    <Box my={3}>
                      <Typography variant="h3">
                        {configJSON.enjoyText}
                      </Typography>
                      <Typography variant="body1">
                        {configJSON.enjoyDiscription}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid container item md={4} justifyContent="center">
                  <Box className="abtDayDockerWrapper">
                    <img src={imgBook} className="abtDayDockerImgs" />
                    <Box my={3}>
                      <Typography variant="h3">
                        {configJSON.bookText}
                      </Typography>
                      <Typography variant="body1">
                        {configJSON.bookDiscription}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Box display="flex" justifyContent="center" my={3}>
              <Button 
                data-test-id="becomeHostBtn"
                variant="contained" 
                className="btnPrimary" 
                onClick={() => this.handleNavigation("JobListing")}
              >
                {configJSON.btnHostText}
              </Button>
            </Box>
          </Box>
          <Box className="downloadAppSection">
            <Box className="downloadAppSectionWrapper">
              <Typography variant="h1" className="appDownloadTitle">
                {configJSON.appDownloadTitle}
              </Typography>
              <Typography variant="h3" className="appDownloadDiscription">
                {configJSON.appDownloadDiscription}
              </Typography>
              <ButtonsContainer>
                <GooglePlayButton
                  url={configJSON.androidAPKUrl}
                  theme={"dark"}
                  className="apkBtn"
                />
                <AppStoreButton
                  url={configJSON.iosAPKUrl}
                  theme={"dark"}
                  className="apkBtn"
                />
              </ButtonsContainer>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <Box className="faqsWrapper">
              <Typography
                variant="h1"
                align="center"
                className="titleText"
                data-test-id="Frequently"
              >
                Frequently Asked Questions
              </Typography>
              {this.state.faqDataList.length > 0 ?                
              this.state.faqDataList?.map((faqs, index) => {
                return (
                  <Accordion
                    defaultExpanded
                    data-test-id={`panel${index + 1}`}
                    expanded={
                      this.state.expandedAccordionPanel === `panel${index + 1}`
                    }
                    onChange={this.setExpandedAccordionPanel(
                      `panel${index + 1}`
                    )}
                  >
                    <AccordionSummary
                      data-test-id={`panel${index + 1}-summary`}
                      expandIcon={
                        this.state.expandedAccordionPanel ===
                        `panel${index + 1}` ? (
                          <RemoveCircleOutlineIcon />
                        ) : (
                          <AddCircleOutlineIcon />
                        )
                      }
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                    >
                      {faqs.attributes.question}
                    </AccordionSummary>
                    <AccordionDetails
                      data-test-id={`panel${index + 1}-details`}
                    >
                      {faqs.attributes.answer}
                    </AccordionDetails>
                  </Accordion>
                );
              })
              : <Typography className="title2">No FAQs data found</Typography>
              }
            </Box>
          </Box>
          <Footer navigation={this.props.navigation}/>
        </MainWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const MainWrapper = styled("div")(({ theme }) => ({
  "& .title2": {
    fontFamily: 'Josefin Sans',
    fontSize: '24px',
    fontWeight: 700,
    marginTop: '47px',
    marginBottom: '2px',
    justifyContent: 'center',
    display: 'flex',
    color: '#94A3B8'
  },
  "& .listingContainer": {
    marginBottom: "30px",
    flexDirection: "unset" as const,
    justifyContent: "center",
  },
  "& .titleText": {
    fontWeight: "700",
    lineHeight: "44px",
    letterSpacing: "-0.01em",
    margin: "20px 0px",
  },
  "& .searchBar": {
    width: "440px",
    height: "56px",
    borderRadius: "8px",
  },
  "& .listItem": {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
  },
  "& .listImg": {
    width: "338.6px",
    height: "248.46px",
    gap: "0px",
    opacity: "0px",
    borderRadius: "20px",
  },

  "& .aboutDayDockerWrapper": {
    width: "100%",
    backgroundImage: `url(${imgBackground})`,
    backgroundRepeat: 'revert-layer',
    backgroundSize: "cover",
    padding: "150px 0px",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center" as const,
    '@media (min-width: 1550px)': {
      backgroundSize: 'contain',
      backgroundRepeat: 'round',
    },
  },
  "& .aboutDayDockerText": {
    fontFamily: "Josefin Sans",
    fontSize: "30px",
    fontWeight: "700",
    lineHeight: "40px",
    margin: "30px 0px",
  },
  "& .abtDayDockerWrapper": {
    width: "245px",
    display: "grid",
  },
  "& .abtDayDockerImgs": {
    width: "179px",
    height: "172px",
    margin: "auto",
  },
  "& .btnPrimary": {
    width: "176px",
    height: "56px",
    borderRadius: "8px",
  },

  "& .downloadAppSection": {
    margin: "120px 80px",
  },
  "& .downloadAppSectionWrapper": {
    backgroundColor: "#FDDA96",
    padding: "56px",
    borderRadius: "8px",
  },
  "& .appDownloadTitle": {
    fontWeight: "700",
    margin: "30px 0px",
  },
  "& .appDownloadDiscription": {
    textAlign: "start" as const,
    fontWeight: "400",
    margin: "30px 0px",
  },
  "& .apkBtn": {
    width: "189px",
    height: "56px",
    borderRadius: "8px",
  },
  "& .faqsWrapper": {
    maxWidth: "960px",
    margin: "120px 20px",
  },
  [theme.breakpoints.down(700)]: {
    "& .downloadAppSection": {
      margin: "120px 35px",
    },
    "& .appDownloadTitle": {
      fontSize: "32px",
    },
    "& .appDownloadDiscription": {
      fontSize: "20px",
    },
    "& .apkBtn": {
      width: "120px",
    },
    "& .button-store-name": {
      fontSize: "18px",
    },
    "& .buttons-container": {
      flexDirection: "column !important",
    },
  },
}));
// Customizable Area End
