Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";

exports.titleText = "Find a Dock";
exports.searchSuggestionTitle = "Search by state";
exports.searchSuggestionBottomText = "Want to see daydocker at a dock near you?";
exports.searchSuggestionBottomTextLink = "Tell us where?";
exports.btnViewMoreText = "View more";

exports.aboutDayDockerText = "How does Daydocker work?";
exports.discoverText = "Discover";
exports.enjoyText = "Enjoy";
exports.bookText = "Book";
exports.discoverDiscription = "Upload pictures, set a rate, and describe your dock and any dock rules.";
exports.enjoyDiscription = "You will receive the precise address, instructions and everything you need for your reservations.";
exports.bookDiscription = "Allow instant reservations or review and approve reservation requests from boaters.";
exports.btnHostText = "Become a host";

exports.appDownloadTitle = "Download our mobile application";
exports.appDownloadDiscription = "We are now also available on appstore and playstore";
exports.androidAPKUrl = "#";
exports.iosAPKUrl = "#";

exports.faqsQuestion1 = "Is the dock suitable for all types of boats?";
exports.faqsQuesstion1Answer = "Our dock is designed to accommodate a variety of watercraft, including boats, jet skis, and kayaks. However, please note the maximum size and weight restrictions to ensure compatibility.";
exports.faqsQuestion2 = "Is fishing allowed off the dock?";
exports.faqsQuestion3 = "Is there electricity available on the dock?";
exports.faqsQuestion4 = "What should I do in case of an emergency?";
exports.faqPath = "/bx_block_content_management/faqs";
exports.searchAPIPath = "/bx_block_advanced_search/dock_search/search_by_location";
exports.noDockFoundMessage = "Opps, No docks found.";
// Customizable Area End