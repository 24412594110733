// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Avatar,
  styled,
} from "@material-ui/core";
import SettingsProfileController, {
  Props,
} from "./SettingsProfileController.web";
import Settings2 from "./Settings2.web";

export default class SettingsProfile extends SettingsProfileController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Settings2 navigation={this.props.navigation} id={this.props.id}>
        <Wrapper data-test-id="profileSettingWrapper">
          {this.state.isViewMode ? (
            <Box className="bodyContent">
              <Grid container>
                <Typography variant="h5" data-test-id="profileTitle">
                  Profile
                </Typography>
                <Grid item md={12} xs={12} sm={12} className="viewBlock">
                  <Typography variant="body1">Profile picture</Typography>
                  <Avatar
                    style={{ margin: "5px 0 10px 0" }}
                    src={this.state.userDetails.profile_pic}
                    alt={this.state.userDetails.first_name || ""}
                  />
                </Grid>
                <Grid item md={12} xs={12} sm={12} className="viewBlock">
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={6} xs={12}>
                      <Typography variant="body1">First name</Typography>
                      <Typography variant="subtitle1">
                        {this.state.userDetails.first_name || "Not Provided"}
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      <Typography variant="body1" className="paddingTop">
                        Last name
                      </Typography>
                      <Typography variant="subtitle1">
                        {this.state.userDetails.last_name || "Not Provided"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12} sm={12} className="viewBlock">
                  <Typography variant="body1">Email address</Typography>
                  <Typography variant="subtitle1">
                    {this.state.userDetails.email || "Not provided"}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12} sm={12} className="viewBlock">
                  <Typography variant="body1">Phone number</Typography>
                  <Typography variant="subtitle1">
                    {this.state.userDetails.full_phone_number
                      ? "+" + this.state.userDetails.full_phone_number
                      : "Add a number so confirmed guests and Airbnb can get in touch. You can add other numbers and choose how they’re used."}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12} sm={12} className="viewBlock">
                <Typography variant="body1">Government ID</Typography>
                  <Avatar
                    style={{ margin: "5px 0 10px 0" }}
                    src={this.state.userDetails.govt_id}
                    alt={this.state.userDetails.first_name || ""}
                  />                  
                </Grid>
                <Grid item md={12} xs={12} sm={12} className="viewBlock">
                  <Typography variant="body1">Address</Typography>
                  <Typography variant="subtitle1">
                    {Object.values(this.state.userDetails.address)
                      .filter((value) => value && value !== "")
                      .join(", ") || "Not provided"}
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12} sm={12} className="viewBlock">
                  <Typography variant="body1">Emergency contact</Typography>
                  <Typography variant="subtitle1">
                    {this.state.userDetails.emergency_contacts.name &&
                      (this.state.userDetails.emergency_contacts.name +
                        (this.state.userDetails.emergency_contacts
                          .full_phone_number &&
                          ` (${
                            "+" +
                            this.state.userDetails.emergency_contacts
                              .full_phone_number
                          })`) ||
                        "Not provided")}
                  </Typography>
                </Grid>
                <Button
                  data-test-id="switchToEditBtn"
                  variant="outlined"
                  color="primary"
                  onClick={this.switchToEditMode}
                >
                  Edit Profile
                </Button>
              </Grid>
            </Box>
          ) : (
            this.renderEditProfileForm()
          )}
        </Wrapper>
      </Settings2>
    );
  }
}

const Wrapper = styled("div")(({ theme }) => ({
  "& .bodyContent": {
    margin: "0 200px 0 50px",
  },
  "& .viewBlock": {
    margin: "12px 0",
  },
  "& .editViewBlock": {
    margin: "10px 0",
  },
  "& .paddingTop": {
    paddingTop: "5px",
  },
  "& .paddingBottom": {
    paddingBottom: "8px",
  },
  "& .paddingRight": {
    paddingRight: "5px",
  },
  "& .paddingLeft": {
    paddingLeft: "5px",
  },
  "& .actionBtnGroup": {
    display: "flex",
    gap: 20,
  },
  "& .colorRed": {
    color: "#EF4444",
  },
  "& .imgError": {
    fontWeight: 600,
    fontFamily: "Outfit",
  },
  [theme.breakpoints.down(1030)]: {
    "& .bodyContent": {
      margin: "0 150px 0 50px",
    },
  },
  [theme.breakpoints.down(950)]: {
    "& .bodyContent": {
      margin: "0 100px 0 30px",
    },
  },
  [theme.breakpoints.down(870)]: {
    "& .bodyContent": {
      margin: "0 40px 0 30px",
    },
  },
  [theme.breakpoints.down(740)]: {
    "& .bodyContent": {
      margin: "0 10px 0 30px",
    },
  },
  [theme.breakpoints.down(500)]: {
    "& .bodyContent": {
      margin: "0 10px 0 15px",
    },
  },
}));

// Customizable Area End
