// Customizable Area Start
import React from "react";
import { Box, Button, Grid, Typography, styled } from "@material-ui/core";
import SettingsPaymentController, {
  Props,
} from "./SettingsPaymentController.web";
import Settings2 from "../../settings2/src/Settings2.web";
import Toast from "../../../components/src/Toast";
import StripeCardForm from "../../../components/src/StripeCardForm.web";

const assets = require("./assets");
const configJSON = require("./config");
// Customizable Area End

export default class SettingsPayment extends SettingsPaymentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const saveBtnText = this.state.isLoading
      ? configJSON.addingPaymentMethodBtnText
      : configJSON.addPaymentMethodBtnText;

    return (
      <Settings2 navigation={this.props.navigation} id={this.props.id}>
        <Wrapper data-test-id="mainWrapper">
          <Box className="bodyContent">
            <Box className="responseMsgContainer">
              {this.state.successMsg && (
                <Toast
                  data-test-id="successToast"
                  message={this.state.successMsg}
                  type="success"
                  onClose={this.handleSuccessMsgClose}
                />
              )}
              {this.state.errorMsg ? (
                <Toast
                  data-test-id="toast"
                  message={this.state.errorMsg}
                  type="error"
                />
              ) : null}
            </Box>
            <Grid container>
              <Grid item md={12} xs={12} sm={12} className="viewBlock">
                {!this.state.isAddCardFormOpen ? (
                  <Box>
                    <Typography
                      variant="body1"
                      className="fontWeight600"
                      data-test-id="titleText"
                    >
                      {configJSON.paymentMethodsText}
                    </Typography>
                    <Typography variant="body1" className="padding10">
                      {configJSON.paymentMethodInfoText}
                    </Typography>
                    <Button
                      data-test-id="addNewCardBtn"
                      variant="contained"
                      color="primary"
                      className="textTransormInitial"
                      onClick={this.handleAddCardFormOpen}
                    >
                      {configJSON.addPaymentMethodBtnText}
                    </Button>
                    {this.state.savedCardList.length
                      ? this.state.savedCardList.map((cardItem) => (
                          <Box
                            data-test-id="savedCard"
                            className="borderCardItem justifySpaceBetween marginBottom10"
                            key={cardItem.id}
                          >
                            <Box className="flexItems">
                              <img
                                className="cardLogo"
                                src={assets.visaCadLogo}
                                alt={cardItem.type || ""}
                              />
                              <Box className="flexItemsCol">
                                <Typography
                                  variant="body1"
                                  className="fontWeight600 textTransormCapitalize"
                                >
                                  {cardItem.type}{" "}
                                  {configJSON.cardNumberPlaceholder}
                                  {cardItem.last4}
                                </Typography>
                                <Typography variant="subtitle1">
                                  {configJSON.expirationDateText} -{" "}
                                  {cardItem.expiry_date}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography
                              data-test-id="removeCardBtn"
                              variant="body1"
                              className="fontWeight600"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.removeCardById(cardItem.id)}
                            >
                              {configJSON.removeText}
                            </Typography>
                          </Box>
                        ))
                      : null}
                  </Box>
                ) : (
                  <Box>
                    <Typography variant="h5">Add a new card</Typography>
                    <StripeCardForm
                      actionBtnRequired
                      saveBtnText={saveBtnText}
                      cancelBtnText={configJSON.cancelBtnText}
                      handleResponse={this.addNewCard}
                      onReset={this.handleReset}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Wrapper>
      </Settings2>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
  "& .bodyContent": {
    margin: "0 200px 100px 50px",
  },
  "& .viewBlock": {
    margin: "12px 0",
  },
  "& .fontWeight600": {
    fontWeight: 600,
  },
  "& .padding10": {
    padding: "10px 0 0 0",
  },
  "& .marginBottom10": {
    marginBottom: "20px",
  },
  "& .textTransormInitial": {
    textTransform: "initial",
  },
  "& .textTransormCapitalize": {
    textTransform: "capitalize",
  },
  "& .borderCardItem": {
    display: "flex",
    gap: "5px",
    padding: "15px",
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
  },
  "& .justifySpaceBetween": {
    justifyContent: "space-between",
  },
  "& .flexItems": {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  "& .cardLogo": {
    width: "24px",
    height: "24px",
  },
  "& .flexItemsCol": {
    display: "flex",
    flexDirection: "column",
  },
  "& .responseMsgContainer": {
    margin: "-60px 0 30px 0",
  },
  [theme.breakpoints.down(1030)]: {
    "& .bodyContent": {
      margin: "0 150px 0 50px",
    },
  },
  [theme.breakpoints.down(950)]: {
    "& .bodyContent": {
      margin: "0 100px 0 30px",
    },
  },
  [theme.breakpoints.down(870)]: {
    "& .bodyContent": {
      margin: "0 40px 0 30px",
    },
  },
  [theme.breakpoints.down(740)]: {
    "& .bodyContent": {
      margin: "0 10px 0 30px",
    },
  },
  [theme.breakpoints.down(500)]: {
    "& .bodyContent": {
      margin: "0 10px 0 15px",
    },
  },
}));
// Customizable Area End
