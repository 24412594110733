// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
  styled,
} from "@material-ui/core";
import Settings2 from "./Settings2.web";
import Toast from "../../../components/src/Toast";
import GenericModal from "../../../components/src/GenericModal";

const modalTheme = createTheme({
  palette: {
    primary: {
      main: "#4F9FF8",
    },
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontWeight: 700,
        fontSize: "1.5rem",
        fontFamily: "Josefin Sans",
        color: "#0F172A",
      },
      body1: {
        fontSize: "1rem",
        fontFamily: "Outfit",
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: "1rem",
        fontFamily: "Outfit",
        fontWeight: 400,
        color: "#64748B",
      },
    },
    MuiButton: {
      root: {
        width: "100%",
        borderRadius: "8px",
        padding: "16px",
        margin: "10px 0",
        height: "44px",
      },
      containedPrimary: {
        fontFamily: "Outfit",
        fontSize: "16px",
        fontWeight: 700,
        color: "white",
        textTransform: "capitalize",
      },
    },
  },
});
// Customizable Area End

import SettingsLoginSecurityController, {
  Props,
  configJSON,
} from "./SettingsLoginSecurityController.web";
import { Wrapper } from "./SettingsNotification.web";

export default class SettingsLoginSecurity extends SettingsLoginSecurityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Settings2 navigation={this.props.navigation} id={this.props.id}>
        <LoginSecurityWrapper data-test-id="loginSecuritySettingsWrapper">
          <GenericModal
            data-test-id="confirmationModal"
            open={this.state.confirmationFlag}
            isCloseIcon={false}
          >
            <ThemeProvider theme={modalTheme}>
              {this.state.confirmationError && (
                <Toast message={this.state.confirmationError} type="error" />
              )}
              <Typography data-test-id="confirmationModalTitle" variant="h6">
                {configJSON.deactivateModalTitleText}
              </Typography>
              <Typography variant="subtitle1">
                {configJSON.deactivateModalHelperText}
              </Typography>
              <Typography
                data-test-id="cancelConfirmationBtn"
                variant="body1"
                color="primary"
                style={{
                  margin: "30px 0 10px 0",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => this.handleConfirmationModal(false)}
              >
                {configJSON.cancelBtnText}
              </Typography>
              <Button
                data-test-id="yesConfirmationBtn"
                variant="contained"
                color="primary"
                onClick={this.deactiveUserAccount}
              >
                {configJSON.deactivateModalYesBtnText}
              </Button>
            </ThemeProvider>
          </GenericModal>
          <Box className="bodyContent">
            <Box className="responseMsgContainer">
              {this.state.successMsg && (
                <Toast
                  data-test-id="successToast"
                  key={new Date().valueOf()}
                  message={this.state.successMsg}
                  type="success"
                />
              )}
            </Box>
            <Grid container>
              <Grid item md={12} xs={12} sm={12} className="viewBlock">
                <Typography
                  data-test-id="loginTitle"
                  variant="body1"
                  className="subTitleText fontWeight600"
                >
                  {configJSON.loginText}
                </Typography>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  className="flexItems justifySpaceBetween"
                >
                  <Typography variant="body1">Password</Typography>
                  <Typography
                    data-test-id="updateBtn"
                    variant="body1"
                    className="fontWeight600 pointer"
                    onClick={this.handleOpenClosePasswordForm}
                  >
                    {this.state.isChangePasswordFlag
                      ? configJSON.cancelBtnText
                      : configJSON.updateBtnText}
                  </Typography>
                </Grid>
                {this.state.isChangePasswordFlag ? (
                  this.renderChangePasswordForm()
                ) : (
                  <>
                    <Typography variant="subtitle1" className="marginTop10">
                      {this.state.lastUpdatedText}
                    </Typography>
                    <Divider className="dividerMargin" />
                    <Typography
                      variant="body1"
                      className="subTitleText fontWeight600"
                    >
                      {configJSON.accountText}
                    </Typography>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      className="flexItems justifySpaceBetween"
                    >
                      <Typography variant="body1">
                        {configJSON.deactivateYourAccountText}
                      </Typography>
                      <Typography
                        data-test-id="deactivateBtn"
                        variant="body1"
                        className="fontWeight600 pointer"
                        color="error"
                        onClick={() => this.handleConfirmationModal(true)}
                      >
                        {configJSON.deactivateMyAccountBtnText}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </LoginSecurityWrapper>
      </Settings2>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const LoginSecurityWrapper = styled(Wrapper)(({ theme }) => ({
  "& .subTitleText": {
    marginBottom: "20px",
  },
  "& .fontWeight600": {
    fontWeight: 600,
  },
  "& .editViewBlock": {
    margin: "20px 0",
  },
  "& .pointer": {
    cursor: "pointer",
  },
  "& .colorGrey": {
    color: "#334155",
  },
}));
// Customizable Area End
