// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
export interface Props {
  navigation: any;
  id: string;
  stripe: any;
  elements: any;
}

interface ICardDetails {
  id: string;
  type: string | null;
  name: string | null;
  last4: string | null;
  expiry_date: string | null;
}

interface SProps {
  isAddCardFormOpen: boolean;
  isLoading: boolean;
  savedCardList: ICardDetails[];
  errorMsg: string;
  successMsg: string;
}

interface SSProps {
  ssId: string;
}
// Customizable Area End

export default class SettingsPaymentController extends BlockComponent<
  Props,
  SProps,
  SSProps
> {
  // Customizable Area Start
  fetchCardListApiCallId: string = "";
  addCardApiCallId: string = "";
  removeCardApiCallId: string = "";
  clearErrorMsgsTimeout: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start
      isAddCardFormOpen: false,
      isLoading: false,
      savedCardList: [],
      errorMsg: "",
      successMsg: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.fetchSavedCardList();
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.fetchCardListApiCallId]: this.handleAPIResponse,
      [this.addCardApiCallId]: this.handleAddCardResponse,
      [this.removeCardApiCallId]: this.handleRemoveCardAPIResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  fetchSavedCardList = async () => {
    this.setState({ isLoading: true });
    const token = await getStorageData("token");
    this.fetchCardListApiCallId = sendAPIRequest(
      "bx_block_stripe_integration/payment_methods",
      {
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  addNewCard = async (token: { id: string }, card_holder: string) => {
    this.setState({ isLoading: true });
    const authToken = await getStorageData("token");
    this.addCardApiCallId = sendAPIRequest(
      "bx_block_stripe_integration/payment_methods",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: authToken,
        },
        body: {
          payment_method: {
            token: token.id,
            card_holder,
          },
        },
      }
    );
  };

  removeCardById = async (id: string) => {
    this.setState({ isLoading: true });
    const token = await getStorageData("token");
    this.removeCardApiCallId = sendAPIRequest(
      `bx_block_stripe_integration/payment_methods/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  handleAPIResponse = (responseJSON: Record<string, unknown>) => {
    this.clearMsgs();
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      data?: {
        id: string;
        type: string;
        attributes: {
          billing_details: { name: string };
          card: {
            last4: string;
            brand: string;
            exp_month: number;
            exp_year: number;
          };
        };
      }[];
    };
    if (response.data) {
      this.setState({
        savedCardList: response.data.map((cardItem) => {
          return {
            id: cardItem.id,
            name: cardItem.attributes.billing_details.name,
            last4: cardItem.attributes.card.last4,
            type: cardItem.attributes.card.brand,
            expiry_date:
              String(cardItem.attributes.card.exp_month).padStart(2, "0") +
              "/" +
              cardItem.attributes.card.exp_year,
          };
        }) as ICardDetails[],
      });
    }
  };

  handleRemoveCardAPIResponse = (responseJSON: Record<string, unknown>) => {
    this.clearMsgs();
    if (this.handleDeleteErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      message: string;
    };
    if (response.message) {
      this.fetchSavedCardList();
      this.setState({
        successMsg: response.message,
      });
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    this.setState({ isLoading: false });
    const { errors } = responseJSON as {
      errors: { stripe: string }[];
    };
    if (errors) {
      this.setState({ errorMsg: errors[0].stripe });
      return true;
    }
    return false;
  };

  handleDeleteErrorResponse = (responseJSON: Record<string, unknown>) => {
    this.setState({ isLoading: false });
    const { error } = responseJSON as {
      error: string;
    };
    if (error) {
      this.setState({ errorMsg: error });
      return true;
    }
    return false;
  };

  handleAddCardResponse = (responseJSON: Record<string, unknown>) => {
    this.clearMsgs();
    if (this.handleErrorResponse(responseJSON)) return;
    const response = responseJSON as {
      data?: { id: string; type: string; attributes: object }[];
      meta?: { message: string };
    };
    if (response.data && response.meta) {
      this.fetchSavedCardList();
      this.setState({
        successMsg: "Congratulations, " + response.meta.message,
      });
      this.handleReset();
    }
  };

  clearMsgs = () => {
    if (this.clearErrorMsgsTimeout) {
      clearTimeout(this.clearErrorMsgsTimeout);
    }
    this.clearErrorMsgsTimeout = setTimeout(() => {
      this.setState({ errorMsg: "" });
      this.handleSuccessMsgClose();
    }, 5000);
  };

  handleReset = () => {
    this.setState({
      errorMsg: "",
      isLoading: false,
      isAddCardFormOpen: false,
    });
  };

  handleAddCardFormOpen = () => {
    this.setState({ isAddCardFormOpen: true });
  };

  handleSuccessMsgClose = () => {
    this.setState({ successMsg: "" });
  };
  // Customizable Area End
}
