import React from "react";
import {
  styled,
} from "@material-ui/core";
import { checked, unchecked, falseChecked } from './assets';

interface ComponentProps {
  description: string;
  met?: boolean;
  customStyle?: { wrapper: {} }
  rightmet?: boolean;
  isFalseIcon?: boolean;
}

const Wrapper = styled('div')({
  '& .password-criterion': {
    display: 'flex',
    alignItems: 'center',
    gap: 5
  },
  '& .criterion-status': {
    backgroundImage: `url(${unchecked})`,
    width: 24,
    height: 24,
    '&.unmet': {
      backgroundImage: `url(${unchecked})`,
    },
    '&.met': {
      backgroundImage: `url(${checked})`,
    },
    '&.falsemet': {
      backgroundImage: `url(${falseChecked})`,
    },
  },
  '& .criterion-description': {
    fontFamily: "Outfit",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22px',
    color: '#94A3B8',
    '&.unmet': {
      color: '#94A3B8',
    },
    '&.met': {
      color: '#1E293B',
    },
    '&.falsemet': {
      color: '#DC2626',
    },
    '&.rightmet': {
      color: '#10B981',
    },
  },
});

const PasswordCriterion: React.FC<ComponentProps> = ({ description, met, customStyle = { wrapper: {} }, rightmet, isFalseIcon }) => {
  return (
    <Wrapper>
      <div className="password-criterion" style={{...customStyle.wrapper}}>
        <div className={`criterion-status ${met || rightmet ? 'met' : isFalseIcon ? 'falsemet' : 'unmet'}`}></div>
        <div className={`criterion-description ${rightmet? 'rightmet' : met ? 'met' : isFalseIcon ? 'falsemet' : 'unmet'}`}>{description}</div>
      </div>
    </Wrapper>
  );
};

export default PasswordCriterion;