import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  basePayPrice: number;
  serviceFee: number;
  draftDocksData:{base_price:number, service_fee: number,lake:{id:number},id:number };
  basePriceErrMsg:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DockListingBasePriceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  draftDocksAPICallId: string = "";
  updateDocksAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      basePayPrice:0,
      serviceFee:0,
      draftDocksData:{base_price:0, service_fee: 0,lake:{id:0},id:0 },
      basePriceErrMsg:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getDraftDocksData()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.dockDraftDataApi(message);
    this.updateDraftNextApi(message);
    // Customizable Area End
  }

  // Customizable Area Start
  dockDraftDataApi = (message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiDraftReqCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const draftJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if (apiDraftReqCallId === this.draftDocksAPICallId) {
        this.setState({
          draftDocksData: draftJsonData.data.attributes
        })
        const draft = draftJsonData.data.attributes;
        const basePayPrice = draft?.base_price !== null ? parseInt(draft?.base_price) : 0;
        const serviceFee = draft?.service_fee !== null ? draft?.service_fee : 0;
        this.setState({basePayPrice, serviceFee});
      }
    }
  } 
  updateDraftNextApi = (message:Message)=>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiUpdateRequId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const docksUpdateJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      if (apiUpdateRequId === this.updateDocksAPICallId) {
        if(docksUpdateJson.data){
          this.handleNavigation("ListingPreview", docksUpdateJson.data.id)
        }
      }
    }
  }
  getDraftDocksData = async () => {
    const token = await getStorageData("token");
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsgs = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.draftDocksAPICallId = getValidationsMsgs.messageId
    getValidationsMsgs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDraftDockApiEndPoint)
    getValidationsMsgs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsgs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getcompanyApiMethod
    );
    runEngine.sendMessage(getValidationsMsgs.id, getValidationsMsgs);
  }
  handleUpdateBasePrice = async () => {
    let basePriceErrMsg = '';

    if (this.state.basePayPrice < 1) {
      basePriceErrMsg = "Please enter base price.";
    } 
    this.setState({ basePriceErrMsg});
    if (basePriceErrMsg) {
      return;
    }
    this.setState({ basePriceErrMsg: ''});
    const formData = new FormData();

    formData.append(`dock_listing[base_price]`, this.state.basePayPrice.toString());
    formData.append(`dock_listing[step]`, '5');
    formData.append(`dock_listing[lake_id]`, this.state.draftDocksData.lake.id.toString());
    formData.append(`dock_listing[completed]`, 'true');    

    const token = await getStorageData("token");
    const headers = {
      token: token,
    };
    const getUpdateResMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateDocksAPICallId = getUpdateResMsg.messageId
    getUpdateResMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createDockListingApiEndPoint}/${this.state.draftDocksData.id}`)
    getUpdateResMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getUpdateResMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    getUpdateResMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(getUpdateResMsg.id, getUpdateResMsg);
  };  
  handleBasePriceCharge = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    this.setState({ basePayPrice: newValue })
  };
  handleNavigation = (route: string, dockId?: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    if (dockId) {
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), dockId);
    }
    this.send(message);
  }; 
  // Customizable Area End
}
