Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  // Customizable Area Start
  exports.apiContentType = "application/json";
  exports.confirmPaymentMethod = "POST";
  exports.stripePaymentIntent = "stripe_integration/payment_intents"
  
  exports.stripePublishableKey = ""
  exports.stripeMerchantDisplayName = "Example.com"
  exports.stripeMerchantIdentifier = "stripeMerchantIdentifier" //used for apple pay
  exports.urlScheme = "rnbuildingblockmasterapp" //app deep linking url
  exports.paymentMethodsText = "Payment methods";
  exports.paymentMethodInfoText =
    "Add a payment method using our secure payment system, then start planning your boating adventure.";
  exports.addNewCardText = "Add a new card";
  exports.nameofCardHolderText = "Name of the card holder";
  exports.namePlaceholder = "e.g. Jassie Dane";
  exports.cardNumberText = "Card number";
  exports.cardPlaceholder = "XXXX XXXX XXXX XXXX";
  exports.expiryDateText = "Expiry date";
  exports.datePlaceholder = "MM/YYYY";
  exports.cvvText = "CVV";
  exports.cvvPlaceholder = "***";
  exports.addPaymentMethodBtnText = "Add a payment method";
  exports.removeText = "Remove";
  exports.addingPaymentMethodBtnText = "Processing..."
  exports.cancelBtnText = "Cancel";
  exports.cardNumberPlaceholder = "****";
  exports.expirationDateText = "Expiration date";
  // Customizable Area End
  