// Customizable Area Start
import React from "react";
import { Box, Divider, Grid, Typography, styled } from "@material-ui/core";
import Settings2 from "./Settings2.web";
import GenericSwitch from "../../../components/src/GenericSwitch";
import Toast from "../../../components/src/Toast";
// Customizable Area End

import SettingsNotificationController, {
  Props,
  configJSON,
} from "./SettingsNotificationController.web";

export default class SettingsNotification extends SettingsNotificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { allow_push_notifications, allow_emails, allow_text_messages } =
      this.state.notificationSettings;
    return (
      <Settings2 navigation={this.props.navigation} id={this.props.id}>
        <Wrapper data-test-id="notificationSettingWrapper">
          <Box className="bodyContent">
            <Box className="responseMsgContainer">
              {this.state.successMsg && (
                <Toast
                  data-test-id="successToast"
                  key={new Date().valueOf()}
                  message={this.state.successMsg}
                  type="success"
                />
              )}
            </Box>
            <Grid container>
              <Grid item md={12} xs={12} sm={12} className="viewBlock">
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  className="flexItems justifySpaceBetween"
                >
                  <Box>
                    <Typography
                      data-test-id="pushNotificationText"
                      variant="body1"
                    >
                      {configJSON.pushNotificationText}
                    </Typography>
                    <Typography variant="subtitle1" className="marginTop10">
                      {configJSON.pushNotificationHelperText}
                    </Typography>
                  </Box>
                  <GenericSwitch
                    data-test-id="allowPushNotificationSwitch"
                    checked={allow_push_notifications}
                    onChange={() =>
                      this.handleNotificationSettingsUpdate(
                        "allow_push_notifications"
                      )
                    }
                  />
                </Grid>
                <Divider className="dividerMargin" />
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  className="flexItems justifySpaceBetween"
                >
                  <Box>
                    <Typography variant="body1">
                      {configJSON.emailsText}
                    </Typography>
                    <Typography variant="subtitle1" className="marginTop10">
                      {configJSON.emailsHelperText}
                    </Typography>
                  </Box>
                  <GenericSwitch
                    data-test-id="allowEmailsSwitch"
                    checked={allow_emails}
                    onChange={() =>
                      this.handleNotificationSettingsUpdate("allow_emails")
                    }
                  />
                </Grid>
                <Divider className="dividerMargin" />
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  className="flexItems justifySpaceBetween"
                >
                  <Box>
                    <Typography variant="body1">
                      {configJSON.textMsgsText}
                    </Typography>
                    <Typography variant="subtitle1" className="marginTop10">
                      {configJSON.textMsgsHelperText}
                    </Typography>
                  </Box>
                  <GenericSwitch
                    data-test-id="allowTextMessagesSwitch"
                    checked={allow_text_messages}
                    onChange={() =>
                      this.handleNotificationSettingsUpdate(
                        "allow_text_messages"
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Wrapper>
      </Settings2>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const Wrapper = styled("div")(({ theme }) => ({
  "& .bodyContent": {
    minHeight: "100vh",
    margin: "0 200px 0 50px",
  },
  "& .viewBlock": {
    margin: "12px 0",
  },
  "& .responseMsgContainer": {
    margin: "-60px 0 30px 0",
  },
  "& .marginTop10": {
    marginTop: 10,
  },
  "& .dividerMargin": {
    margin: "25px 0 20px 0",
  },
  "& .flexItems": {
    display: "flex",
    alignItems: "center",
  },
  "& .justifySpaceBetween": {
    justifyContent: "space-between",
  },
  [theme.breakpoints.down(1030)]: {
    "& .bodyContent": {
      margin: "0 150px 0 50px",
    },
  },
  [theme.breakpoints.down(950)]: {
    "& .bodyContent": {
      margin: "0 100px 0 30px",
    },
  },
  [theme.breakpoints.down(870)]: {
    "& .bodyContent": {
      margin: "0 40px 0 30px",
    },
  },
  [theme.breakpoints.down(740)]: {
    "& .bodyContent": {
      margin: "0 10px 0 30px",
    },
  },
  [theme.breakpoints.down(500)]: {
    "& .bodyContent": {
      margin: "0 10px 0 15px",
    },
  },
}));
// Customizable Area End
