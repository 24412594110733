import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { getLastPartOfURL } from "../../../components/src/utils";

interface Arraydata { id: number, name: string }
interface Draftdata {id:number,max_boats: number,max_guests:number, allow_pets:boolean, allow_smoking:boolean, allow_liveaboards:boolean, rules_and_cautions:string, has_security_camera:boolean, has_animals:boolean, lake:{id:number}};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    noOfBoats: number;
    propertyWithArea: Arraydata[];
    description: string;
    featureData: Arraydata[];
    featureIds: string[];
    noOfGuests:number;
    anchorEl: HTMLElement | null,
    propertyIds: string[];
    boatErrorMsg :string;
    guestErrorMsg :string;
    draftDataIds:Draftdata
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class DockListingRulesInstructionsController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    updateDockAPICallId: string = "";
    draftDockAPICallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.NavigationMessage),
        getName(MessageEnum.NavigationPropsMessage),
        getName(MessageEnum.NavigationTargetMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        boatErrorMsg: '',
        guestErrorMsg: '',
        noOfBoats: 0,
        propertyWithArea: [{
          "id": 1,
          "name": "Security Camera"
        },
        {
          "id": 2,
          "name": "Animal"
        },],
        description: "",
        featureData: [{
            "id": 1,
            "name": "Pets"
        },
        {
            "id": 2,
            "name": "Smoking"
        },
        {
            "id": 3,
            "name": "Liveaboards"
        },],
        featureIds: [],
        noOfGuests:0,
        anchorEl: null,
        propertyIds: [],
        draftDataIds:{id:0,max_boats: 0,max_guests:0, allow_pets:false, allow_smoking:false, allow_liveaboards:false, rules_and_cautions:"", has_security_camera:false, has_animals:false, lake:{id:0}},
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      // Customizable Area Start
      // Customizable Area End
    }

    async componentDidMount() {
      // Customizable Area Start
      this.getDraftDockData()
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      // Customizable Area Start    
      this.draftDockApi(message)
      this.updateDocksApi(message)
      // Customizable Area End
    }

    // Customizable Area Start
    draftDockApi = (message:Message)=>{
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiDraftRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const addOnsDraftJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      
        if (apiDraftRequestCallId === this.draftDockAPICallId) {
          this.setState({
            draftDataIds: addOnsDraftJson.data.attributes
          }
          )
          const noOfGuests = addOnsDraftJson.data.attributes?.max_guests;
          const description = addOnsDraftJson.data.attributes?.rules_and_cautions !== null ? addOnsDraftJson.data.attributes?.rules_and_cautions: "";
          const noOfBoats = addOnsDraftJson.data.attributes?.max_boats;
          this.setState({noOfBoats, noOfGuests, description });
          this.updateFetures(addOnsDraftJson.data.attributes)
          this.updateProperty(addOnsDraftJson.data.attributes)
        }
      }
    } 
    updateFetures=(draftData:Draftdata)=>{
      const featureIds = [];
      if (draftData.allow_pets) {
        const petsFeature = this.state.featureData.find(feature => feature.name === 'Pets');
        if (petsFeature) {
          featureIds.push(petsFeature.name);
        }
      }
      if (draftData.allow_smoking) {
        const smokingFeature = this.state.featureData.find(feature => feature.name === 'Smoking');
        if (smokingFeature) {
          featureIds.push(smokingFeature.name);
        }
      }
      if (draftData.allow_liveaboards) {
        const liveaboardsFeature = this.state.featureData.find(feature => feature.name === 'Liveaboards');
        if (liveaboardsFeature) {
          featureIds.push(liveaboardsFeature.name);
        }
      }
      this.setState({ featureIds });
    }
    updateProperty=(draftData:Draftdata)=>{
      const propertyIds = [];
      if (draftData.has_security_camera) {
        const smokingFeature = this.state.propertyWithArea.find(property => property.name === 'Security Camera');
        if (smokingFeature) {
          propertyIds.push(smokingFeature.name);
        }
      }
      if (draftData.has_animals) {
        const liveaboardsFeature = this.state.propertyWithArea.find(property => property.name === 'Animal');
        if (liveaboardsFeature) {
          propertyIds.push(liveaboardsFeature.name);
        }
      }
      this.setState({ propertyIds });
    }
    getDraftDockData = async () => {
      const token = await getStorageData("token");
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.draftDockAPICallId = getValidationsMsg.messageId
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getDraftDockApiEndPoint)
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getcompanyApiMethod
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    } 
    updateDocksApi = (message:Message)=>{
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiUpdateReqCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const dockUpdateJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      
        if (apiUpdateReqCallId === this.updateDockAPICallId) {
          if(dockUpdateJson.data){            
            this.handleNavigation("DockListingShowOff", getLastPartOfURL().url)
          }
        }
      }
    }
    handleGuestsCount = (event: React.ChangeEvent<HTMLInputElement>)=>{
      const newValue = parseInt(event.target.value);
      this.setState({noOfGuests:newValue})
    }  
    handleDescription = (event: React.ChangeEvent<HTMLTextAreaElement>)=>{
      this.setState({description:event.target.value})
      if(event.target !== null) {this.adjustTextareaHeight(event.target);}
    } 
    adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
      if(textarea !== null){
      textarea.style.height = 'auto'; 
      textarea.style.height = `${textarea.scrollHeight}px`}; 
    };
    handleChecked = (feature: Arraydata) => {
      this.setState((prevState) => {
        const featureIds = [...prevState.featureIds];
        const itemIndex = featureIds.indexOf(feature.name);
    
        if (itemIndex === -1) {
          featureIds.push(feature.name);
        } else {
          featureIds.splice(itemIndex, 1);
        }      
        return { featureIds };
      });
    };
    handlePropertyChecked = (property: Arraydata) => {
      this.setState((prevState) => {
        const propertyIds = [...prevState.propertyIds];
        const itemIndex = propertyIds.indexOf(property.name);
    
        if (itemIndex === -1) {
          propertyIds.push(property.name);
        } else {
          propertyIds.splice(itemIndex, 1);
        }      
        return { propertyIds };
      });
    };
    handleNoOfBoats = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = parseInt(event.target.value);
      this.setState({ noOfBoats: newValue })
    };
    handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      this.setState({ anchorEl: event.currentTarget });
    };  
    handlePopoverClose = () => {
      this.setState({ anchorEl: null });
    };
    handleNavigation = (route: string, dockId: string) => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), route);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), dockId);
      this.send(message);
    };
    handleUpdateDock = async() => {
      let boatErrorMsg = '';
      let guestErrorMsg = '';

      if (this.state.noOfBoats < 1) {
        boatErrorMsg = "Please enter number of Boats.";
      } 
      if (this.state.noOfGuests < 1) {
        guestErrorMsg = "Please enter number of Guests.";
      } 
      this.setState({ boatErrorMsg, guestErrorMsg });
      if (boatErrorMsg || guestErrorMsg) {
        return;
      }
        this.setState({ boatErrorMsg: '', guestErrorMsg: '' });    
      
      const bodyData = {
        "dock_listing": {
          "max_boats": this.state.noOfBoats,
          "max_guests": this.state.noOfGuests,
          "allow_pets": this.state.featureIds.includes('Pets') ? true : false,
          "allow_smoking": this.state.featureIds.includes('Smoking') ? true : false,
          "allow_liveaboards": this.state.featureIds.includes('Liveaboards') ? true : false,
          "rules_and_cautions": this.state.description,
          "has_security_camera": this.state.propertyIds.includes('Security Camera') ? true : false,
          "has_animals": this.state.propertyIds.includes('Animal') ? true : false,
          "step": 3,
          "lake_id": this.state.draftDataIds.lake.id
        }
      }
        const token = await getStorageData("token");      
        const headers = {
          "Content-Type": "application/json",
          token: token,
        };
        const getUpdateMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateDockAPICallId = getUpdateMsg.messageId
        getUpdateMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.createDockListingApiEndPoint}/${this.state.draftDataIds.id}`)
        getUpdateMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        getUpdateMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpPutMethod
        );
        getUpdateMsg.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              JSON.stringify(bodyData)
            );
        runEngine.sendMessage(getUpdateMsg.id, getUpdateMsg); 
    };
    // Customizable Area End
  }
