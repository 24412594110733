import React from "react";
// Customizable Area Start
import { 
    Button, 
    Container, 
    Grid,
    Typography,
    ThemeProvider,
    Box,
    createTheme,
    Divider,
    FormHelperText,
    styled,
    TextField,
    AppBar,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import Header from '../../../components/src/Header';
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import CustomInput from "../../../components/src/CustomInput.web";
import CustomMap from "../../../components/src/CustomMap";
import { imgHeaderLogo, progressBar1} from "./assets";
import Toast from "../../../components/src/Toast";
import { Autocomplete } from "@material-ui/lab";

interface IGeocodeAddress {
  latitude: string,
  longitude: string,
  formatted_address: string,
  locality: string,
  administrative_area_level_2: string,
  administrative_area_level_1: string,
  state_short_name: string,
  postal_code: string,
}


  const theme = createTheme({
    palette: {
      primary: {
        main: "#4F9FF8",
      },
    },
    overrides: {
      MuiTypography: {
        h5: {
          fontFamily: "Josefin Sans",
          fontSize: "1.2rem",
          fontWeight: 700,
          color: "#0F172A",
          marginBottom: 10,
        },
        h6: {
          fontFamily: "Outfit",
          fontSize: "1rem",
          fontWeight: 600,
          color: "#0F172A",
          margin: "12px 0",
        },
        body1: {
          fontFamily: "Outfit",
          fontSize: "1rem",
          fontWeight: 700,
          color: "#64748B",
          textTransform: "capitalize",
        },
        subtitle1: {
          fontFamily: "Outfit",
          fontSize: "0.9rem",
          fontWeight: 400,
          color: "#64748B",
        },
        subtitle2: {
          fontFamily: "Outfit",
          fontSize: "1rem",
          fontWeight: 400,
          color: "#1C1917",
        },
      },
      MuiDivider: {
        root: { margin: "8px 0" },
      },
      MuiButton: {
        root: {
          borderRadius: "8px",
          padding: "16px 32px",
          margin: "10px 0 30px 0",
        },
        containedPrimary: {
          fontFamily: "Outfit",
          fontSize: "16px",
          fontWeight: 700,
          color: "white",
          textTransform: "capitalize",
        },
      },
      MuiTextField: {
        root: {
          "& .MuiFormHelperText-root": {
            color: "red",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
            fontFamily: "Outfit",
          },
          "& label": {
            fontFamily: "Outfit",
            fontSize: "16px",
            fontWeight: 400,
            color: "#94A3B8",
          },
        },
      }
    },
  });
// Customizable Area End

import DockListingFormController, {
  Props,
  configJSON,
} from "./DockListingFormController.web";

export default class DockListingForm extends DockListingFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      errorMsg, 
      successMsg, 
      listingDetails, 
      destinationAddress, 
      isLakeFieldDisabled, 
      lakesList 
    } = this.state;
    return (
        <ThemeProvider theme={theme}>
        <Wrapper data-test-id="dockListingFormWrapper">
          <Container maxWidth="lg">
            <AppBar position="relative" elevation={0} className="headerContent">
              <img src={imgHeaderLogo} className="logoContent" />
            </AppBar>
            <Button
              className="backBtn"
              startIcon={<ArrowBackIosRoundedIcon />}
              onClick={() => this.handleNavigation("JobListing")}
              data-test-id = "backBtn"
              >
                {configJSON.backBtnText}
            </Button>
            <Formik
              initialValues={this.state.listingDetails}
              validationSchema={this.validationSchema}
              onSubmit={(values) => {
                this.handleDockListing(values);
              }}
              data-test-id="listingForm"
              enableReinitialize={true}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Form>
                  <Box className="bodyContent">
                    <Typography variant="h5">
                      {configJSON.mapTitle}
                    </Typography>
                    <Box
                      style={{
                        borderRadius: "8px",
                      }}
                    >
                        <div className="Map">
                            <CustomMap
                                destinationAddress={destinationAddress}
                                onAddressChange={(geocodeAddress: IGeocodeAddress) =>
                                  this.handleGeoCodeAddress(geocodeAddress)
                                }
                                defaultMarkerPosition={{
                                  lat: Number(listingDetails.latitude),
                                  lng: Number(listingDetails.longitude),
                                }}
                            />
                        </div>
                    </Box>
                    <Grid container className="addressBox">
                      <Grid item xs={12} className="paddingTop paddingBottom">
                        <Field
                          name="address"
                          as={CustomInput}
                          placeholder="Address"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            this.handleAddressChange(event.target.value, setFieldValue)
                          }
                          value={values.address}
                          error={touched.address && errors.address}
                          errorMsg={errors.address}
                          data-test-id="address"
                        />
                      </Grid>
                      <Grid item xs={12} className="paddingTop paddingBottom">
                        <Autocomplete
                          data-test-id="lake"
                          options={lakesList}
                          getOptionLabel={(option) => `${option.name}, ${option.state}`}
                          value={values.lake || null}
                          disabled={isLakeFieldDisabled}
                          renderInput={(params) => <TextField
                            {...params}
                            label="Lake"
                            variant="outlined"
                            style={{ borderRadius: "8px !important" }}
                            name="lake"
                            placeholder="Lake"
                            error={Boolean(touched.lake && errors.lake)}
                            helperText={errors.lake}
                            data-test-id="lakeTextField"
                          />}
                          onChange={(event, option) => 
                            this.handleLakeFieldChange(option, setFieldValue)
                          }
                          onInputChange={(event, newInputValue) => this.getLakeList(newInputValue)}
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container className="aboutBox flexBox">
                      <Grid item md={6}>
                        <Typography variant="h5">
                          {configJSON.tellUsAboutYourDock}
                        </Typography>
                        <Typography variant="h6">{configJSON.typeOfDockText}</Typography>
                        <Grid container spacing={2}>
                            { this.renderDockTypes(values, setFieldValue) }
                        </Grid>
                        {touched.dock_type && errors.dock_type && (
                          <FormHelperText error style={{ color: "red" }}>
                            {errors.dock_type}
                          </FormHelperText>
                        )}
                        <Typography variant="h6">{configJSON.areYouListingText}</Typography>
                        <Box className="flexItems">
                          { this.renderWholeOrSlipDock(values, setFieldValue) }
                        </Box>
                        {touched.listing_type && errors.listing_type && (
                          <FormHelperText error style={{ color: "red" }}>
                            {errors.listing_type}
                          </FormHelperText>
                        )}
                        {values.listing_type && (
                          this.renderDockLengthWidthFields(values, touched, errors, setFieldValue)
                        )}
                        {(touched.docking_length && errors.docking_length) && (
                          <FormHelperText error style={{ color: "red" }}>
                            {errors.docking_length}
                          </FormHelperText>
                        )}
                        {(touched.docking_width && errors.docking_width) && (
                          <FormHelperText error style={{ color: "red" }}>
                            {errors.docking_width}
                          </FormHelperText>
                        )}
                        <Typography variant="h6">{configJSON.dockingDepthLabel}</Typography>
                        <Box maxWidth={93}>
                          <TextField
                            variant="outlined"
                            size="small"
                            defaultValue="15"
                            className="dockingDimentionInput"
                            InputProps={{
                              endAdornment: <Typography variant="body2" color="textSecondary">{configJSON.lengthWidthUnit}</Typography>,
                            }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              this.handleLengthInputChange(event, "water_depth", setFieldValue)
                            }
                            value={values.water_depth}
                            data-test-id="water_depth"
                          />
                        </Box>
                        {(touched.water_depth && errors.water_depth) && (
                          <FormHelperText error style={{ color: "red" }}>
                            {errors.water_depth}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item md={6}>
                      {values.listing_type && (
                        <img 
                          alt="listing_type" 
                          src={this.srcPath(values.listing_type)} 
                          className="dockImg"
                          data-test-id="dockImg"
                        />
                      )}
                      </Grid>
                    </Grid>
                    <Divider />
                    <Box className="aboutBox">
                      <Typography variant="h5">
                        {configJSON.listingTitleFieldLabel}
                      </Typography>
                      <Typography variant="h6">
                        {configJSON.listingTitleFieldNote}
                      </Typography>
                      <Field
                        name="listing_title"
                        as={CustomInput}
                        placeholder="Give a title (eg. covered dock with slide)"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue("listing_title", event.target.value)
                        }
                        value={values.listing_title}
                        error={touched.listing_title && errors.listing_title}
                        errorMsg={errors.listing_title}
                        data-test-id="listing_title"
                      />
                      <Typography variant="h6">
                        {configJSON.descriptionFieldLabel}
                      </Typography>
                      <Field
                        name="about"
                        as={CustomInput}
                        placeholder="Description"
                        multiline
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue("about", event.target.value)
                        }
                        value={values.about}
                        error={touched.about && errors.about}
                        errorMsg={errors.about}
                        data-test-id="about"
                      />
                      <FormHelperText style={{ textAlign: "right" }}>
                        <Typography variant="subtitle1">
                          {values.about.length}/100
                        </Typography>
                      </FormHelperText>
                      <Typography variant="subtitle1" className="marginTop">
                        {configJSON.noteForDescription}
                      </Typography>
                      <Typography variant="h6">
                        {configJSON.whyShouldBoater}
                      </Typography>
                      <Field
                        name="rental_reason"
                        as={CustomInput}
                        placeholder="Description"
                        multiline
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue("rental_reason", event.target.value)
                        }
                        value={values.rental_reason}
                        error={touched.rental_reason && errors.rental_reason}
                        errorMsg={errors.rental_reason}
                        data-test-id="rental_reason"
                      />
                      <FormHelperText style={{ textAlign: "right" }}>
                        <Typography variant="subtitle1">
                          {values.rental_reason.length}/100
                        </Typography>
                      </FormHelperText>
                    </Box>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      type="submit" 
                      data-test-id="nextBtn"
                    >
                      {configJSON.nextBottonText}
                    </Button>
                    {errorMsg && <Toast data-test="toast" type="error" message={errorMsg} />}
                    {successMsg && <Toast data-test="toast" type="success" message={successMsg} />}
                  </Box>
                </Form>
              )}
            </Formik>
          </Container>
          <Box className="progressBar">
              <img alt="progress-bar" src={progressBar1} />
          </Box>
        </Wrapper>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled("div")(({ theme }) => ({
    "& .backBtn": {
      fontSize: "18px",
      fontFamily:  "Outfit",
      fontWeight: 700,
      color: "#64748B",
      textTransform: "none",
    },
    "& .bodyContent": {
      maxWidth: "736px",
      margin: "0 20%",
    },
    "& .headerContent": {
      width: "100%", 
      height: "auto", 
      backgroundColor: "#FFFFFF", 
      padding: "10px 0"
    },
    "& .logoContent": {
      width: "20%",
      height: "auto",
      minWidth: "175px",
    },
    "& .addressBox": {
      margin: "18px 0",
    },
    "& .paddingTop": {
      paddingTop: "8px",
    },
    "& .paddingBottom": {
      paddingBottom: "8px",
    },
    "& .paddingRight": {
      paddingRight: "5px",
    },
    "& .paddingLeft": {
      paddingLeft: "5px",
    },
    "& .aboutBox": {
      margin: "30px 0",
    },
    "& .dockImg": {
      width: "100%",
      maxWidth: "352px",
      maxHeight: "224px",
      padding: "12px",
      marginLeft: "40%",
    },
    "& .flexBox": {
      justifyContent: "space-between",
    },
    "& .marginTop": {
      marginTop: "12px",
    },
    "& .flexItems": {
      display: "flex",
      gap: 15,
      alignItems: "center",
    },
    "& .chipContent": {
      padding: "25px 10px",
      borderRadius: "26px",
      borderColor: "#CBD5E1",
    },
    "& .chipAlign": {
      display: "flex",
      gap: 5,
      alignItems: "center",
    },
    "& .chipTextSelected": {
      fontWeight: 700,
      color: "white",
    },
    "& .selectedChip": {
      backgroundColor: "#4F9FF8 !important",
    },
    "& .Map": {
        height: "446px",
        border: "1px solid #FFEB3B",
        borderRadius: "8px",
        overflow: "hidden",
    },
    "& .progressBar": {
      width: "100%",
    },
    "& .progressBar img": {
      width: "100%",
    },
    "& .dimentionOfDock": {
      margin: "16px 0",
      display: "flex",
      gap: 16,
    },
    "& .dockingDimentionInputLabel": {
      width: "max-content",
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "Outfit",
      marginBottom:'10px',
      color:'#0F172A',
    },
    "& .dockingDimentionInput": {
      width: "93px",
      height: "44px",
      borderRadius: "8px",
    },
    "& .dockingDepthInput": {
      width: "114px",
      height: "44px",
      borderRadius: "8px",
    },
    [theme.breakpoints.down(900)]: {
      "& .bodyContent": {
        margin: "0 16px",
      },
      "& .dockImg":{
        marginLeft: "0",
      },
  },
  }));
  
// Customizable Area End
