Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.ApiContentType = "application/json";
exports.httpGetMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.httpPostMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "emailnotifications2";
exports.labelBodyText = "emailnotifications2 Body";
exports.contactUsApiEndPoint = "bx_block_email_notifications/contact_us";

exports.btnExampleTitle = "CLICK ME";
exports.contactFormTitle = "Contact Form";
exports.emailFieldLabel = "Email";
exports.nameFieldLabel = "Name";
exports.sendBtnText = "Send";
// Customizable Area End