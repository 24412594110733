// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/utils";
import * as Yup from "yup";
import { getStorageData } from "../../../framework/src/Utilities";
import { Field, Form, Formik, FormikErrors, FormikTouched } from "formik";
import {
  Avatar,
  Box,
  Button,
  FormHelperText,
  Grid,
  Typography,
} from "@material-ui/core";
import CustomInput from "../../../components/src/CustomInput.web";
import Toast from "../../../components/src/Toast";

export const configJSON = require("./config");
export const assets = require("./assets");

export interface Props {
  navigation: any;
  id: string;
}

interface IApiResponse {
  id: string;
  type: string;
  attributes: {
    id: number | null;
    first_name: string | null;
    last_name: string | null;
    email: string | null;
    full_phone_number: string | null;
    govt_id: string | null;
    profile_pic: string | null;
    address: {
      data: {
        id: string | null;
        type: string | null;
        attributes: {
          street: string | null;
          flat: string | null;
          city: string | null;
          state: string | null;
          postcode: string | null;
        };
      };
    };
    emergency_contacts: {
      name: string | null;
      relationship: string | null;
      full_phone_number: string | null;
    };
  };
}

interface IUserAddress {
  street: string | null;
  flat: string | null;
  city: string | null;
  state: string | null;
  postcode: string | null;
}

interface IUserEmergencyContact {
  name: string | null;
  relationship: string | null;
  full_phone_number: string | null;
}

interface IUserDetails {
  profile_pic: string;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  full_phone_number: string | null;
  government_id: string | null;
  govt_id: string;
  address: IUserAddress;
  emergency_contacts: IUserEmergencyContact;
  errorKey: string | null;
}

interface SProps {
  isLoading: boolean;
  isViewMode: boolean;
  profile_prev: string | ArrayBuffer | null | undefined;
  gov_prev: string | ArrayBuffer | null | undefined;
  userDetails: IUserDetails;
  errorMsg: IUserDetails;
  successMsg: string;
}

interface SSProps {
  ssId: string;
}

export default class SettingsProfileController extends BlockComponent<
  Props,
  SProps,
  SSProps
> {
  countryCode: number = 1; // Change it when its necessary
  getUserDetailsApiCallId: string = "";
  updateUserApiCallId: string = "";
  clearErrorMsgsTimeout: NodeJS.Timeout | null = null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isLoading: false,
      isViewMode: true,
      profile_prev: null,
      gov_prev: null,
      userDetails: {
        profile_pic: "",
        first_name: "",
        last_name: "",
        email: "",
        full_phone_number: "",
        government_id: "",
        govt_id: '',
        address: {
          street: "",
          flat: "",
          city: "",
          state: "",
          postcode: "",
        },
        emergency_contacts: {
          name: "",
          relationship: "",
          full_phone_number: "",
        },
      } as IUserDetails,
      errorMsg: {} as IUserDetails,
      successMsg: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  validationSchema = Yup.object().shape({
    first_name: Yup.string().nullable().required("First name is required"),
    email: Yup.string().nullable()
      .email("Enter valid email")
      .required("Email is required"),
    full_phone_number: Yup.string().nullable()
      .matches(/^\d+$/, "Phone number must be numeric")
      .length(11, "Phone number must be exactly 10 digits"),
    govt_id: Yup.string().nullable().required("Government Id is required"),
    address: Yup.object().shape({
      street: Yup.string().nullable().required("Street is required"),
      city: Yup.string().nullable().required("City is required"),
      state: Yup.string().nullable().required("State is required"),
      postcode: Yup.string().nullable().required("Postal code is required"),
    }),
    emergency_contacts: Yup.object().shape({
      name: Yup.string().nullable().required("Emergency contact person name is required"),
      relationship: Yup.string().nullable().required("Last name is required"),
      full_phone_number: Yup.string().nullable()
        .matches(/^\d+$/, "Phone number must be numeric")
        .length(11, "Phone number must be exactly 10 digits"),
    }),
  });

  switchToEditMode = () => {
    this.setState({
      isViewMode: false,
    });
  };

  async componentDidMount() {
    super.componentDidMount();
    this.fetchUserDetails();
  }

  getFieldError = (
    fieldName: keyof IUserDetails,
    touched: FormikTouched<IUserDetails>,
    errors: FormikErrors<IUserDetails>,
    subFieldName?: keyof IUserAddress | keyof IUserEmergencyContact
  ) => {
    if (subFieldName) {
      return {
        error: Boolean(
          ((touched[fieldName] as { [key: string]: string })?.[subFieldName] &&
            (errors[fieldName] as { [key: string]: string })?.[subFieldName]) ||
            (
              this.state.errorMsg[fieldName] as unknown as Record<
                string,
                unknown
              >
            )?.[subFieldName]
        ),
        errorMsg:
          (errors[fieldName] as { [key: string]: string })?.[subFieldName] ||
          (
            this.state.errorMsg[fieldName] as unknown as Record<string, unknown>
          )?.[subFieldName] as string,
      };
    }
    return {
      error: Boolean(
        (touched[fieldName] && errors[fieldName]) ||
          this.state.errorMsg[fieldName]
      ),
      errorMsg: errors[fieldName] || this.state.errorMsg[fieldName] as string,
    };
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.getUserDetailsApiCallId]: this.handleAPIResponse,
      [this.updateUserApiCallId]: this.handleAPIResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  fetchUserDetails = async () => {
    this.setState({
      errorMsg: {} as IUserDetails,
    });
    const token = await getStorageData("token");
    this.getUserDetailsApiCallId = sendAPIRequest(
      "bx_block_settings/profile_settings/view",
      {
        headers: {
          "Content-Type": "application/json",
          token,
        },
      }
    );
  };

  handleUserDetailsUpdate = async (values: IUserDetails) => {
    const { address, emergency_contacts, ...restValues } = values;
    this.setState({
      isLoading: true,
      errorMsg: {} as IUserDetails,
      successMsg: "",
    });
    const token = await getStorageData("token");
    const formData = new FormData();
    Object.entries(restValues).forEach(([keyName, value]) => {
      if(value !== null) {
        if (keyName === "profile_pic") {
          if (this.state.profile_prev) {
            formData.append(`settings[${keyName}]`, value);
          }
        }
        else if (keyName === "govt_id") {
            formData.append(`settings[${keyName}]`, value);
        } else {
          formData.append(`settings[${keyName}]`, value);
        }
      }
    });

    Object.entries(address).forEach(([keyName, value]) => {
      formData.append(`settings[${keyName}]`, value);
    });

    Object.entries(emergency_contacts).forEach(([keyName, value]) => {
      formData.append(
        `settings[emergency_contacts][${keyName}]`,
        value as string
      );
    });

    this.updateUserApiCallId = sendAPIRequest(
      "bx_block_settings/profile_settings/modify",
      {
        method: "PUT",
        headers: {
          token,
        },
        body: formData,
      }
    );
  };

  handleAPIResponse = (responseJSON: Record<string, unknown>) => {
    this.clearMsgs();
    if (this.handleErrorResponse(responseJSON)) return;
    this.setState({ successMsg: "" });
    const response = responseJSON as {
      meta?: { message: string };
      data?: IApiResponse;
    };
    if (response.meta) {
      this.setState({
        successMsg: response.meta.message,
      });
    }
    if (response.data) {
      const attributes = response.data.attributes;
      const userDetails = {
        profile_pic: attributes.profile_pic,
        first_name: attributes.first_name,
        last_name: attributes.last_name,
        email: attributes.email,
        full_phone_number: attributes.full_phone_number,
        govt_id: attributes.govt_id,
        address: {
          street: attributes.address.data?.attributes.street,
          flat: attributes.address.data?.attributes.flat,
          city: attributes.address.data?.attributes.city,
          state: attributes.address.data?.attributes.state,
          postcode: attributes.address.data?.attributes.postcode,
        },
        emergency_contacts: {
          name: attributes.emergency_contacts.name,
          relationship: attributes.emergency_contacts.relationship,
          full_phone_number: attributes.emergency_contacts.full_phone_number,
        },
      } as IUserDetails;
      this.setState({ userDetails });
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    this.setState({ isLoading: false });
    const { errors } = responseJSON as {
      errors: Record<string, string | Record<string, string>>[];
    };
    let errorMsg = {} as IUserDetails;
    if (errors) {
      errors.forEach((error) => {
        Object.keys(error).forEach((errorKey) => {
          const keyValue = error[errorKey] as unknown as string;
          if (typeof keyValue === "string") {
            (errorMsg as unknown as Record<string, unknown>)[errorKey] =
              keyValue;
          } else if (typeof keyValue === "object") {
            Object.keys(keyValue).forEach((subKey) => {
              if (!(errorMsg as unknown as Record<string, unknown>)[errorKey]) {
                (errorMsg as unknown as Record<string, unknown>)[errorKey] = {};
              }
              (
                (errorMsg as unknown as Record<string, unknown>)[
                  errorKey
                ] as Record<string, unknown>
              )[subKey] = keyValue[subKey];
            });
          }
        });
      });
    }
    this.setState({ errorMsg });
    return false;
  };

  clearMsgs = () => {
    if (this.clearErrorMsgsTimeout) {
      clearTimeout(this.clearErrorMsgsTimeout);
    }
    this.clearErrorMsgsTimeout = setTimeout(() => {
      this.setState({ errorMsg: {} as IUserDetails, successMsg: "" });
    }, 5000);
  };

  handleReset = () => {
    this.setState({
      isViewMode: true,
      errorMsg: {} as IUserDetails,
      successMsg: "",
      isLoading: false,
    });
  };

  renderEditProfileForm = () => {
    return (
      <Formik
        data-test-id="editFormContainer"
        initialValues={this.state.userDetails}
        validationSchema={this.validationSchema}
        onSubmit={(values) => {
          this.handleUserDetailsUpdate(values);
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form className="bodyContent">
            <Typography variant="h5" data-test-id="editProfileTitle">
              Edit Profile
            </Typography>
            <Grid container>
              <Grid item md={12} xs={12} sm={12} className="editViewBlock">
                <Typography variant="body1">Profile picture</Typography>
                <Avatar
                  style={{ margin: "5px 0 10px 0" }}
                  alt={values.first_name || ""}
                  src={
                    (this.state.profile_prev as string) || values.profile_pic
                  }
                />
                <input
                  data-test-id="profilePic"
                  name="profile_pic"
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    if (event.target.files && event.target.files[0]) {
                      const file = event.target.files[0];
                      setFieldValue("profile_pic", file);
                      const reader = new FileReader();
                      reader.onload = (event) => {
                        this.setState({ profile_prev: event.target?.result });
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                />
                {this.getFieldError("profile_pic", touched, errors).error ? (
                  <FormHelperText className="colorRed imgError">
                    {
                      this.getFieldError("profile_pic", touched, errors)
                        .errorMsg
                    }
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item md={12} xs={12} sm={12} className="editViewBlock">
                <Typography variant="body1">First name</Typography>
                <Typography variant="subtitle1">
                  Name should match with government ID
                </Typography>
                <Field
                  data-test-id="firstName"
                  name="first_name"
                  as={CustomInput}
                  placeholder="First Name"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue("first_name", event.target.value)
                  }
                  value={values.first_name}
                  {...this.getFieldError("first_name", touched, errors)}
                />
                <Typography variant="body1" className="paddingTop">
                  Last name
                </Typography>
                <Typography variant="subtitle1">
                  Name should match with government ID
                </Typography>
                <Field
                  data-test-id="lastName"
                  name="last_name"
                  as={CustomInput}
                  placeholder="Last Name"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue("last_name", event.target.value)
                  }
                  value={values.last_name}
                  {...this.getFieldError("last_name", touched, errors)}
                />
              </Grid>
              <Grid item md={12} xs={12} sm={12} className="editViewBlock">
                <Typography variant="body1">Email address</Typography>
                <Typography variant="subtitle1">
                  Use the address that you’ll have to access.
                </Typography>
                <Field
                  data-test-id="email"
                  name="email"
                  as={CustomInput}
                  placeholder="eg. email@address.com"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue("email", event.target.value)
                  }
                  value={values.email}
                  {...this.getFieldError("email", touched, errors)}
                />
              </Grid>
              <Grid item md={12} xs={12} sm={12} className="editViewBlock">
                <Typography variant="body1">Phone number</Typography>
                <Typography variant="subtitle1">
                  Add number so confirmed guests and Airbnb can get in touch.
                  You can add other numbers and choose how they’re used.
                </Typography>
                <Field
                  data-test-id="phoneNumber"
                  name="full_phone_number"
                  as={CustomInput}
                  placeholder="eg. 453 654 6789"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(
                      "full_phone_number",
                      `${this.countryCode}${event.target.value}`
                    )
                  }
                  startIcon={`(+${this.countryCode})`}
                  value={values.full_phone_number?.substring(1)}
                  {...this.getFieldError("full_phone_number", touched, errors)}
                />
              </Grid>
              <Grid item md={12} xs={12} sm={12} className="editViewBlock">
                <Typography variant="body1">Government ID</Typography>
                <Avatar
                  style={{ margin: "5px 0 10px 0" }}
                  src={
                    (this.state.gov_prev as string) || values.govt_id
                  }
                />
                <Typography variant="subtitle1">
                  We’ll need you to add an official government ID to verify
                  that’s really you.
                </Typography>
                <input
                  data-test-id="governmentId"
                  type="file"
                  name="govt_id"
                  accept="image/*"
                  onChange={(event) => {
                    if (event.target.files && event.target.files[0]) {
                      const fileName = event.target.files[0];
                      setFieldValue("govt_id", fileName);                      
                      this.setState({ gov_prev: URL.createObjectURL(fileName)});                      
                    }
                  }}
                />
                {this.getFieldError("govt_id", touched, errors).error ? (
                  <FormHelperText className="colorRed imgError">
                    {
                      this.getFieldError("govt_id", touched, errors)
                        .errorMsg
                    }
                  </FormHelperText>
                ) : null}
              </Grid>
              <Grid item md={12} xs={12} sm={12} className="editViewBlock">
                <Typography variant="body1">Address</Typography>
                <Typography variant="subtitle1">
                  Use a permanent address where you can receive mail.
                </Typography>
                <Typography variant="body1" className="paddingTop">
                  Street Address
                </Typography>
                <Field
                  data-test-id="street"
                  name="address.street"
                  as={CustomInput}
                  placeholder="eg. Robinson street"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue("address.street", event.target.value)
                  }
                  value={values.address.street}
                  {...this.getFieldError("address", touched, errors, "street")}
                />
                <Typography variant="body1" className="paddingTop">
                  Flat /Suite (option)
                </Typography>
                <Field
                  data-test-id="flat"
                  name="address.flat"
                  as={CustomInput}
                  placeholder="eg. A-203"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue("address.flat", event.target.value)
                  }
                  value={values.address.flat}
                  {...this.getFieldError("address", touched, errors, "flat")}
                />
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={12}>
                    <Typography variant="body1" className="paddingTop">
                      City
                    </Typography>
                    <Field
                      data-test-id="city"
                      name="address.city"
                      as={CustomInput}
                      placeholder="eg. City"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("address.city", event.target.value)
                      }
                      value={values.address.city}
                      {...this.getFieldError(
                        "address",
                        touched,
                        errors,
                        "city"
                      )}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <Typography variant="body1" className="paddingTop">
                      State
                    </Typography>
                    <Field
                      data-test-id="state"
                      name="address.state"
                      as={CustomInput}
                      placeholder="eg. State"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue("address.state", event.target.value)
                      }
                      value={values.address.state}
                      {...this.getFieldError(
                        "address",
                        touched,
                        errors,
                        "state"
                      )}
                    />
                  </Grid>
                </Grid>
                <Typography variant="body1" className="paddingTop">
                  Postcode
                </Typography>
                <Field
                  data-test-id="postCode"
                  name="address.postcode"
                  as={CustomInput}
                  placeholder="eg. 456788"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue("address.postcode", event.target.value)
                  }
                  value={values.address.postcode}
                  {...this.getFieldError(
                    "address",
                    touched,
                    errors,
                    "postcode"
                  )}
                />
              </Grid>
              <Grid item md={12} xs={12} sm={12} className="editViewBlock">
                <Typography variant="body1">Emergency contact</Typography>
                <Typography variant="subtitle1">
                  Use the address that you’ll have to access.
                </Typography>
                <Typography variant="body1" className="paddingTop">
                  Name
                </Typography>
                <Field
                  data-test-id="emergencyPerson"
                  name="emergency_contacts.name"
                  as={CustomInput}
                  placeholder="Jassie Dane"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue("emergency_contacts.name", event.target.value)
                  }
                  value={values.emergency_contacts.name}
                  {...this.getFieldError(
                    "emergency_contacts",
                    touched,
                    errors,
                    "name"
                  )}
                />
                <Typography variant="body1" className="paddingTop">
                  Relationship
                </Typography>
                <Field
                  data-test-id="relation"
                  name="emergency_contacts.relationship"
                  as={CustomInput}
                  placeholder="Sister"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(
                      "emergency_contacts.relationship",
                      event.target.value
                    )
                  }
                  value={values.emergency_contacts.relationship}
                  {...this.getFieldError(
                    "emergency_contacts",
                    touched,
                    errors,
                    "relationship"
                  )}
                />
                <Typography variant="body1" className="paddingTop">
                  Phone number
                </Typography>
                <Field
                  data-test-id="emergencyContactPhone"
                  name="emergency_contacts.full_phone_number"
                  as={CustomInput}
                  placeholder="eg. 456788"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue(
                      "emergency_contacts.full_phone_number",
                      `${this.countryCode}${event.target.value}`
                    )
                  }
                  startIcon={`(+${this.countryCode})`}
                  value={values.emergency_contacts.full_phone_number?.substring(
                    1
                  )}
                  {...this.getFieldError(
                    "emergency_contacts",
                    touched,
                    errors,
                    "full_phone_number"
                  )}
                />
              </Grid>
            </Grid>
            {this.state.successMsg && (
              <Toast message={this.state.successMsg} type="success" />
            )}
            {Object.values(this.state.errorMsg).length ? (
              <Toast
                message="Opps! Your profile settings need some changes, Do it and try again."
                type="error"
              />
            ) : null}
            <Box className="actionBtnGroup">
              <Button
                variant="outlined"
                color="primary"
                type="reset"
                onClick={this.handleReset}
              >
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                {this.state.isLoading ? "Saving..." : "Save"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    );
  };
}

// Customizable Area End
