import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { ShallowWrapper } from "enzyme";

export enum Continue {
  No = 1,
  VerifyEmail,
  CompleteProfile,
};

export interface MustVerifyEmail { email: string; password: string; tempToken: string; };
export interface MustCompleteProfile { tempToken: string; email: string; password: string; };

type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
interface RequestParams {
  method?: HttpMethods;
  headers?: object;
  body?: object | FormData | any;
}

export function sendAPIRequest(endpoint: string, params?: RequestParams) {
  const { method = "GET", headers, body } = params || {}
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  )
  const callId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endpoint
  )
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  )
  if (body)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body?.append ? body : JSON.stringify(body)
    )

  if (headers)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )
  runEngine.sendMessage(requestMessage.id, requestMessage)
  return callId
}

export function findByDataTest(wrapper: ShallowWrapper, dataTestValue: string): ShallowWrapper {
  // The returned value will be another shallow wrapper
  return wrapper.find(`[data-test="${dataTestValue}"]`);
}

export const checkMinLength = (someText: string): boolean => {
  return someText.length >= 8;
};

export const checkHasNumber = (someText: string): boolean => {
  return /\d/.test(someText);
};

export const checkHasSpecialChar = (someText: string): boolean => {
  const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  return specialCharRegex.test(someText);
};

export const validateEmailFormat = (email: string): boolean => {
  // Simple regex for email validation; you can refine this as needed
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isLeapYear = (year: number): boolean => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const getDaysInMonth = (month: number, year: number): number => {
  if (month === 0 || year === 0) {
    month = 1;
    year = 2000;
  }
  const daysInMonth = [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  return daysInMonth[month - 1];
};

// Month names mapping
export const monthNames = [
  { value: 1, label: 'Jan' },
  { value: 2, label: 'Feb' },
  { value: 3, label: 'Mar' },
  { value: 4, label: 'Apr' },
  { value: 5, label: 'May' },
  { value: 6, label: 'Jun' },
  { value: 7, label: 'Jul' },
  { value: 8, label: 'Aug' },
  { value: 9, label: 'Sep' },
  { value: 10, label: 'Oct' },
  { value: 11, label: 'Nov' },
  { value: 12, label: 'Dec' },
];

export const yearsArray = Array.from({ length: 100 }, (_, i) => {
  const yearValue = new Date().getFullYear() - i;
  return { value: yearValue, label: yearValue.toString() };
});

export const checkIfEighteenOrOlder = (day: number, month: number, year: number): boolean => {
  const today = new Date();
  const birthDate = new Date(year, month - 1, day); // JavaScript months are 0-indexed
  const age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    return age > 18;
  } else {
    return age >= 18;
  }
};

export function getLastPartOfURL() {
  let url = window.location.pathname;
  let parts = url.split("/");
  let lastPart = parts[parts.length - 1];
  const rolePart = parts[parts.length - 2];
  if (lastPart === "where-you-are-docking" || lastPart === "where-you-have-docked") {
    return { role: rolePart, url: "BoaterReservationList" };
  } else if (lastPart === "reservations" || lastPart === "past-reservations") {
    return { role: rolePart, url: "ReservationList" };
  } else if (lastPart === "chat") {
    if (rolePart === "host") {
      return { role: "host", url: "HostChat" };
    } else {
      return { role: "boater", url: "BoaterChat" };
    }
  } else {
    return { role: rolePart, url: lastPart };
  }
}

export function convertToOptions(
  itemList: any,
  valueProperty: string,
  labelProperty: string = "",
) {
		const tempList: {value: string, label: string}[] = [];
		itemList.map((item: any) =>
			tempList.push({
				label: labelProperty ? item[labelProperty] : item.name,
				value: item[valueProperty].toString(),
			})
		);
		return tempList;
};