import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { setStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/utils";
import { Grid, Typography } from "@material-ui/core";
import { imgListing } from "./assets";
export const configJSON = require("./config");
import {noDataFound} from './BoaterSearch.web';
interface FaqAttributes {
  id: number | null;
  question: string;
  answer: string;
}

interface ILake {
  id: number,
  name: string,
  state: string,
}

interface DockListAttributes {
  id: number,
  name: string,
  state: string,
  latitude: string,
  longitude: string,
  address: string,
  docking_length: number,
  docking_width: number,
  listing_title: string,
  about: string,
  access: string,
  parking_space: number,
  max_boats: number,
  max_guests: number,
  allow_pets: boolean,
  allow_smoking: boolean,
  allow_liveaboards: boolean,
  rules_and_cautions: string,
  has_security_camera: boolean,
  has_animals: boolean,
  reservation_type: string,
  cancellation_policy: string,
  base_price: string,
  locality: string,
  administrative_area_level_1: string,
  administrative_area_level_2: string,
  state_short_name: string,
  step: number,
  features: [],
  lake: ILake | null,
  dock_type: string,
  listing_type: string,
  image: string,
  dock_add_ons: [],
  service_fee: number,
  guest_total: string
}

interface ISearchDockApiResponse {
  id: number,
  type: string,
  attributes: DockListAttributes,
}

interface FaqData {
  id: string;
  type: string;
  attributes: FaqAttributes;
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchTerm: string;
  propsData: {query:string; lake_id:number};
  expandedAccordionPanel: string | boolean;
  faqDataList: FaqData[];
  dockList: DockListAttributes[];
  errorMsg: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  searchDockAPICallId: string = "";
  getFAQCallId: string = "";
  timeoutId: ReturnType<typeof setTimeout> | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      searchTerm: "",
      propsData:{query:"", lake_id:0},
      expandedAccordionPanel: false,
      faqDataList: [],
      dockList: [],
      errorMsg: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.searchDock("");
    this.getFAQs();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
      [this.getFAQCallId]: this.handleGetFAQAPIResponse,
      [this.searchDockAPICallId]: this.handleSearchDockAPIResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  getFAQs = async () => {
    this.getFAQCallId = sendAPIRequest(configJSON.faqPath, {
      method: configJSON.validationApiMethodType,
      headers: {
        "Content-Type": configJSON.validationApiContentType,
      },
      },
    );
  };
  setExpandedAccordionPanel =
    (panel: string) =>
    (event: React.SyntheticEvent<{}>, isExpanded: boolean) => {
      this.setState({ expandedAccordionPanel: isExpanded ? panel : false });
    };

  searchDock = async (searchQuery: string) => {
    this.searchDockAPICallId = sendAPIRequest(
      `${configJSON.searchAPIPath}?location=${searchQuery}&per=${5}`,
      {
        method: configJSON.validationApiMethodType,
        headers: {
          "Content-Type": configJSON.validationApiContentType,
        },
      }
    );
  }

  handleGetFAQAPIResponse = (responseJSON: Record<string, unknown>) => {
    this.setState({
          faqDataList: responseJSON.data as FaqData[],
    });
  }

  handleSearchDockAPIResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) return;

    const response = responseJSON as {
      meta?: {
        pagination: {
            per_page: number,
            current_page: number,
            next_page: string,
            prev_page: string,
            total_pages: number,
            total_count: number
        }
      };
      data?: ISearchDockApiResponse[];
    };
    if (response.data && response.meta) {
      const dockList = response.data.map((dock) => ({
        ...dock.attributes
      }));
      this.setState({
        dockList: dockList as DockListAttributes[],
      });
    }
  }

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
     const { errors: possibleErrors } = responseJSON;
     if (possibleErrors) {
       const errors = possibleErrors as string;
       this.setState({ errorMsg: errors });
         return true; // Indicates that there was an error
     }
     return false; // Indicates that there was no error
 };

 handleSearchByText = (searchQuery: string) => {
    const newValue = searchQuery.replace(/\bLake\b|\blake\b/g, '').trim();
    this.setState(prevState => ({
      searchTerm: searchQuery,
      propsData: {
        ...prevState.propsData, 
        query: newValue 
      }
    }));
  if (this.timeoutId) {
    clearTimeout(this.timeoutId);
  }

  this.timeoutId = setTimeout(() => {
    this.searchDock(newValue);
  }, 1500);
};

handleDockDetail = (lakeId: number, lakeName: string) => {
  if(this.state.propsData.query === ""){
    this.setState(prevState => ({
      propsData: {
        ...prevState.propsData,
        query: lakeName, 
        lake_id: lakeId 
      }
    }),()=> {
      this.handleNavigation('AdvancedSearch')
    });
  } else {
    this.setState(prevState => ({
      propsData: {
        ...prevState.propsData, 
        lake_id: lakeId 
      }
    }),()=>{
      this.handleNavigation('AdvancedSearch')
    });
  }
}
 renderDockList = () => {
  if(this.state.dockList.length > 0){
    return this.state.dockList.map((dock) => {
      const dockTitle = dock.state ? `${dock.name}, ${dock.state}` : `${dock.name}`;
      return (
      <Grid item key={dock.id} lg={4} md={6} xs={12} className="listItem">
      <div data-test-id='dock-box' onClick={()=>this.handleDockDetail(dock.id, dock.name)}>  
      <img src={dock.image ? dock.image : imgListing} className="listImg" />
      <Typography variant="h5" align="center" style={{ cursor: "pointer" }}>
        { dock.name ? dockTitle : "Not Provided"}
      </Typography>
      </div>
    </Grid>
    )});
  }
  else{
    return noDataFound
  }
 }

  handleNavigation = async(route: string) => {
    await setStorageData('propsData', JSON.stringify(this.state.propsData));
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
